import { Box, Divider, Flex, Heading, Text } from "@chakra-ui/react";
import useGetARetailer from "hooks/useGetARetailer";
import { useNavigate } from "react-router-dom";

export default function OrderCard({ order }) {
  const { data: retailer } = useGetARetailer(order.retailerId);
  const navigate = useNavigate();

  return (
    <>
      <Box
        w={"full"}
        maxW={"md"}
        height={'190'}
        boxShadow={"xl"}
        rounded="2xl"
        p={2}
        border={"1px"}
        borderColor={"gray.500"}
        _hover={{
          transform: "scale(1.03)",
          transition: "all 0.2s ease-in-out",
          cursor: "pointer",
        }}
        onClick={() => {navigate(`/orders/${order.orderId}`)}}
      >
        <Flex direction={"row"} justify={"space-between"}>
          <Heading size={"sm"}>{order.orderId}</Heading>
          <Text>{order.createdAt}</Text>
        </Flex>
        <Divider my={2} />
        <Flex direction={"row"} justify={"space-between"}>
          <Text>Retailer:</Text>
          <Text>{retailer ? retailer.firmName : "Loading..."}</Text>
        </Flex>
        <Flex direction={"row"} justify={"space-between"}>
          <Text>Order Status:</Text>
          <Text>{order.status}</Text>
        </Flex>
        <Flex direction={"row"} justify={"space-between"}>
          <Text>Payment Method:</Text>
          <Text>{order.paymentMethod}</Text>
        </Flex>
        <Flex direction={"row"} justify={"space-between"}>
          <Text>Payment Status:</Text>
          <Text>{order.paymentStatus}</Text>
        </Flex>
        <Flex direction={"row"} justify={"space-between"}>
          <Text>CreatedBy:</Text>
          <Text>{order.createdBy}</Text>
        </Flex>
      </Box>
    </>
  );
}
