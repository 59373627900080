import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import { useShowSuccessToast } from "hooks/ToastUtil";
import { createSubCategory } from "http/subcategory";
import { useState } from "react";
import SubCategoryForm from "./SubCategoryForm";

export default function AddSubCategory({ isOpen, onClose, onSuccess, categoryId }) {
    const [ErrorMessage, setErrorMessage] = useState('');
    const toast = useShowSuccessToast()
    const submitHandler = async(data) => {
        const response = await createSubCategory(data);
        if(response.error) {
            setErrorMessage(response.error);
            return;
        }
        onClose();
        onSuccess();
        toast('Creating Sub Category', response.result);
    };
  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        closeOnOverlayClick={false}
        closeOnEsc={false}
        isCentered
        blockScrollOnMount={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add New Sub Category</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <SubCategoryForm submitHandler={submitHandler} alertMessage={ErrorMessage} categoryId={categoryId}/>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
