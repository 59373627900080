// @ts-nocheck
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Text,
  SimpleGrid,
  Button,
  Center,
  Select,
  Input,
  FormControl,
  FormErrorMessage,
  Textarea,
  Box,
  Checkbox,
  HStack,
  Spinner,
  Flex,
  Spacer,
} from "@chakra-ui/react";
import { Tabs, TabList, TabPanels, Tab, TabPanel, Icon } from "@chakra-ui/react";
import { Field, Formik } from "formik";
import { useShowErrorToast, useShowSuccessToast } from "hooks/ToastUtil";
import useGetRetailerDocuments from "hooks/useGetRetailerDocuments";
import useGetZones from "hooks/useGetZones";
import { createRetailer, updateRetailer, uploadPdfStatement } from "http/retailer";
import { useEffect, useState } from "react";
import { MdDownload, MdFullscreen } from "react-icons/md";
import { Document, Page } from "react-pdf";

export default function CreateRetailer({
  data: RetailerInfo,
  isOpen,
  onClose,
  forceRefetch,
}) {
  const successToast = useShowSuccessToast();
  const errorToast = useShowErrorToast();
  const [tabIndex, setTabIndex] = useState(0);
  const [pdf, setPdf] = useState('');
  const [pdfUrl, setPdfUrl] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const { isLoading: isZonesLoading, data: zones } = useGetZones();

  const update = async (newData) => {
    // newData = {...newData, userType: RetailerInfo.userType};
    if (RetailerInfo) {
      newData = { ...newData, sequenceId: RetailerInfo.sequenceId };
    }
    let res = null;
    if (RetailerInfo) {
      res = await updateRetailer(newData);
    } else {
      newData.userType = 'Retailer'
      res = await createRetailer(newData);
    }
    if (res.error) {
      errorToast("Update Retailer", res.error);
    } else {
      successToast("Update Retailer", res.result);
      setTabIndex(0);
      forceRefetch();
      onClose();
    }
  };

  const isMinimumLength = (value, minLength) => {
    if (value?.length < minLength) {
      return `Must be at least ${minLength} characters`;
    }
    return null;
  };

  const validateEmail = (email) => {
    if (String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )) {
      return null;
    } else {
      return 'Invalid email address';
    }
  };

  const areBasicDetailsValid = (values) => {
    if (values.zone === 0) return false;
    if (isMinimumLength(values.name, 3)) return false;
    if (isMinimumLength(values.mobileNo, 10)) return false;
    if (validateEmail(values.email)) return false;
    if (isMinimumLength(values.address, 10)) return false;
    if (isMinimumLength(values.pinCode, 6)) return false;

    return true;
  };

  function handlePdfChange(event) {
    setPdf(event.target.files[0])
  }
  const uploadTransactionPdf = async (retailerId) => {
    let response = null;
    if (pdf['name'] == null) {
      errorToast("Upload Transaction", "PDF statment not selected, Please select");
      return
    }
    if (!pdf['name'].match(/\.(pdf)$/)) {
      errorToast("Upload Transaction", "Please select valid pdf format");
      return
    }
    const formData = new FormData()
    formData.append('image', pdf)
    response = await uploadPdfStatement(retailerId, 'TransactionStatment', formData);
    if (response.error) {
      errorToast("Upload Transaction", response.error);
    } else {
      successToast("Upload Transaction", response.result);
      forceRefetch();
      onClose();
    }
  }
  const { data: documents } = useGetRetailerDocuments(
    RetailerInfo ? RetailerInfo.mobileNo : 0
  );

  useEffect(() => {
    setPdfUrl(null);
    if (documents) {
      const doc = documents.documentUrls.find(
        (doc) => doc.documentType === "TransactionStatment"
      );
      if (doc) {
        if (!!doc.url) {
          setPdfUrl(doc.url);
        }
      }
    }
  }, [documents]);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={() => { onClose(); setTabIndex(0); }}
        closeOnOverlayClick={true}
        closeOnEsc={true}
        isCentered
        blockScrollOnMount={false}
        size="5xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {RetailerInfo ?
              <>{RetailerInfo?.firmName}, Pro:  {RetailerInfo?.proprietorName} (Zone: {RetailerInfo?.zone})</>
              :
              <>Add New Retailer</>
            }
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {
              isZonesLoading ? <Center><Spinner /></Center> :
                <Formik
                  initialValues={{
                    firmName: RetailerInfo ? RetailerInfo.firmName : "",
                    proprietorName: RetailerInfo ? RetailerInfo.proprietorName : "",
                    address: RetailerInfo ? RetailerInfo.address : "",
                    email: RetailerInfo ? RetailerInfo.email : "",
                    fertilizersLicenseNo: RetailerInfo ? RetailerInfo.fertilizersLicenseNo : "",
                    fertilizersLicenseVerified: RetailerInfo ? RetailerInfo.fertilizersLicenseVerified : false,
                    gstNo: RetailerInfo ? RetailerInfo.gstNo : "",
                    active: RetailerInfo ? RetailerInfo.active : false,
                    mobileNo: RetailerInfo ? RetailerInfo.mobileNo : "",
                    pan: RetailerInfo ? RetailerInfo.pan : "",
                    pesticideLicenseNo: RetailerInfo ? RetailerInfo.pesticideLicenseNo : "",
                    pesticideLicenseVerified: RetailerInfo ? RetailerInfo.pesticideLicenseVerified : false,
                    photoIdUrl: RetailerInfo ? RetailerInfo.photoIdUrl : "",
                    photoIdVerified: RetailerInfo ? RetailerInfo.photoIdVerified : false,
                    pinCode: RetailerInfo ? RetailerInfo.pinCode : "",
                    profilePhotoUrl: RetailerInfo ? RetailerInfo.profilePhotoUrl : "",
                    rewards: RetailerInfo ? RetailerInfo.rewards : 0,
                    seedsLicenseNo: RetailerInfo ? RetailerInfo.seedsLicenseNo : "",
                    seedsLicenseVerified: RetailerInfo ? RetailerInfo.seedsLicenseVerified : false,
                    walletBalance: RetailerInfo ? RetailerInfo.walletBalance : 0,
                    zone: RetailerInfo ? RetailerInfo.zone : 0,
                    userType: RetailerInfo ? RetailerInfo.userType : "Retailer",
                  }}
                  onSubmit={async (values) => {
                    values.walletBalance = 0;
                    values.rewards = 0;
                    values.profilePhotoUrl = '';
                    values.photoIdVerified = false;
                    values.photoIdUrl = '';
                    if (!values.fertilizersLicenseVerified) values.fertilizersLicenseVerified = false;
                    if (!values.pesticideLicenseVerified) values.pesticideLicenseVerified = false;
                    if (!values.seedsLicenseVerified) values.seedsLicenseVerified = false;
                    if (!values.active) values.active = false;
                    values.photoIdUrl = '';
                    await update(values);
                  }}
                >
                  {({ values, errors, handleSubmit, isSubmitting,
                    setFieldValue, touched }) => (
                    <form onSubmit={values => {
                      handleSubmit(values);
                    }}>
                      <Tabs
                        isFitted
                        variant="enclosed"
                        colorScheme={"purple"}
                        minH={"600px"}
                        index={tabIndex}
                      // onChange={(index) => setTabIndex(index)}
                      >
                        <TabList mb="1em">
                          <Tab>Basic Details</Tab>
                          <Tab>License Details</Tab>
                          <Tab>Transactions Details</Tab>
                        </TabList>
                        <TabPanels>
                          <TabPanel overflowY="auto" height="650px">
                            <SimpleGrid columns={2} spacing={"25px"}>
                              <Text fontWeight={700}>Zone</Text>
                              <FormControl isInvalid={!!errors.zone && !!touched.zone}>
                                <Field as={Select} name="zone" id="zone" placeholder="Select Zone" validate={(value) => !!value ? null : 'Please choose the zone'}>
                                  {zones?.map((zone) => <option key={zone.zoneCode} value={zone.zoneCode}>{zone.zoneName}</option>)}
                                </Field>
                                <FormErrorMessage>{errors.zone?.toString()}</FormErrorMessage>
                              </FormControl>
                              <Text fontWeight={700}>Proprietor Name</Text>
                              <FormControl isInvalid={!!errors.proprietorName && !!touched.proprietorName}>
                                <Field as={Input} type="text" name="proprietorName" id="proprietorName" validate={(value) => isMinimumLength(value, 3)}>
                                </Field>
                                <FormErrorMessage>{errors.proprietorName?.toString()}</FormErrorMessage>
                              </FormControl>
                              <Text fontWeight={700}>Firm Name</Text>
                              <FormControl isInvalid={!!errors.firmName && !!touched.firmName}>
                                <Field as={Input} type="text" name="firmName" id="firmName" validate={(value) => isMinimumLength(value, 3)}>
                                </Field>
                                <FormErrorMessage>{errors.firmName?.toString()}</FormErrorMessage>
                              </FormControl>
                              <Text fontWeight={700}>Firm Type</Text>
                              <FormControl isInvalid={!!errors.userType && !!touched.userType}>
                                <Field as={Input} type="text" name="userType" id="userType" validate={(value) => isMinimumLength(value, 3)}>
                                </Field>
                                <FormErrorMessage>{errors.userType?.toString()}</FormErrorMessage>
                              </FormControl>

                              <Text fontWeight={700}>Mobile No</Text>
                              {RetailerInfo ?
                                <FormControl isInvalid={!!errors.mobileNo && !!touched.mobileNo}>
                                  <Field as={Input} type="number" name="mobileNo" id="mobileNo" validate={(value) => isMinimumLength(value.toString(), 10)} isDisabled={true} />
                                  <FormErrorMessage>{errors.mobileNo?.toString()}</FormErrorMessage>
                                </FormControl>
                                :
                                <FormControl isInvalid={!!errors.mobileNo && !!touched.mobileNo}>
                                  <Field as={Input} type="number" name="mobileNo" id="mobileNo" validate={(value) => isMinimumLength(value.toString(), 10)} />
                                  <FormErrorMessage>{errors.mobileNo?.toString()}</FormErrorMessage>
                                </FormControl>
                              }

                              <Text fontWeight={700}>Email Id</Text>
                              <FormControl isInvalid={!!errors.email && !!touched.email}>
                                <Field as={Input} type="email" name="email" id="email" validate={(value) => isMinimumLength(value, 5) || validateEmail(value)}>
                                </Field>
                                <FormErrorMessage>{errors.email?.toString()}</FormErrorMessage>
                              </FormControl>
                              <Text fontWeight={700}>Address</Text>
                              <FormControl isInvalid={!!errors.address && !!touched.address}>
                                <Field as={Textarea} name="address" id="address" validate={(value) => isMinimumLength(value, 10)}>
                                </Field>
                                <FormErrorMessage>{errors.address?.toString()}</FormErrorMessage>
                              </FormControl>
                              <Text fontWeight={700}>Pin Code</Text>
                              <FormControl isInvalid={!!errors.pinCode && !!touched.pinCode}>
                                <Field as={Input} type="number" name="pinCode" id="pinCode" validate={(value) => isMinimumLength(value.toString(), 6)}>
                                </Field>
                                <FormErrorMessage>{errors.pinCode?.toString()}</FormErrorMessage>
                              </FormControl>
                              <Text fontWeight={700}>Activated {values.active}</Text>
                              <Field as={Checkbox} name="active" id="active" colorScheme={'green'} isChecked={values.active}></Field>
                            </SimpleGrid>
                          </TabPanel>
                          <TabPanel overflowY="auto" height="650px">
                            <SimpleGrid columns={3} spacing={"30px"}>
                              <Text fontWeight={700}>PAN Number</Text>
                              <FormControl isInvalid={!!errors.pan && !!touched.pan}>
                                <Field as={Input} type="text" name="pan" id="pan" validate={(value) => isMinimumLength(value, 10)}>
                                </Field>
                                <FormErrorMessage>{errors.pan?.toString()}</FormErrorMessage>
                              </FormControl>
                              <Text></Text>
                              <Text fontWeight={700}>GST Number</Text>
                              <FormControl isInvalid={!!errors.gstNo && !!touched.gstNo}>
                                <Field as={Input} type="text" name="gstNo" id="gstNo" validate={(value) => isMinimumLength(value, 15)}>
                                </Field>
                                <FormErrorMessage>{errors.gstNo?.toString()}</FormErrorMessage>
                              </FormControl>
                              <Text></Text>
                              <Text fontWeight={700}>Seeds License Number</Text>
                              {/* <FormControl isInvalid={!!errors.seedsLicenseNo && !!touched.seedsLicenseNo}> */}
                              <FormControl>
                                <Field as={Input} type="text" name="seedsLicenseNo" id="seedsLicenseNo">
                                </Field>
                                <FormErrorMessage>{errors.seedsLicenseNo?.toString()}</FormErrorMessage>
                              </FormControl>
                              <Field as={Checkbox} name="seedsLicenseVerified" id="seedsLicenseVerified" colorScheme={'green'} isChecked={values.seedsLicenseVerified}></Field>
                              <Text fontWeight={700}>Pesticide License Number</Text>
                              {/* <FormControl isInvalid={!!errors.pesticideLicenseNo && !!touched.pesticideLicenseNo}> */}
                              <FormControl>
                                <Field as={Input} type="text" name="pesticideLicenseNo" id="pesticideLicenseNo">
                                </Field>
                                <FormErrorMessage>{errors.pesticideLicenseNo?.toString()}</FormErrorMessage>
                              </FormControl>
                              <Field as={Checkbox} name="pesticideLicenseVerified" id="pesticideLicenseVerified" colorScheme={'green'} isChecked={values.pesticideLicenseVerified}></Field>
                              <Text fontWeight={700}>
                                Fertilizers License Number
                              </Text>
                              {/* <FormControl isInvalid={!!errors.fertilizersLicenseNo && !!touched.fertilizersLicenseNo}> */}
                              <FormControl>
                                <Field as={Input} type="text" name="fertilizersLicenseNo" id="fertilizersLicenseNo">
                                </Field>
                                <FormErrorMessage>{errors.fertilizersLicenseNo?.toString()}</FormErrorMessage>
                              </FormControl>
                              <Field as={Checkbox} name="fertilizersLicenseVerified" id="fertilizersLicenseVerified" colorScheme={'green'} isChecked={values.fertilizersLicenseVerified}></Field>
                            </SimpleGrid>
                          </TabPanel>
                          <TabPanel overflowY="auto" height="650px" border={10}>
                            <Center
                              w={"full"}
                              boxShadow={"none"}
                              rounded="md"
                              p={0}
                              color={"purple"}
                              border={'3px'}
                              fontWeight={'bold'}
                            >
                              Select PDF Statement:<Text color={'red'} fontSize={'16'} fontWeight={'hairline'}>(*Only pdf format allowed)</Text>&nbsp;&nbsp;
                              <input type="file" name="file" onChange={handlePdfChange} /><Spacer />
                              <Button type="button" colorScheme="blue" mr={3} onClick={() => uploadTransactionPdf(values.mobileNo)}>Upload Statement</Button>
                            </Center>
                            {/* Display if transaction statement is already available */}
                            {pdfUrl === null ? (
                              <Center
                                w={"full"}
                                boxShadow={"xl"}
                                rounded="md"
                                p={5}
                                color={"white.500"}
                                bg={"white.100"}
                                border={"0px"}
                                borderColor={"white.300"}
                              >
                                No Transactions Statement Available
                              </Center>
                            ) : (
                              <div>
                                <Flex minWidth='max-content' alignItems='center' gap='2'>
                                  <Spacer />
                                  <a href={pdfUrl} rel="noreferrer" target="_blank">
                                    <Icon as={MdFullscreen} boxSize={10} color={'purple'} /> &nbsp;&nbsp;&nbsp;&nbsp;
                                    <Icon as={MdDownload} boxSize={10} color={'purple'} />
                                  </a>
                                </Flex>
                                <Document onLoadSuccess={onDocumentLoadSuccess} file={pdfUrl}>
                                  <Page pageNumber={numPages} />
                                </Document>
                              </div>
                            )}
                          </TabPanel>
                        </TabPanels>
                      </Tabs>
                      <HStack justifyContent={'space-between'}>
                        {tabIndex === 1 ? <Button type="button" colorScheme="blue" mr={3} onClick={() => setTabIndex(0)}>Back</Button> : <Box></Box>}
                        {tabIndex === 0 ? <Button isDisabled={!areBasicDetailsValid(values)} type="button" colorScheme="blue" mr={3} onClick={() => setTabIndex(1)}>Next</Button> : <></>}
                        {tabIndex === 1 ? <Button isDisabled={!areBasicDetailsValid(values)} type="button" colorScheme="blue" mr={3} onClick={() => setTabIndex(2)}>Next</Button> : <></>}
                        {tabIndex === 2 ? <Button type="submit" isLoading={isSubmitting} colorScheme={'brand'} mr={3}>Update Retailer</Button> : <></>}
                      </HStack>
                    </form>
                  )}
                </Formik>
            }
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
