import { Flex, useColorModeValue, Stack, Image, Text, Heading, FormControl, Button, FormLabel, Link, Box, NumberInputField, NumberInput, PinInput, PinInputField, FormErrorMessage } from "@chakra-ui/react";
import { RequestOTP, VerifyOTP } from "http/auth";
import React, { useState } from "react";
import { MdArrowBack, MdSend } from 'react-icons/md';
import { useNavigate } from "react-router-dom";

function Login() {
    const [IsProcessing, setIsProcessing] = useState(false);
    const [IsVerifyingOTP, setIsVerifyingOTP] = useState(false);
    const [MobileNumber, setMobileNumber] = useState('');
    const [OTP, setOTP] = useState('');
    const [IsError, setIsError] = useState(false);
    const [ErrorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();

    const onSendOTP = async () => {
        setIsProcessing(true);
        const response = await RequestOTP(MobileNumber);
        if (response.error) {
            setIsError(true);
            setErrorMessage(response.error);
        } else {
            setIsError(false);
            setErrorMessage('');
            setIsVerifyingOTP(true);
        }
        setIsProcessing(false);
    }
    const onVerifyOTP = async () => {
        setIsProcessing(true);
        const response = await VerifyOTP(MobileNumber, OTP, "Web", "WEB_ADMIN");
        if (response.error) {
            setIsError(true);
            setErrorMessage(response.error);
        } else {
            setIsError(false);
            setErrorMessage('');
            setIsVerifyingOTP(false);
            setMobileNumber('');
            setOTP('');
            navigate("/dashboard", { replace: true });
        }
        setIsProcessing(false);
    }
    const onEditMobileNumber = () => {
        setOTP('');
        setIsVerifyingOTP(false);
    }

    const onMobileNumberChange = (value) => {
        const number = value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1");
        if (number === '' || number.length !== 10) {
            setIsError(true);
            setErrorMessage('Enter Valid Mobile Number');
        } else {
            setIsError(false);
            setErrorMessage('');
        }
        setMobileNumber(number);
    };

    return (
        <Flex
            backgroundImage="url('/web_screen.jpg')"
            minH={'100vh'}
            align={'center'}
            justify={'center'}
        >
            <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
                <Stack align={'center'}>
                    <Image src='/Logo_Admin.png' alt='Mirseed Admin Logo' />
                </Stack>
                <Box
                    rounded={'lg'}
                    bg={useColorModeValue('white', 'gray.700')}
                    boxShadow={'lg'}
                    p={8}>
                    <Stack spacing={4}>
                        <Heading textAlign={'center'} size={'md'}>Experience the all new Mirseed Admin!</Heading>
                        <Stack spacing={4} display={IsVerifyingOTP ? 'none' : 'flex'} height={'150'}>
                            <FormControl pt={4} isInvalid={IsError}>
                                <FormLabel fontSize={'xs'}>Enter Your Mobile Number</FormLabel>
                                <NumberInput value={MobileNumber} onChange={(e) => onMobileNumberChange(e)}>
                                    <NumberInputField maxLength={10} required autoComplete="off" textAlign={'center'} />
                                </NumberInput>
                                <FormErrorMessage>{ErrorMessage}</FormErrorMessage>
                            </FormControl>
                            <Button
                                isLoading={IsProcessing}
                                leftIcon={<MdSend />}
                                onClick={() => onSendOTP()}
                            >
                                Send OTP
                            </Button>
                        </Stack>
                        <Stack spacing={4} display={IsVerifyingOTP ? 'flex' : 'none'}>
                            <FormControl pt={10} isInvalid={IsError}>
                                <FormLabel fontSize={'xs'}>Enter OTP Received on you Email or Mobile</FormLabel>
                                <Flex direction={'row'} justifyContent='center'>
                                    <PinInput otp value={OTP} onChange={value => setOTP(value)}>
                                        <PinInputField />
                                        <PinInputField />
                                        <PinInputField />
                                        <PinInputField />
                                        <PinInputField />
                                        <PinInputField />
                                    </PinInput>
                                </Flex>
                                <FormErrorMessage>{ErrorMessage}</FormErrorMessage>
                            </FormControl>
                            <Button
                                isDisabled={OTP.length !== 6}
                                isLoading={IsProcessing}
                                leftIcon={<MdSend />}
                                onClick={() => onVerifyOTP()}
                            >
                                Verify OTP
                            </Button>
                            <Button
                                leftIcon={<MdArrowBack />}
                                onClick={() => onEditMobileNumber()}
                            >
                                Edit Mobile Number
                            </Button>
                        </Stack>
                        <Text fontSize='xs' pt={5}>By continuing, you agree to Mirseed Admin <Link href="http://mirseed.com/#about" target='_blank'>Terms of Use</Link> and <Link href="http://mirseed.com/#about" target='_blank'>Privacy Policy</Link></Text>
                    </Stack>
                </Box>
            </Stack>
        </Flex>
    );
}

export default Login;
