import { createBrowserRouter, RouterProvider } from "react-router-dom";
import NotFoundScreen from "screens/404";
import Categories from "screens/Categories/Categories";
import Dashboard from "screens/DashBoard/DashBoard";
import Login from "screens/Login/Login";
import Logout from "screens/Logout";
import Offers from 'screens/Offers/Offers';
import Orders from 'screens/Orders/Orders';
import ViewOrder from 'screens/Orders/ViewOrder';
import AddPayment from 'screens/Payment/AddPayment';
import Payments from 'screens/Payment/ViewPaymentDetails';
import UpdatePayment from 'screens/Payment/UpdatePayment';
import Products from 'screens/Products/Products';
import ViewProduct from 'screens/Products/ViewProduct';
import ViewProductRequest from 'screens/Products/ViewProductRequest';
import Retailers from "screens/Retailers/Retailers";
import RootLayout from "screens/Root";
import Settings from 'screens/Settings/Settings';
import Zones from "screens/Zones/Zones";
import Sales from 'screens/Sales/Sales';
import Reports from 'screens/Reports/Reports';
import Enquiry from 'screens/Enquiry/Enquiry';

const router = createBrowserRouter([
	{
		path: '/login',
		element: <Login />
	},
	{
		path: '/',
		element: <RootLayout />,
		errorElement: <NotFoundScreen />,
		children: [
			{
				path: 'dashboard',
				element: <Dashboard />
			},
			{
				path: 'categories',
				element: <Categories />
			},
			{
				path: 'products',
				element: <Products />,
			},
			{
				path: 'products/:productCode',
				element: <ViewProduct />
			},
			{
				path: '/zones',
				element: <Zones />,
			},
			{
				path: '/retailers',
				element: <Retailers />,
			},
			{
				path: '/offers',
				element: <Offers />,
			},
			{
				path: '/settings',
				element: <Settings />,
			},
			{
				path: '/orders',
				element: <Orders />,
			},
			{
				path: '/payments',
				element: <Payments />,
			},
			{
				path: '/orders/:orderId',
				element: <ViewOrder />,
			},
			{
				path: '/logout',
				element: <Logout />
			},
			{
				path: '/update/:id',
				element: <UpdatePayment />,
			},
			{
				path: '/addPayment',
				element: <AddPayment />,
			},
			{
				path: 'productsRequest/:variantId/:totalRequest',
				element: <ViewProductRequest />,
			},
			{
				path: '/sales',
				element: <Sales />,
			},
			{
				path: '/reports',
				element: <Reports />,
			},
			{
				path: '/enquiry',
				element: <Enquiry />,
			}
		]
	},
]);

function App() {
	return (
		<RouterProvider router={router}></RouterProvider>
	);
}

export default App;
