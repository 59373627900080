import { deleteRequest, getBaseUrl, postRequest, putRequest } from "./axios";
import { getHeaderWithAccessToken } from "./helper";

const ENDPOINT = 'product';

export const getProductUrl = (category, subCategory) => {
    if (category && subCategory) {
        return `${getBaseUrl()}${ENDPOINT}/category/${category}/${subCategory}`;
    } else if (category) {
        return `${getBaseUrl()}${ENDPOINT}/category/${category}`;
    } else {
        return `${ENDPOINT}`;
    }
}

export const getProductsUsingKeyword = (keyword) => {
    return `${getBaseUrl()}${ENDPOINT}/search/${keyword}`;
}

export const getProductByCodeUrl = (productCode) => {
    return `${getBaseUrl()}${ENDPOINT}/${productCode}`;
}

export const getProductImagesUrl = (productCode) => {
    return `${getBaseUrl()}${ENDPOINT}/images/${productCode}`;
}

export const makeAnImageAsDefault = async (productCode, imageId) => {
    return await putRequest(`${ENDPOINT}/images/${imageId}/${true}`, null, getHeaderWithAccessToken());

}

export const deleteProductImage = async (productCode, imageId) => {
    return await deleteRequest(`${ENDPOINT}/${productCode}/${imageId}`, getHeaderWithAccessToken());

}

export const getUploadImageUrl = (productCode) => {
    return `${getBaseUrl()}${ENDPOINT}/images/${productCode}`;
}

export const addAProductVarient = async (data) => {
    return await postRequest(`${ENDPOINT}/varients`, data, getHeaderWithAccessToken());
}

export const deleteAProduct = async (id) => {
    return await deleteRequest(`${ENDPOINT}/${id}`, getHeaderWithAccessToken());
};

export const createAProduct = async (data) => {
    return await postRequest(`${ENDPOINT}`, data, getHeaderWithAccessToken());
}

export const updateAProduct = async (id, data) => {
        return await putRequest(`${ENDPOINT}/${id}`, data, getHeaderWithAccessToken());
}

export const getProductsRequestEndPoint = () => `${getBaseUrl()}${ENDPOINT}/request`;


export const getProductRequestByIdEnPoint = (variantId) => {
    return `${getBaseUrl()}${ENDPOINT}/request/${variantId}`;
}

export const notifyAllCustomer = async (variantId, name, pack) => {
    return await postRequest(`${ENDPOINT}/notify/${variantId}/${name}/${pack}`,null, getHeaderWithAccessToken());
}