import React from 'react';
import {
  Stack,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Textarea,
  Button,
  Alert,
  AlertIcon,
} from "@chakra-ui/react";
import { useState } from "react";

export default function CategoryForm({
  zone: category = null,
  alertMessage = "",
  submitHandler,
}) {
  const [Name, setName] = useState(category ? category.name : "");
  const [Description, setDescription] = useState(category ? category.description : "");
  const [IsNameError, setIsNameError] = useState(false);
  const [NameErrorMessage, setNameErrorMessage] = useState("");
  const [IsProcessing, setIsProcessing] = useState(false);

  const onNameChange = (value) => {
    setName(value);
    if (value.length <= 3) {
      setIsNameError(true);
      setNameErrorMessage("Name should be minimum of 3 letters");
    } else {
      setIsNameError(false);
      setNameErrorMessage("");
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    setIsProcessing(true);
    await submitHandler({name: Name, description: Description});
    setIsProcessing(false);
  };
  return (
    <>
      <form onSubmit={onSubmit}>
        <Stack spacing={6}>
          {
            alertMessage !== '' ? <Alert status='error'><AlertIcon />{alertMessage}</Alert>: <></>
          }
          <FormControl isRequired isInvalid={IsNameError}>
            <FormLabel>Name:</FormLabel>
            <Input
              type="text"
              placeholder="Enter name of the category"
              onChange={(e) => onNameChange(e.currentTarget.value)}
              value={Name}
            />
            <FormErrorMessage>{NameErrorMessage}</FormErrorMessage>
          </FormControl>
          <FormControl>
            <FormLabel>Description:</FormLabel>
            <Textarea
              placeholder="Describe the category"
              onChange={(e) => setDescription(e.currentTarget.value)}
              value={Description}
            ></Textarea>
            <FormErrorMessage>Error</FormErrorMessage>
          </FormControl>
          <Button colorScheme={"brand"} type="submit" isLoading={IsProcessing}>
            Submit
          </Button>
        </Stack>
      </form>
    </>
  );
}
