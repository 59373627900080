import { deleteRequest, getBaseUrl, getRequest, postRequest, putRequest } from "./axios";
import { getHeaderWithAccessToken } from "./helper";

const ENDPOINT = 'category';

export const getUploadImageUrl = (id) => `${getBaseUrl}${ENDPOINT}/icon/${id}`;

export const getCategoriesEndPoint = () => ENDPOINT;

export const getCategories = async () => {
    return await getRequest(ENDPOINT, getHeaderWithAccessToken());
};

export const createCategory = async (data) => {
    return await postRequest(ENDPOINT, data, getHeaderWithAccessToken());
};

export const updateCategory = async (data) => {
    return await putRequest(`${ENDPOINT}`, data, getHeaderWithAccessToken());
};

export const deleteCategory = async (id) => {
        return await deleteRequest(`${ENDPOINT}/${id}`, getHeaderWithAccessToken());
};
