import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton} from "@chakra-ui/react";
import ImageUploader from 'components/ImageUploader';

export default function ChangeImageRetailer({ title, url, isOpen, onClose, onSuccess }) {
  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        closeOnOverlayClick={false}
        closeOnEsc={false}
        isCentered
        blockScrollOnMount={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Retailer: {title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <ImageUploader multiple={false} maxFiles={1} uploadHandlerObj={{
              url: url,
              method: "put",
              name: "image",
            }}></ImageUploader>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
