import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Text,
    SimpleGrid,
    Table,
    Thead,
    Th,
    Tbody,
    Tr,
    Td,
    Tooltip,
    IconButton
    // @ts-ignore
} from "@chakra-ui/react";
// @ts-ignore
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import { useShowErrorToast, useShowSuccessToast } from "hooks/ToastUtil";
import useGetOrdersBySalesOfficers from "hooks/useGetOrderBySalesOfficer";
// @ts-ignore
import { appointSalesOfficerForZone, dismissSalesOfficerFromZone } from "http/sales";
import { FiEye } from "react-icons/fi";
import { RiDeleteBin3Fill, RiAddBoxFill, RiInformationLine } from "react-icons/ri";
import { TiTick, TiWarning } from "react-icons/ti";
import { useNavigate } from "react-router-dom";

export default function ViewDetailedSalesOfficers({
    data: SalesOfficersInfo,
    isOpen,
    onClose,
    forceRefetch
}) {
    const navigate = useNavigate();
    const successToast = useShowSuccessToast();
    const errorToast = useShowErrorToast();

    const mobileNo = SalesOfficersInfo ? SalesOfficersInfo.mobileNo : -1
    const { data: ordersBySalesOfficer } = useGetOrdersBySalesOfficers(mobileNo);


    const dismissSalesOfficer = async (id) => {
        const res = await dismissSalesOfficerFromZone(id);
        if (res.error) {
            errorToast("Unable to dismiss sales officer from zone", res.error);
        }
        onClose();
        successToast('Dismissed sales officer from zone successfully', res.result);
        forceRefetch({});
    }

    const appointSalesOfficer = async (mobileNo, id) => {
        const res = await appointSalesOfficerForZone(mobileNo, id);
        if (res.error) {
            errorToast("Unable to appoint sales officer", res.error);
        }
        onClose();
        successToast("Appointed sales officer to zone successfully", res.result);
        forceRefetch({});

    }

    return (
        <>
            <Modal
                isOpen={isOpen}
                onClose={onClose}
                closeOnOverlayClick={true}
                closeOnEsc={true}
                isCentered
                blockScrollOnMount={false}
                size="5xl"
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>
                        Sales Officer: {SalesOfficersInfo?.name}
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Tabs
                            isFitted
                            variant="enclosed"
                            colorScheme={"purple"}
                            minH={"500px"}
                        >
                            <TabList mb="1em">
                                <Tab>Basic Details</Tab>
                                <Tab>Zones Details/Management</Tab>
                                <Tab>Orders Details</Tab>
                            </TabList>
                            <TabPanels>
                                {/* Basic details */}
                                <TabPanel overflowY="auto" height="650px">
                                    <SimpleGrid columns={2} spacing={"25px"}>
                                        <Text fontWeight={700}>Name</Text>
                                        <Text>{SalesOfficersInfo?.name}</Text>
                                        <Text fontWeight={700}>Mobile No</Text>
                                        <Text>{SalesOfficersInfo?.mobileNo}</Text>
                                        <Text fontWeight={700}>Email Id</Text>
                                        <Text>{SalesOfficersInfo?.email}</Text>
                                        <Text fontWeight={700}>Adhar No</Text>
                                        <Text>{SalesOfficersInfo?.adharNo}</Text>
                                        <Text fontWeight={700}>PAN </Text>
                                        <Text>{SalesOfficersInfo?.pan}</Text>
                                        <Text fontWeight={700}>Address</Text>
                                        <Text>{SalesOfficersInfo?.address}</Text>
                                        <Text fontWeight={700}>Pin Code</Text>
                                        <Text>{SalesOfficersInfo?.pinCode}</Text>
                                        <Text fontWeight={700}>Status</Text>
                                        {SalesOfficersInfo?.active === false ? (
                                            <TiWarning fontSize={24} color={"red"} title='Inactive sales officer' />
                                        ) : (
                                            <TiTick fontSize={24} color={"green"} title='Active sales officer' />
                                        )}
                                        <Text fontWeight={700}>Rewards Points</Text>
                                        <Text>{SalesOfficersInfo?.rewards}</Text>
                                        <Text fontWeight={700}>Boarded On</Text>
                                        <Text>{SalesOfficersInfo?.createdAt}</Text>

                                    </SimpleGrid>
                                </TabPanel>
                                {/* Zone details */}

                                <TabPanel overflowY="auto" height="650px">
                                    <Table border={"collapse"} variant='striped'>
                                        <Thead>
                                            <Th fontSize={"13"} fontWeight={700}>Status</Th>
                                            <Th fontSize={"13"} fontWeight={700}>Zone Name</Th>
                                            <Th fontSize={"13"} fontWeight={700}>Zone Description</Th>
                                            <Th fontSize={"13"} fontWeight={700}>Appointed On</Th>
                                            <Th fontSize={"13"} fontWeight={700}>Actions</Th>
                                        </Thead>
                                        <Tbody>
                                            {/* Appointed zones */}
                                            {
                                                SalesOfficersInfo?.appointedZones?.map((zone, index) => (
                                                    <Tr>
                                                        <Td>{index + 1}.</Td>
                                                        <Td >
                                                            {zone.zoneName}
                                                            <IconButton
                                                                aria-label="info"
                                                                title='Zone already appointed to sales officer'
                                                                icon={<RiInformationLine />}
                                                                color={'ORANGE'}
                                                                variant="unstyled"
                                                            />

                                                        </Td>
                                                        <Td>{zone.description}</Td>
                                                        <Td>{zone.createdAt}</Td>
                                                        <Td>
                                                            <IconButton
                                                                aria-label="Delete"
                                                                title='Dismiss dales officer from this zone'
                                                                icon={<RiDeleteBin3Fill />}
                                                                colorScheme={"red"}
                                                                variant="outline"
                                                                onClick={() => dismissSalesOfficer(zone.appointmentId)}
                                                            /> &nbsp;&nbsp;&nbsp;
                                                            <IconButton
                                                                isDisabled={true}
                                                                aria-label="Add"
                                                                title='Appoint Sales Officer'
                                                                icon={<RiAddBoxFill />}
                                                                colorScheme={"green"}
                                                                variant="outline"
                                                            />
                                                        </Td>
                                                    </Tr>
                                                )
                                                )
                                            }
                                            <Tr height={70}>
                                                <Td ></Td>
                                                <Td ></Td>
                                                <Td ></Td>
                                                <Td></Td>
                                                <Td ></Td>
                                            </Tr>
                                            {/* Available zones to appoint */}
                                            {
                                                SalesOfficersInfo?.availableZones?.map((zone, index) => (
                                                    <Tr>
                                                        <Td>{index + 1}.</Td>
                                                        <Td >
                                                            {zone.zoneName}
                                                            <IconButton
                                                                aria-label="info"
                                                                title='Zone is available to appointed to sales officer'
                                                                icon={<RiInformationLine />}
                                                                color={'green'}
                                                                variant="unstyled"
                                                            />

                                                        </Td>
                                                        <Td>{zone.description}</Td>
                                                        <Td color={'green'}>{zone.createdAt}</Td>
                                                        <Td>
                                                            <IconButton
                                                                isDisabled={true}
                                                                aria-label="Delete"
                                                                title='Dismiss dales officer from this zone'
                                                                icon={<RiDeleteBin3Fill />}
                                                                colorScheme={"red"}
                                                                variant="outline"
                                                            /> &nbsp;&nbsp;&nbsp;
                                                            <IconButton
                                                                aria-label="Add"
                                                                title='Appoint Sales Officer'
                                                                icon={<RiAddBoxFill />}
                                                                colorScheme={"green"}
                                                                variant="outline"
                                                                onClick={() => appointSalesOfficer(SalesOfficersInfo?.mobileNo, zone.zoneCode)}
                                                            />
                                                        </Td>
                                                    </Tr>
                                                )
                                                )
                                            }
                                        </Tbody>
                                    </Table>
                                </TabPanel>

                                <TabPanel overflowY="auto" height="650px">
                                    <Table border={"collapse"} variant='striped'>
                                        <Thead>
                                            <Th fontSize={"13"} fontWeight={700}>#</Th>
                                            <Th fontSize={"13"} fontWeight={700}>Order</Th>
                                            <Th fontSize={"13"} fontWeight={700}>Retailer</Th>
                                            <Th fontSize={"13"} fontWeight={700}>Status</Th>
                                            <Th fontSize={"13"} fontWeight={700}>Amount</Th>
                                            <Th fontSize={"13"} fontWeight={700}>Items</Th>
                                            <Th fontSize={"13"} fontWeight={700}>Ordered On</Th>
                                            <Th fontSize={"13"} fontWeight={700}>Delivery Date </Th>
                                            <Th fontSize={"13"} fontWeight={700}>Actions</Th>
                                        </Thead>
                                        <Tbody>
                                            {
                                                ordersBySalesOfficer?.map((order, index) => (
                                                    <Tr>
                                                        <Td>{index + 1}</Td>
                                                        <Td>{order.orderId}</Td>
                                                        <Td >{order.createdFor}</Td>
                                                        <Td>{order.orderStatus}</Td>
                                                        <Td>{order.orderAmount}</Td>
                                                        <Td>{order.orderItems}</Td>
                                                        <Td>{order.createdAt}</Td>
                                                        <Td>{order.deliveredAt}</Td>
                                                        <Td>
                                                            <Tooltip label="View Details">
                                                                <IconButton
                                                                    aria-label="View Details"
                                                                    icon={<FiEye />}
                                                                    colorScheme={"brand"}
                                                                    variant="outline"
                                                                    onClick={() => { navigate(`/orders/${order.orderId}`) }}
                                                                />
                                                            </Tooltip>

                                                        </Td>
                                                    </Tr>
                                                )
                                                )
                                            }
                                        </Tbody>
                                    </Table>
                                </TabPanel>
                            </TabPanels>
                        </Tabs>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
}
