import { deleteRequest } from "./axios";
import { getHeaderWithAccessToken } from "./helper";

const ENDPOINT = 'address';

export const getAddressByCustomerEndPoint = (id)=> `address/${id}`;

export const deleteAddressByUser = async (id) => {
    return await deleteRequest(`${ENDPOINT}/${id}`, getHeaderWithAccessToken());
};

