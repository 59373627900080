import {
  Button,
  Flex,
  Heading,
  Stack,
  useDisclosure,
  Center,
  SimpleGrid,
} from "@chakra-ui/react";
import { useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import AddCategory from "./components/AddCategory";
import ViewCategory from "./components/ViewCategory";
import EditCategory from "./components/EditCategory";
import useGetCategories from "hooks/useGetCategories";
import ViewSubCategoriesList from "./components/ViewSubCategoriesList";
import { deleteCategory } from "http/category";
import { useShowErrorToast, useShowSuccessToast } from "hooks/ToastUtil";
import ChangeImageCategory from './components/ChangeImageCategory';
import PropagateLoader from "react-spinners/PropagateLoader";

export default function Categories() {
  const [SelectedCategory, setSelectedCategory] = useState(null);
  const {
    isOpen: isAddOpen,
    onOpen: onAddOpen,
    onClose: onAddClose,
  } = useDisclosure();
  const {
    isOpen: isEditOpen,
    onOpen: onEditOpen,
    onClose: onEditClose,
  } = useDisclosure();
  const {
    isOpen: isSubCategoriesListOpen,
    onOpen: onSubCategoriesListOpen,
    onClose: onSubCategoriesListClose,
  } = useDisclosure();
  const {
    isOpen: isCategoryImageChangeOpen,
    onOpen: onCategoryImageChangeOpen,
    onClose: onCategoryImageChangeClose,
  } = useDisclosure();

  const [isDeleting, setIsDeleting] = useState(false);

  const {
    isLoading,
    data: categories,
    error,
    refetch: forceRefetch,
  } = useGetCategories();
  const successToast = useShowSuccessToast();
  const errorToast = useShowErrorToast();

  const onEditACategory = (id) => {
    setSelectedCategory(categories?.result?.find((z) => z.id === id));
    onEditOpen();
  };

  const onViewSubCategories = (id) => {
    setSelectedCategory(categories?.result?.find((z) => z.id === id));
    onSubCategoriesListOpen();
  };

  const onDelete = async id => {
    setIsDeleting(true);
    const response = await deleteCategory(id);
    if (response.error) {
      errorToast('Delete Category', response.error);
    } else {
      forceRefetch({});
      successToast('Delete Category', response.result);
    }
    setIsDeleting(false);
  };

  const onChangeImage = async id => {
    setSelectedCategory(categories?.result?.find((z) => z.id === id));
    onCategoryImageChangeOpen();
  };

  return (
    <>
      <Flex direction={"row-reverse"}>
        <Button
          colorScheme={"brand"}
          leftIcon={<AiOutlinePlus />}
          onClick={() => onAddOpen()}
        >
          Add New Category
        </Button>
      </Flex>

      <Stack spacing={2} mt={6}>
        <Heading size={"md"}>Available Categories</Heading>
        {isLoading ? (
          <Center
            w={"full"}
            boxShadow={"None"}
            rounded="md"
            p={5}
            color={"gray.500"}
            bg={"gray.100"}
            border={"0px"}
            borderColor={"gray.300"}
          >
            <PropagateLoader
              color={'purple'}
              loading={isLoading}
              data-testid="loader"
              speedMultiplier={1}
            />
          </Center>
        ) : null}
        {!isLoading &&
          !error &&
          (categories === null || categories?.result?.length === 0 || Object.keys(categories?.result).length === 0) ? (
          <Center
            w={"full"}
          >
            No Categories Available
          </Center>
        ) : (
          <SimpleGrid minChildWidth='250px' spacing='40px' pl={[0, 0, '80px', '18px', '20px', '40px']}>
            {categories?.result?.map((cat) => (
            <ViewCategory
              isDeleting={isDeleting}
              data={cat}
              onEdit={onEditACategory}
              key={cat.id}
              rest={{ my: 5 }}
              onViewSubCategories={(id) => onViewSubCategories(id)}
              onDelete={id => onDelete(id)}
              onImageChange={id => onChangeImage(id)}
            />
          ))} 
          </SimpleGrid>
        )}

      </Stack>

      <AddCategory
        isOpen={isAddOpen}
        onClose={onAddClose}
        onSuccess={() => forceRefetch({})}
      ></AddCategory>

      <EditCategory
        isOpen={isEditOpen}
        onClose={onEditClose}
        onSuccess={() => forceRefetch({})}
        category={SelectedCategory}
      ></EditCategory>

      <ViewSubCategoriesList
        isOpen={isSubCategoriesListOpen}
        onClose={onSubCategoriesListClose}
        categoryId={SelectedCategory?.id}
        categoryName={SelectedCategory?.name}
      ></ViewSubCategoriesList>
      <ChangeImageCategory
        isOpen={isCategoryImageChangeOpen}
        onClose={() => { onCategoryImageChangeClose(); forceRefetch({}); }} category={SelectedCategory} onSuccess={() => { }}></ChangeImageCategory>
    </>
  );
}
