import { CustomFetch } from "http/helper";
import { useEffect, useState } from "react";

export default function useFetch(url) {
    const [refetch, forceRefetch] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (!url) {
            setIsLoading(false);
            setData(null);
            setError(null);
        } else {
            setIsLoading(true);
            CustomFetch(url).then(response => {
                if (response.error) {
                    setIsLoading(false);
                    setData(null);
                    setError(response.error);
                } else {
                    setIsLoading(false);
                    setError(null);
                    setData(response.result);
                }
            });
        }
    }, [refetch, url]);

    return { isLoading, data, error, forceRefetch };
}