import { deleteRequest, getRequest, postRequest, putRequest } from "./axios";
import { getHeaderWithAccessToken, SendResult } from "./helper";

const ENDPOINT = '/subCategory';

export const getSubCategoriesEndPoint = (categoryId) => categoryId ? ENDPOINT + '/' + categoryId : 0;

export const getSubCategories = async (categoryId) => {
    if (!categoryId) {
        return SendResult(null);
    }
    return await getRequest(getSubCategoriesEndPoint(categoryId), getHeaderWithAccessToken());
};


export const createSubCategory = async (data) => {
    return await postRequest(ENDPOINT, data, getHeaderWithAccessToken());
};


export const updateSubCategory = async (id, data) => {
    return await putRequest(getSubCategoriesEndPoint(id), data, getHeaderWithAccessToken());
};

export const deleteSubCategory = async (id) => {
    return await deleteRequest(`${ENDPOINT}/${id}`, getHeaderWithAccessToken());
};
