import { deleteRequest, getRequest, postRequest, putRequest } from "./axios";
import { getHeaderWithAccessToken } from "./helper";

const ENDPOINT = '/zone';

export const getZonesEndPoint = () => ENDPOINT;

export const getZones = async () => {
    return await getRequest(ENDPOINT, getHeaderWithAccessToken());
};

export const createZone = async (data) => {
    return await postRequest(ENDPOINT, data, getHeaderWithAccessToken());
};

export const updateZone = async (code, data) => {
    return await putRequest(`${ENDPOINT}/${code}`, data, getHeaderWithAccessToken());
};

export const deleteZone = async (id) => {
    return await deleteRequest(`${ENDPOINT}/${id}`, getHeaderWithAccessToken());
};
