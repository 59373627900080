import { clearAccessToken, clearFirmName, clearMobile, getAccessToken, setAccessToken, setFirmName, setMobile } from "storage/session";
import { postRequest, deleteRequest } from "./axios"

export const RequestOTP = async (mobile) => {
    return await postRequest(`otp/${mobile}/LOGIN`, { mobile });
}

export const VerifyOTP = async (mobile, otp, fcmId, userAgent) => {
    const response = await postRequest(`/validate`, { mobileNo: mobile, otp, fcmId, userAgent }, {}, true);
    if (response.error) {
        return response;
    }
    setMobile(response.result.mobileNo);
    setFirmName(response.result.firmName);
    setAccessToken(response.result.token);
    return response;
}
export const SignOut = async () => {
    deleteRequest('/signout', { headers: { 'access-token': getAccessToken() } });
    clearMobile();
    clearFirmName();
    clearAccessToken();
}
