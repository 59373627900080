import { Button } from "@chakra-ui/button";
import { useColorMode } from "@chakra-ui/color-mode";
import { SunIcon, MoonIcon } from '@chakra-ui/icons'

const ToggleColorMode = () => {
  const { colorMode, toggleColorMode } = useColorMode();
  return (
    <Button
      onClick={() => toggleColorMode()}
      marginLeft='20'
      title="Toggle Application Theme"
      height={8}
      alignContent='center'
      variant="contained" disableElevation
    >
      {colorMode === "dark" ? (
        <SunIcon color="orange" boxSize={'6'} bgColor='dark'/>
      ) : (
        <MoonIcon color="blue"  boxSize={'6'} bgColor='white'/>
      )}
    </Button>
  );
};

export default ToggleColorMode;