import { Button } from "@chakra-ui/button";
import { Box, Center, Heading, VStack } from "@chakra-ui/layout";
import { FormControl, FormLabel, Image, Input, Spinner, Table, Td, Tr } from "@chakra-ui/react";
import { Formik } from "formik";
import { updatePaymentDetails, uploadBarcodeImage } from "http/payments";
import { useShowErrorToast, useShowSuccessToast } from "hooks/ToastUtil";
import { useParams, useNavigate } from "react-router-dom";
import { useState } from "react";
import { AiOutlineArrowLeft } from "react-icons/ai";
import useGetPaymentsById from "hooks/useGetPaymentById";


export default function UpdatePayment(props){

  const { id } = useParams();
  const successToast = useShowSuccessToast();
  const errorToast = useShowErrorToast();
  const navigate = useNavigate();
  const [image, setImage] = useState('');
  const {isLoading, data: payment} = useGetPaymentsById(id);


  const updatePayment = async (paymentDetails) => {
    let response = null;
    response = await updatePaymentDetails(paymentDetails);
    if (response.error) {
      errorToast("Update payment", response.error);
    } else {
      successToast("Update payment", response.result);
    }
    
  };

  const uploadImage = async (id)=> {
    let response = null;
    if (image['name']==null){
      errorToast("Upload barcode", "Please select barcode image to upload");
      return
    }
    const formData = new FormData()
    formData.append('image', image)
    response = await uploadBarcodeImage(id, formData);
    if (response.error) {
      errorToast("Upload barcode", response.error);
    } else {
      successToast("Upload barcode", response.result);
      navigate("/payments", {replace: true});
    }
  };


  function handleImageChange(event){
    setImage(event.target.files[0])
  }

  return (
    <>
    <Button
        colorScheme={"brand"}
        leftIcon={<AiOutlineArrowLeft />}
        onClick={() => navigate("/payments")}
      >
      Payment details
    </Button>

    {isLoading ? (
        <Center
          w={"full"}
          boxShadow={"xl"}
          rounded="md"
          p={5}
          color={"gray.500"}
          bg={"gray.100"}
          border={"1px"}
          borderColor={"gray.300"}
        >
        <Spinner />
        </Center>
      ) : (
          <Formik
            initialValues={{
              id : payment.id,
              accountNumber: payment.accountNumber, 
              name: payment.name, 
              bankName: payment.bankName, 
              email: payment.email, 
              ifscCode: payment.ifscCode, 
              primaryWhatsApp: payment.primaryWhatsApp, 
              secondaryWhatsApp:payment.secondaryWhatsApp, 
              upiId: payment.upiId,
              barCodeUrl:  payment.barCodeUrl}}
            onSubmit={(values, actions) => {
              if (values!==null){
                updatePayment(values);
              }
            }}
            enableReinitialize
            >
            {formik => (
              <VStack
                as="form"
                mx="auto"
                w={{ base: "100%", md: 900 }}
                h="90%"
                justifyContent="center"
                onSubmit={values=>formik.handleSubmit()}
              >
                <Box p={8} borderWidth={3} borderRadius={10} boxShadow="lg" width={800}>
                  <Heading  size={"md"}>Update Payment Details</Heading> <br/>
                  <Table >
                    <Tr>
                      <Td>
                      <FormControl isRequired >
                        <FormLabel>Account number</FormLabel>
                        <Input 
                            type={"number"}
                            name="accountNumber" 
                            onChange={formik.handleChange}
                            value={formik.values.accountNumber} 
                            placeholder="Enter 16 digit of account number"/>
                      </FormControl>
                      </Td>
                      <Td>
                      <FormControl isRequired>
                      <FormLabel>Account Holder Name</FormLabel>
                      <Input 
                          type="text"
                          name="name" 
                          onChange={formik.handleChange}
                          value={formik.values.name} 
                          placeholder="Enter account holder name"/>
                    </FormControl>
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>
                      <FormControl isRequired>
                      <FormLabel>Account BankName</FormLabel>
                      <Input 
                          type="text"
                          name="bankName" 
                          onChange={formik.handleChange}
                          value={formik.values.bankName} 
                          placeholder="Enter bank name"/>
                    </FormControl>
                      </Td>
                      <Td>
                      <FormControl isRequired>
                          <FormLabel>Account IFSC Code</FormLabel>
                          <Input
                              name="ifscCode"
                              type="text"
                              onChange={formik.handleChange}
                              value={formik.values.ifscCode}
                              placeholder="Enter bank IFSC code"/>
                      </FormControl>
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>
                      <FormControl isRequired>
                        <FormLabel>Account UPI ID</FormLabel>
                        <Input 
                            name="upiId" 
                            type="text"
                            onChange={formik.handleChange}
                            value={formik.values.upiId} 
                            placeholder="Enter bank UPI ID"/>
                      </FormControl>
                      </Td>

                      <Td>
                      <FormControl isRequired>
                      <FormLabel>Primary WhatsApp Number</FormLabel>
                      <Input 
                          name="primaryWhatsApp" 
                          type="number"
                          onChange={formik.handleChange}
                          value={formik.values.primaryWhatsApp} 
                          placeholder="Enter primary whatsapp number"/>
                      </FormControl>
                      </Td>
                  </Tr>
                  <Tr>
                    <Td>
                    <FormControl isRequired>
                    <FormLabel>Secondary WhatsApp Number</FormLabel>
                    <Input 
                        type="number"
                        name="secondaryWhatsApp" 
                        onChange={formik.handleChange}
                        value={formik.values.secondaryWhatsApp} 
                        placeholder="Enter secondary whatsapp number"/>
                  </FormControl>
                    </Td>
                    <Td>
                    <FormControl isRequired>
                    <FormLabel>Customer Support Email</FormLabel>
                    <Input 
                        type="text"
                        name="email" 
                        onChange={formik.handleChange}
                        value={formik.values.email} 
                        placeholder="Enter customer support email"/>
                  </FormControl>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>
                    Existing barcode<Image boxSize='200px' src={payment.barCodeUrl} />
                    </Td>
                    <Td></Td>
                  </Tr>
                  <Tr>
                    <Td>
                    <input
                    type="file"
                    name="file"
                    onChange={handleImageChange} 
                  />
                    </Td>
                    <Td><Button  className="uploadImage" variant="solid" colorScheme="blue" mt={4} onClick={()=> uploadImage(payment.id)}> Upload Barcode</Button></Td>
                  </Tr>
                  </Table>
                  <Button type="submit" variant="solid" colorScheme="blue" mt={4} justifyContent="flex-start">Update Payment</Button>
              </Box>
              </VStack>
            )}
          </Formik>
        )
        }
      </>
  )
}