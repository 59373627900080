import { Box, Center, Flex, Heading } from "@chakra-ui/react";
import { useEffect, useState } from "react";

export default function DataCard({ title, value }) {
    const [Count, setCount] = useState(0);

    useEffect(() => {
        let i = 0;
        const interval = setInterval(() => {
            if (i <= value) {
                setCount(i);
                i++;
            } else {
                clearInterval(interval);
            }
        }, Math.random() * 10);
    }, [value]);

  return (
    <>
      <Box
        w={["100%", "100%", "100%", "100%"]}
        h={"150px"}
        boxShadow="md"
        borderRadius="md"
        p={3}
        m={2}
      >
        <Flex direction={"column"} h={"100%"}>
          <Heading size={"sm"}>
            {title}
          </Heading>
          <Center flexGrow={2} textAlign={"center"}>
            <Heading size={'3xl'}>
              {Count}
            </Heading>
          </Center>
        </Flex>
      </Box>
    </>
  );
}
