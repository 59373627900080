import { Box, useColorModeValue, Stack, Heading, Text, Image } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { Icon } from '@chakra-ui/react'
import { MdAccountBox } from "react-icons/md";

export default function ProductRequestCard({data, rest}) {
    const navigate = useNavigate();
    const getImage = () => data.imagesUrl === "" ? "/import_placeholder.png" : data.imagesUrl;

    return (
        <>
      <Box
        role={"group"}
        p={6}
        maxW={"230px"}
        w={"full"}
        bg={useColorModeValue("white", "gray.800")}
        boxShadow={"2xl"}
        rounded={"lg"}
        pos={"relative"}
        zIndex={1}
        {...rest}
        _hover={{
          cursor: "pointer",
        }}
        onClick={() => navigate(`/productsRequest/${data.variantId}/${data.totalRequests}`)}
      >
        <Box
          pos={"relative"}
          height={"180px"}
          justifyContent={"center"}
          display={"flex"}
        >
          <Image src={getImage()} 
            rounded={"lg"}
            height={180}
            width={180}
            objectFit={"cover"}></Image>

        </Box>
        <Stack pt={3} align={"center"}>
          <Heading fontSize={"medium"} fontFamily={"body"} fontWeight={500}>
            {data.productName} ({data.pack})
          </Heading>
          <Text color={"gray.500"} fontSize={"xs"} textAlign={'center'}>
            {data.productDescription}
          </Text>
          <Text color={"gray.500"} fontSize={"xs"} textAlign={'center'}>
            Company: {data.companyName}
          </Text>
          <Heading fontSize={"xl"} fontFamily={"body"} fontWeight={500}>
          <Icon as={MdAccountBox} boxSize={10} color={'purple'}/>{data.totalRequests}
          </Heading>
        </Stack>
      </Box>
    </>
    );
}