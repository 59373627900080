import {
  Button,
  Center,
  Checkbox,
  FormControl,
  FormErrorMessage,
  Input,
  InputGroup,
  InputLeftAddon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
} from "@chakra-ui/react";
import { useShowErrorToast, useShowSuccessToast } from "hooks/ToastUtil";
import { addAProductVarient } from "http/product";
import { useState } from "react";

export default function AddVarientModal({
  isOpen,
  onClose,
  onSuccess,
  product,
  sendToServer = true,
}) {
  const [FormData, setFormData] = useState({
    default: false,
    description: "",
    minimumQuantity: 0,
    maxQty: 0,
    pack: "",
    price: 0,
    stock: 0,
    rewards:0,
  });
  const [errorMessage, setErrorMessage] = useState({
    description: null,
    minimumQuantity: null,
    maxQty: null,
    pack: null,
    price: null,
    stock: null,
    rewards: null,
  });
  const [IsAddingVarient, setIsAddingVarient] = useState(false);
  const successToast = useShowSuccessToast();
  const errorToast = useShowErrorToast();

  const onSubmit = async () => {
    let isError = false;
    const error = {
      description: null,
      minimumQuantity: null,
      maxQty : null,
      pack: null,
      price: null,
      stock: null,
      rewards: null,
    };
    if (FormData.pack === "") {
      error.pack = "Pack is required";
      isError = true;
    }
    if (FormData.price <= 0) {
      error.price = "Price is required";
      isError = true;
    }
    if (FormData.stock <= 0) {
      error.stock = "Stock is required";
      isError = true;
    }
    if (FormData.minimumQuantity <= 0) {
      error.minimumQuantity = "Minimum quantity per order is required";
      isError = true;
    }
    if (FormData.maxQty <= 0){
      error.maxQty = "Max quantity per order is required";
      isError = true;
    }
    if (FormData.rewards < 0){
      error.rewards = "Rewards per quantity is invalid";
      isError = true;
    }
    if (FormData.description === "") {
      error.description = "Description is required";
      isError = true;
    }
    if (isError) {
      setErrorMessage(error);
      return;
    }

    setErrorMessage(error);

    if (sendToServer) {
      setIsAddingVarient(true);
      const response = await addAProductVarient({
        productCode: product,
        varients: [{ ...FormData, productCode: product }],
      });

      if (response.error) {
        errorToast(response.error);
        setIsAddingVarient(false);
        return;
      }
      successToast(response.result);
    }
    onSuccess(FormData);
    setFormData({
      default: false,
      description: "",
      minimumQuantity: 0,
      maxQty: 0,
      pack: "",
      price: 0,
      stock: 0,
      rewards:0,
    });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered={true}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add New Pack</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={4}>
            <FormControl id="pack" isInvalid={errorMessage.pack}>
              <InputGroup>
                <InputLeftAddon minW={168}>Pack</InputLeftAddon>
                <Input
                  type="text"
                  value={FormData.pack}
                  onChange={(e) =>
                    setFormData({ ...FormData, pack: e.target.value })
                  }
                />
              </InputGroup>
              <FormErrorMessage>{errorMessage.pack}</FormErrorMessage>
            </FormControl>
            <FormControl id="description" isInvalid={errorMessage.description}>
              <InputGroup>
                <InputLeftAddon minW={168}>Description</InputLeftAddon>
                <Input
                  type="string"
                  value={FormData.description}
                  onChange={(e) =>
                    setFormData({ ...FormData, description: e.target.value })
                  }
                />
              </InputGroup>
              <FormErrorMessage>{errorMessage.description}</FormErrorMessage>
            </FormControl>
            <FormControl id="price" isInvalid={errorMessage.price}>
              <InputGroup>
                <InputLeftAddon minW={168}>Price</InputLeftAddon>
                <Input
                  type="number"
                  value={FormData.price}
                  onChange={(e) =>
                    setFormData({
                      ...FormData,
                      price: parseFloat(e.target.value),
                    })
                  }
                />
              </InputGroup>
              <FormErrorMessage>{errorMessage.price}</FormErrorMessage>
            </FormControl>
            <FormControl id="stock" isInvalid={errorMessage.stock}>
              <InputGroup>
                <InputLeftAddon minW={168}>Stock</InputLeftAddon>
                <Input
                  type="number"
                  value={FormData.stock}
                  onChange={(e) =>
                    setFormData({
                      ...FormData,
                      stock: parseInt(e.target.value),
                    })
                  }
                />
              </InputGroup>
              <FormErrorMessage>{errorMessage.stock}</FormErrorMessage>
            </FormControl>
            <FormControl
              id="minimumQuantity"
              isInvalid={errorMessage.minimumQuantity}
            >
              <InputGroup>
                <InputLeftAddon minW={168}>Minimum Quantity</InputLeftAddon>
                <Input
                  type="number"
                  value={FormData.minimumQuantity}
                  onChange={(e) =>
                    setFormData({
                      ...FormData,
                      minimumQuantity: parseInt(e.target.value),
                    })
                  }
                />
              </InputGroup>
              <FormErrorMessage>
                {errorMessage.minimumQuantity}
              </FormErrorMessage>
            </FormControl>
            <FormControl id="maxQty" isInvalid={errorMessage.maxQty}>
              <InputGroup>
                <InputLeftAddon minW={168}>Maximum Quantity</InputLeftAddon>
                <Input
                  type="number"
                  value={FormData.maxQty}
                  onChange={(e) =>
                    setFormData({
                      ...FormData,
                      maxQty: parseInt(e.target.value),
                    })
                  }
                />
              </InputGroup>
              <FormErrorMessage>
                {errorMessage.maxQty}
              </FormErrorMessage>
            </FormControl>
            <FormControl id="maxQty" isInvalid={errorMessage.rewards}>
              <InputGroup>
                <InputLeftAddon minW={168}>Rewards per quantity:</InputLeftAddon>
                <Input
                  type="number"
                  value={FormData.rewards}
                  onChange={(e) =>
                    setFormData({
                      ...FormData,
                      rewards: parseInt(e.target.value),
                    })
                  }
                />
              </InputGroup>
              <FormErrorMessage>
                {errorMessage.maxQty}
              </FormErrorMessage>
            </FormControl>
            <FormControl id="default">
              <InputGroup>
                <InputLeftAddon minW={168}>Is Default?</InputLeftAddon>
                <Center w={"100%"}>
                  <Checkbox
                    isChecked={FormData.default}
                    onChange={(e) =>
                      setFormData({ ...FormData, default: e.target.checked })
                    }
                  />
                </Center>
              </InputGroup>
            </FormControl>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme="brand"
            mr={3}
            onClick={onSubmit}
            isLoading={IsAddingVarient}
          >
            Add New Pack
          </Button>
          <Button onClick={onClose}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
