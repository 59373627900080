import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone-uploader";
import { getAccessToken } from "storage/session";
import { useShowSuccessToast, useShowErrorToast } from "hooks/ToastUtil";

const ImageUploader = ({
  multiple = true,
  maxFiles = 10,
  uploadHandlerObj = null,
}) => {
  const successToast = useShowSuccessToast();
  const errorToast = useShowErrorToast();

  // specify upload params and url for your files
  const getUploadParams = ({ file, meta }) => {
    if (uploadHandlerObj) {
      const body = new FormData();
      body.append(uploadHandlerObj.name, file);
      return {
        url: uploadHandlerObj.url,
        method: uploadHandlerObj.method,
        body,
        headers: {
          "Access-Control-Allow-Origin": "*",
        //   "Content-Type": "multipart/form-data; boundary=----WebKitFormBoundary4L5is0lmJEGehZiJ",
          "access-token": getAccessToken(),
        },
      };
    }
    return { url: uploadHandlerObj };
  };

  // called every time a file's `status` changes
  const handleChangeStatus = ({ meta, file }, status) => {
    if (status === "headers_received") {
        successToast(`${meta.name} uploaded!`);
    } else if (status === "aborted") {
        errorToast(`${meta.name}, upload failed...`);
    }
  };

  return (
    <Dropzone
      multiple={multiple}
      maxFiles={maxFiles}
      getUploadParams={getUploadParams}
      onChangeStatus={handleChangeStatus}
      accept="image/*"
    />
  );
};

export default ImageUploader;
