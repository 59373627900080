import { getBaseUrl } from "./axios";

const ENDPOINT = 'report';
const TOP_TEN_RETAILERS = `${ENDPOINT}/topTenRetailer`
const TOP_TEN_SELLING_PRODUCTS = `${ENDPOINT}/topTenSellingProducts`
const TOP_TEN_WEEKLY_SELLING_PODUCTS = `${ENDPOINT}/topTenWeeklySellingProducts`
const TOP_FIVE_SALES_OFFICERS = `${ENDPOINT}/topFiveOfficers`
const WEEKLY_SALES_CATEGORIES_WISE = `${ENDPOINT}/categoryWiseWeeklySales`

export const get_top_ten_retailers = () => {
    return `${getBaseUrl()}${TOP_TEN_RETAILERS}`;
}

export const get_top_ten_selling_products = () => {
    return `${getBaseUrl()}${TOP_TEN_SELLING_PRODUCTS}`;
}

export const get_top_weekly_selling_products = () => {
    return `${getBaseUrl()}${TOP_TEN_WEEKLY_SELLING_PODUCTS}`;
}

export const get_top_five_sales_officers = () => {
    return `${getBaseUrl()}${TOP_FIVE_SALES_OFFICERS}`;
}

export const get_weekly_sales_category_wise = () => {
    return `${getBaseUrl()}${WEEKLY_SALES_CATEGORIES_WISE}`;
}