// @ts-nocheck
import {
  Stack,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Textarea,
  Button,
  Alert,
  AlertIcon,
  NumberInput,
  NumberInputField,
  Switch,
  IconButton,
} from "@chakra-ui/react";
import { useState } from "react";
import { RiInformationLine } from "react-icons/ri";
import validator from 'validator'

export default function SalesOfficerForm({
  sales = null,
  alertMessage = "",
  submitHandler,
}) {
  const [Name, setName] = useState(sales ? sales.name : false);
  const [Active, setActive] = useState(sales ? sales.active : "");
  const [Address, setAddress] = useState(sales ? sales.address : "");
  const [Email, setEmail] = useState(sales ? sales.email : "");
  const [MobileNo, setMobileNo] = useState(sales ? sales.mobileNo : "");
  const [Rewards, setRewards] = useState(sales ? sales.rewards : "");
  const [Pincode, setPincode] = useState(sales ? sales.pinCode : "");
  const [Pan, setPan] = useState(sales ? sales.pan : "");
  const [AdharNo, setAdharNo] = useState(sales ? sales.adharNo : "");

  const [IsNameError, setIsNameError] = useState(false);
  const [NameErrorMessage, setNameErrorMessage] = useState("");

  const [IsAddressError, setIsAddressError] = useState(false);
  const [AddressErrorMessage, setAddressErrorMessage] = useState("");

  const [IsEmailError, setIsEmailError] = useState(false);
  const [EmailErrorMessage, setEmailErrorMessage] = useState("");

  const [IsMobileNoError, setIsMobileNoError] = useState(false);
  const [MobileNoErrorMessage, setMobileNoErrorMessage] = useState("");

  const [IsRewardsError, setIsRewardsError] = useState(false);
  const [RewardsErrorMessage, setRewardsErrorMessage] = useState("");

  const [IsPincodeError, setIsPincodeError] = useState(false);
  const [PincodeErrorMessage, setPincodeErrorMessage] = useState("");

  const [IsPanError, setIsPanError] = useState(false);
  const [PanErrorMessage, setPanErrorMessage] = useState("");

  const [IsAdharNoError, setIsAdharNoError] = useState(false);
  const [AdharNoErrorMessage, setAdharNoErrorMessage] = useState("");



  const [IsProcessing, setIsProcessing] = useState(false);

  const onNameChange = (value) => {
    setName(value);
    if (value.length <= 3) {
      setIsNameError(true);
      setNameErrorMessage("Name should be minimum of 3 character");
    } else {
      setIsNameError(false);
      setNameErrorMessage("");
    }
  };

  const onStatushange = (value) => {
    setActive(value);
  };

  const onAddressChange = (value) => {
    setAddress(value);
    if (value.length <= 10) {
      setIsAddressError(true);
      setAddressErrorMessage("Address must be minimum of 10 character");
    } else {
      setIsAddressError(false);
      setAddressErrorMessage("");
    }
  };

  const onEmailChange = (value) => {
    setEmail(value);
    if (!validator.isEmail(value)) {
      setIsEmailError(true);
      setEmailErrorMessage("Enter valid email in format of abc@gmail.com");
    } else {
      setIsEmailError(false);
      setEmailErrorMessage("");
    }
  };

  const onMobileChange = (value) => {
    setMobileNo(value);
    if (value.length !== 10) {
      setIsMobileNoError(true);
      setMobileNoErrorMessage("Mobile number must be of 10 digit");
    } else {
      setIsMobileNoError(false);
      setMobileNoErrorMessage("");
    }
  };

  const onRewardsChange = (value) => {
    setRewards(value);
    if (value < 0) {
      setIsRewardsError(true);
      setRewardsErrorMessage("Invalid rewards");
    } else {
      setIsRewardsError(false);
      setRewardsErrorMessage("");
    }
  };

  const onPincodeChange = (value) => {
    setPincode(value);
    if (value.length !== 6) {
      setIsPincodeError(true);
      setPincodeErrorMessage("Pincode must be of 6 digits");
    } else {
      setIsPincodeError(false);
      setPincodeErrorMessage("");
    }
  };

  const onPanChange = (value) => {
    setPan(value);
    if (value.length !== 10) {
      setIsPanError(true);
      setPanErrorMessage("PAN must be of 10 alphanumeric character");
    } else {
      setIsPanError(false);
      setPanErrorMessage("");
    }
  };

  const onAdharNoChange = (value) => {
    setAdharNo(value);
    if (value.length !== 12) {
      setIsAdharNoError(true);
      setAdharNoErrorMessage("Adhar number must of 12 digits");
    } else {
      setIsAdharNoError(false);
      setAdharNoErrorMessage("");
    }
  };


  const onSubmit = async (e) => {
    e.preventDefault();
    setIsProcessing(true);
    await submitHandler({ name: Name, address: Address, email: Email, mobileNo: MobileNo, rewards: Rewards, pinCode: Pincode, pan: Pan, adharNo: AdharNo, active: Active, photoIdUrl: "", profilePhotoUrl: "" });
    setIsProcessing(false);
  };
  return (
    <>
      <form onSubmit={onSubmit}>
        <
          // @ts-ignore
          Stack spacing={5} >
          {
            alertMessage !== '' ? <Alert status='error'><AlertIcon />{alertMessage}</Alert> : <></>
          }
          <FormControl display='flex' alignItems='center' >
            <FormLabel htmlFor='sales-officer-status' mb='0'>
              Status
              <IconButton
                aria-label="info"
                title='Marking sales officer as inactive will terminate all the login session immediately'
                icon={<RiInformationLine />}
                color={'ORANGE'}
                variant="unstyled"
              />
            </FormLabel>
            <Switch id='sales-officer-status' size='md' direction='row' marginLeft={"230"} olorScheme='green' defaultChecked={Active} value={!Active} onChange={(e) => onStatushange(e.currentTarget.value)} />
          </FormControl>

          <FormControl isRequired isInvalid={IsNameError}>
            <FormLabel>Name:</FormLabel>
            <Input
              type="text"
              placeholder="Enter name of the sales officer"
              onChange={(e) => onNameChange(e.currentTarget.value)}
              value={Name}
            />
            <FormErrorMessage>{NameErrorMessage}</FormErrorMessage>
          </FormControl>

          <FormControl isRequired isInvalid={IsMobileNoError}>
            <FormLabel>MobileNo:</FormLabel>
            <NumberInput value={MobileNo} onChange={(e) => onMobileChange(e)}>
              <NumberInputField maxLength={10} required autoComplete="off" textAlign={'left'} />
            </NumberInput>
            <FormErrorMessage>{MobileNoErrorMessage}</FormErrorMessage>
          </FormControl>

          <FormControl isRequired isInvalid={IsEmailError}>
            <FormLabel>Email:</FormLabel>
            <Input
              type="text"
              placeholder="Enter email of the sales officer"
              onChange={(e) => onEmailChange(e.currentTarget.value)}
              value={Email}
            />
            <FormErrorMessage>{EmailErrorMessage}</FormErrorMessage>
          </FormControl>

          <FormControl isRequired isInvalid={IsAddressError}>
            <FormLabel>Complete Address:</FormLabel>
            <Textarea
              placeholder="Enter complete address"
              onChange={(e) => onAddressChange(e.currentTarget.value)}
              value={Address}
            ></Textarea>
            <FormErrorMessage>{AddressErrorMessage}</FormErrorMessage>
          </FormControl>

          <FormControl isRequired isInvalid={IsPincodeError}>
            <FormLabel>Pincode:</FormLabel>
            <NumberInput value={Pincode} onChange={(e) => onPincodeChange(e)}>
              <NumberInputField maxLength={6} required autoComplete="off" textAlign={'left'} />
            </NumberInput>
            <FormErrorMessage>{PincodeErrorMessage}</FormErrorMessage>
          </FormControl>

          <FormControl isRequired isInvalid={IsAdharNoError}>
            <FormLabel>AdharNo:</FormLabel>
            <NumberInput value={AdharNo} onChange={(e) => onAdharNoChange(e)}>
              <NumberInputField maxLength={16} required autoComplete="off" textAlign={'left'} />
            </NumberInput>
            <FormErrorMessage>{AdharNoErrorMessage}</FormErrorMessage>
          </FormControl>

          <FormControl isRequired isInvalid={IsPanError}>
            <FormLabel>PAN:</FormLabel>
            <Input
              type="text"
              placeholder="Enter PAN of the sales officer"
              onChange={(e) => onPanChange(e.currentTarget.value)}
              value={Pan}
              maxLength={10}
            />
            <FormErrorMessage>{PanErrorMessage}</FormErrorMessage>
          </FormControl>

          <FormControl isRequired isInvalid={IsRewardsError}>
            <FormLabel>Rewards:</FormLabel>
            <Input
              type="number"
              placeholder="Enter the rewards point for sales officer"
              onChange={(e) => onRewardsChange(e.currentTarget.value)}
              value={Rewards}
            />
            <FormErrorMessage>{RewardsErrorMessage}</FormErrorMessage>
          </FormControl>
          <Button colorScheme={"brand"} type="submit" isLoading={IsProcessing}>
            Submit
          </Button>
        </Stack>
      </form>
    </>
  );
}
