import { getAccessToken } from "storage/session";
import { getRequest } from "./axios";

export const SendError = (message) => { return { result: null, error: message }; };
export const SendResult = (res) => { return { result: res, error: null }; };
export const getHeaderWithAccessToken = () => { return { headers: { "Access-Control-Allow-Origin": "*", 'access-token': getAccessToken() } }; };

export const CustomFetch = async (url) => {
    return await getRequest(url, getHeaderWithAccessToken());
};
