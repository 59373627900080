import {
  Box,
  Flex,
  Heading,
  IconButton,
  Stack,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { AiFillEye } from "react-icons/ai";
import { BiEdit } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import DeleteItemComponent from "components/deleteItemComponent";

export default function ViewZone({ data, onEdit, onDelete, isDeleting }) {
  const navigate = useNavigate();

  return (
    <>
      <Box
        w={"full"}
        boxShadow={"xl"}
        rounded="md"
        p={2}
        border={"1px"}
        borderColor={"gray.300"}
        m={1}
      >
        <Flex justifyContent={"space-between"}>
          <Stack direction={"column"} spacing={0} ml={5}>
            <Heading size={"md"}>{data.zoneName}</Heading>
            <Text fontSize={12} color="gray">
              {data.description}
            </Text>
          </Stack>
          <Stack direction={"row"} mr={5}>
            <Tooltip label="Edit Zone" bg={"brand.600"}>
              <IconButton
                aria-label="Edit"
                icon={<BiEdit />}
                colorScheme={"brand"}
                variant="outline"
                onClick={() => onEdit(data.zoneCode)}
              />
            </Tooltip>
            <Tooltip label="View Retailers">
              <IconButton
                aria-label="View Retailers"
                icon={<AiFillEye />}
                colorScheme={"brand"}
                variant="outline"
                onClick={() => navigate(`/retailers?zone=${data.zoneCode}`)}
              />
            </Tooltip>

            <DeleteItemComponent
              isDeleting={isDeleting}
              title="Delete Zone"
              onDelete={() => onDelete(data.zoneCode)}
            />
          </Stack>
        </Flex>
      </Box>
    </>
  );
}
