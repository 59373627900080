import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import { useShowSuccessToast } from "hooks/ToastUtil";
import { updateZone } from "http/zone";
import { useState } from "react";
import ZoneForm from "./ZoneForm";

export default function EditZone({ isOpen, onClose, zone, onSuccess }) {
  const [ErrorMessage, setErrorMessage] = useState("");
  const toast = useShowSuccessToast()
  const submitHandler = async (data) => {
    const response = await updateZone(zone.zoneCode, data);
    if (response.error) {
      setErrorMessage(response.error);
    }
    onClose();
    onSuccess();
    toast('Editing Zone', response.result);
  };
  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        closeOnOverlayClick={true}
        closeOnEsc={true}
        isCentered
        blockScrollOnMount={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Zone</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <ZoneForm submitHandler={submitHandler} zone={zone} alertMessage={ErrorMessage}/>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
