import {
  Box,
  Drawer,
  DrawerContent,
  Flex,
  useColorModeValue,
  useDisclosure,
  CloseButton,
  Link,
  Icon,
  IconButton,
  Image,
} from "@chakra-ui/react";
import { FiUsers, FiHome, FiMenu, FiSettings, FiLogOut, FiInfo, FiTrendingUp, FiUserCheck } from "react-icons/fi";
import { RiPlantFill } from "react-icons/ri";
import { FaShoppingCart } from "react-icons/fa";
import { BiCategory } from "react-icons/bi";
import { MdOutlineBorderHorizontal, MdLocalOffer, MdPayments } from "react-icons/md";
import { useMatch, Link as RouterLink } from "react-router-dom";
import ToggleColorMode from "./ToggleColorMode";

const LinkItems = [
  { name: "Dashboard", icon: FiHome, link: "/dashboard" },
  { name: "Categories", icon: BiCategory, link: "/categories" },
  { name: "Products", icon: RiPlantFill, link: "/products" },
  { name: "Zones", icon: MdOutlineBorderHorizontal, link: "/zones" },
  { name: "Retailers", icon: FiUsers, link: "/retailers" },
  { name: "Sales Officers", icon: FiUserCheck, link: "/sales" },
  { name: "Offers", icon: MdLocalOffer, link: "/offers" },
  { name: "Orders", icon: FaShoppingCart, link: "/orders" },
  { name: "Payment", icon: MdPayments, link: "/payments" },
  { name: "Enquiries", icon: FiInfo, link: "/enquiry" },
  { name: "Settings", icon: FiSettings, link: "/settings" },
  { name: "Reports", icon: FiTrendingUp, link: "/reports" },
  { name: "Logout", icon: FiLogOut, link: "/logout" }

];

export default function SimpleSidebar({ children }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Box minH="100vh" bg={useColorModeValue("gray.100", "gray.900")}>

      <SidebarContent
        onClose={() => onClose}
        display={{ base: "none", md: "block" }}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>

      {/* mobilenav */}
      <MobileNav display={{ base: "flex", md: "none" }} onOpen={onOpen} />
      <Box ml={{ base: 0, md: 60 }} p="4">
        {children}

      </Box>

    </Box>
  );
}

const SidebarContent = ({ onClose, ...rest }) => {
  return (
    <Box
      bg={useColorModeValue("#1c0527", "gray.900")}
      borderRight="1px"
      borderRightColor={useColorModeValue("gray.200", "gray.700")}
      w={{ base: "full", md: 60 }}
      pos="fixed"
      h="full"
      {...rest}
    >

      <Flex h="40" alignItems="center" mx="14" justifyContent="space-between">
        <Image src="/Logo_Admin.png" width={110} />
        <CloseButton display={{ base: "flex", md: "none" }} onClick={onClose} />
      </Flex>
      <ToggleColorMode></ToggleColorMode>
      {/* <NavItem key={'Toggle Mode'} icon={ToggleColorMode} link={'/dashboard'}> &nbsp;</NavItem> */}
      {LinkItems.map((link) => (
        <NavItem key={link.name} icon={link.icon} link={link.link}>
          {link.name}
        </NavItem>
      ))}
    </Box>

  );
};

const NavItem = ({ icon, link, children, ...rest }) => {
  const match = useMatch(link);
  return (
    <Link
      as={RouterLink}
      to={link}
      style={{ textDecoration: "none" }}
      _focus={{ boxShadow: "none" }}
    >
      <Flex
        align="center"
        p="4"
        mx="4"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        _hover={{
          bg: "green.800",
          color: "white",
        }}
        color="white"
        bg={match ? "green.800" : "transparent"}
        {...rest}
      >
        {icon && (
          <Icon
            mr="4"
            fontSize="16"
            _groupHover={{
              color: "white",
            }}
            as={icon}
          />
        )}
        {children}
      </Flex>
    </Link>
  );
};

const MobileNav = ({ onOpen, ...rest }) => {
  return (
    <Flex
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 24 }}
      height="20"
      alignItems="center"
      bg={useColorModeValue("white", "gray.900")}
      borderBottomWidth="1px"
      borderBottomColor={useColorModeValue("gray.200", "gray.700")}
      justifyContent="flex-start"
      {...rest}
    >
      <IconButton
        variant="outline"
        onClick={onOpen}
        aria-label="open menu"
        icon={<FiMenu />}
      />
      <Image src="/Logo_Admin.png" width={90} />
    </Flex>
  );
};
