import {
  Button,
  Flex,
  Heading,
  Stack,
  useDisclosure,
  Center,
  SimpleGrid,
} from "@chakra-ui/react";
import useGetZones from "hooks/useGetZones";
import { useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import AddZone from "./components/AddZone";
import ViewZone from "./components/ViewZone";
import EditZone from "./components/EditZone";
import { useShowErrorToast, useShowSuccessToast } from "hooks/ToastUtil";
import { deleteZone } from "http/zone";
import PropagateLoader from "react-spinners/PropagateLoader";

export default function Zones() {
  const [SelectedZone, setSelectedZone] = useState(null);
  const {
    isOpen: isAddOpen,
    onOpen: onAddOpen,
    onClose: onAddClose,
  } = useDisclosure();
  const {
    isOpen: isEditOpen,
    onOpen: onEditOpen,
    onClose: onEditClose,
  } = useDisclosure();
  const { isLoading, data: zones, error, forceRefetch } = useGetZones();
  const successToast = useShowSuccessToast();
  const errorToast = useShowErrorToast();
  const [isDeleting, setIsDeleting] = useState(false);


  const onEditAZone = (zoneCode) => {
    setSelectedZone(zones.find((z) => z.zoneCode === zoneCode));
    onEditOpen();
  };


  const onDelete = async id => {
    setIsDeleting(true);
    const response = await deleteZone(id);
    if (response.error) {
      errorToast('Delete Sub Category', response.error);
    } else {
      forceRefetch({});
      successToast('Delete Sub Category', response.result);
    }
    setIsDeleting(false);
  };

  return (
    <>
      <Flex direction={"row-reverse"}>
        <Button
          colorScheme={"brand"}
          leftIcon={<AiOutlinePlus />}
          onClick={() => onAddOpen()}
        >
          Add New Zone
        </Button>
      </Flex>

      <Stack spacing={2} mt={6}>
        <Heading size={"md"}>Available Zones</Heading>
        {isLoading ? (
          <Center
            w={"full"}
            boxShadow={"None"}
            rounded="md"
            p={5}
          >
            <PropagateLoader
              color={'purple'}
              loading={isLoading}
              data-testid="loader"
              speedMultiplier={1} />
          </Center>
        ) : (
          <></>
        )}
        {!isLoading && !error && (zones === null || zones.length === 0) ? (
          <Center
            w={"full"}
            boxShadow={"xl"}
            rounded="md"
            p={5}
            color={"gray.500"}
            bg={"gray.100"}
            border={"1px"}
            borderColor={"gray.300"}
          >
            No Zones Created
          </Center>
        ) : (
          <></>
        )}

        <SimpleGrid minChildWidth={['full', 'full', '500px', '500px', '500px']} spacing='40px'>
          {zones?.map((zone) => (
            <ViewZone data={zone} onEdit={onEditAZone} key={zone.zoneCode} onDelete={onDelete} isDeleting={isDeleting} />
          ))}
          {/* </Flex> */}
        </SimpleGrid>
      </Stack>

      <AddZone
        isOpen={isAddOpen}
        onClose={onAddClose}
        onSuccess={() => forceRefetch({})}
      ></AddZone>
      <EditZone
        isOpen={isEditOpen}
        onClose={onEditClose}
        zone={SelectedZone}
        onSuccess={() => forceRefetch({})}
      ></EditZone>
    </>
  );
}
