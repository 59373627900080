import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Center, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import useGetTopFiveSalesOfficers from "hooks/useGetTopFiveSalesOfficers";
import useGetTopTenRetailers from "hooks/useGetTopTenRetailers";
import useGetTopSellingProducts from "hooks/useGetTopTenSellingProducts";
import useGetTopTenWeeklySellingProducts from "hooks/useGetTopTenWeeklySellingProducts";
import useGetWeeklySalesCategoryWise from "hooks/useGetWeeklySalesCategoryWise";
import { PropagateLoader } from "react-spinners";


export default function Reports() {

    const { topTenRetailersIsLoading, data: topTenRetailers } = useGetTopTenRetailers();
    const { topTenProductsIsLoading, data: topTenSeelingProducts } = useGetTopSellingProducts();
    const { topTenWeeklySellingProductsIsLoading, data: topTenWeeklySellingProducts } = useGetTopTenWeeklySellingProducts();
    const { topFiveSalesOfficersIsLoading, data: topFiveSalesOfficers } = useGetTopFiveSalesOfficers();
    const { weeklySalesCategoryWiseIsLoading, data: weeklySalesCategoryWise } = useGetWeeklySalesCategoryWise();

    return (
        <>
            <Accordion defaultIndex={[0]} allowMultiple >




                {/* Top 10 selling products */}
                <AccordionItem boxShadow={"xl"} rounded="3xl" marginBottom={"10"}>
                    <AccordionButton>
                        <Box as="span" flex='100%' textAlign='left' fontSize={"18"} fontWeight={750}  >
                            TOP 10 SELLING PRODUCTS
                        </Box>
                        <AccordionIcon boxSize={5} />
                    </AccordionButton>
                    <AccordionPanel pb={4} rounded="xl">
                        {topTenProductsIsLoading ? (
                            <Center
                                w={"full"}
                                boxShadow={"none"}
                                rounded="md"
                                p={5}
                                color={"gray.500"}
                                bg={"gray.100"}
                                border={"0px"}
                                borderColor={"gray.300"}

                            >
                                <PropagateLoader
                                    color={'purple'}
                                    loading={topTenProductsIsLoading}
                                    aria-label="Loading enquiries"
                                    data-testid="loader"
                                    speedMultiplier={1}
                                />

                            </Center>
                        ) : (
                            <>
                                {topTenSeelingProducts != null && topTenSeelingProducts.length > 0
                                    ?
                                    <Table border={"collapse"} variant='striped'>
                                        <Thead bgColor={"gray.600"} >
                                            <Th fontSize={"12"} fontWeight={900} textColor={'white'}>#</Th>
                                            <Th fontSize={"12"} fontWeight={900} textColor={'white'}>Name</Th>
                                            <Th fontSize={"12"} fontWeight={900} textColor={'white'}>Product Origin</Th>
                                            <Th fontSize={"12"} fontWeight={900} textColor={'white'}>Company</Th>
                                            <Th fontSize={"12"} fontWeight={900} textColor={'white'}>ManufacturedBy</Th>
                                            <Th fontSize={"12"} fontWeight={900} textColor={'white'}>Description</Th>
                                            <Th fontSize={"12"} fontWeight={900} textColor={'white'}>Pack</Th>
                                            <Th fontSize={"12"} fontWeight={900} textColor={'white'}>Sold Quantity</Th>
                                            <Th fontSize={"12"} fontWeight={900} textColor={'white'}>Sales Amount</Th>
                                        </Thead>
                                        <Tbody fontSize={"12"}>
                                            {
                                                topTenSeelingProducts?.map((products, index) => (
                                                    <Tr >
                                                        <Td>{index + 1}.</Td>
                                                        <Td key={index}>
                                                            {products.productName}
                                                        </Td>
                                                        <Td>{products.productOrigin}</Td>
                                                        <Td>{products.companyName}</Td>
                                                        <Td>{products.manufacturedBy}</Td>
                                                        <Td>{products.description}</Td>
                                                        <Td>{products.pack}</Td>
                                                        <Td>{products.soldQuntity}</Td>
                                                        <Td>Rs. {products.salesAmount}</Td>
                                                    </Tr>
                                                )
                                                )
                                            }
                                        </Tbody>
                                    </Table>
                                    :
                                    <Center fontSize={"12"}>
                                        No products details
                                    </Center>
                                }
                            </>
                        )}
                    </AccordionPanel>
                </AccordionItem>


                {/* Top 10 weekly selling products */}
                <AccordionItem boxShadow={"xl"} rounded="3xl" fontSize={18} marginBottom={"10"}>
                    <AccordionButton>
                        <Box as="span" flex='100%' textAlign='left' fontSize={"18"} fontWeight={750}  >
                            TOP 10 WEEKLY SELLING PRODUCTS
                        </Box>
                        <AccordionIcon boxSize={5} />
                    </AccordionButton>
                    <AccordionPanel pb={4} rounded="xl">
                        {topTenWeeklySellingProductsIsLoading ? (
                            <Center
                                w={"full"}
                                boxShadow={"none"}
                                rounded="md"
                                p={5}
                                color={"gray.500"}
                                bg={"gray.100"}
                                border={"0px"}
                                borderColor={"gray.300"}

                            >
                                <PropagateLoader
                                    color={'purple'}
                                    loading={topTenWeeklySellingProductsIsLoading}
                                    aria-label="Loading enquiries"
                                    data-testid="loader"
                                    speedMultiplier={1}
                                />

                            </Center>
                        ) : (
                            <>
                                {topTenWeeklySellingProducts != null && topTenWeeklySellingProducts.lenght > 0
                                    ?
                                    <Table border={"collapse"} variant='striped'>
                                        <Thead bgColor={"gray.600"} >
                                            <Th fontSize={"12"} fontWeight={900} textColor={'white'}>#</Th>
                                            <Th fontSize={"12"} fontWeight={900} textColor={'white'}>Name</Th>
                                            <Th fontSize={"12"} fontWeight={900} textColor={'white'}>Product Origin</Th>
                                            <Th fontSize={"12"} fontWeight={900} textColor={'white'}>Company</Th>
                                            <Th fontSize={"12"} fontWeight={900} textColor={'white'}>ManufacturedBy</Th>
                                            <Th fontSize={"12"} fontWeight={900} textColor={'white'}>Description</Th>
                                            <Th fontSize={"12"} fontWeight={900} textColor={'white'}>Pack</Th>
                                            <Th fontSize={"12"} fontWeight={900} textColor={'white'}>Sold Quantity</Th>
                                            <Th fontSize={"12"} fontWeight={900} textColor={'white'}>Sales Amount</Th>
                                        </Thead>
                                        <Tbody fontSize={"12"}>
                                            {
                                                topTenWeeklySellingProducts?.map((products, index) => (
                                                    <Tr >
                                                        <Td>{index + 1}.</Td>
                                                        <Td key={index}>
                                                            {products.productName}
                                                        </Td>
                                                        <Td>{products.productOrigin}</Td>
                                                        <Td>{products.companyName}</Td>
                                                        <Td>{products.manufacturedBy}</Td>
                                                        <Td>{products.description}</Td>
                                                        <Td>{products.pack}</Td>
                                                        <Td>{products.soldQuntity}</Td>
                                                        <Td>Rs. {products.salesAmount}</Td>
                                                    </Tr>
                                                )
                                                )
                                            }
                                        </Tbody>
                                    </Table>
                                    :
                                    <Center fontSize={"12"}>
                                        No products details
                                    </Center>
                                }

                            </>
                        )}
                    </AccordionPanel>
                </AccordionItem>

                {/* Top 10 weekly selling products category wise */}
                <AccordionItem boxShadow={"xl"} rounded="3xl" fontSize={18} marginBottom={"10"}>
                    <AccordionButton>
                        <Box as="span" flex='100%' textAlign='left' fontSize={"18"} fontWeight={750}  >
                            TOP WEEKLY SELLING PRODUCTS CATEGORY WISE
                        </Box>
                        <AccordionIcon boxSize={5} />
                    </AccordionButton>
                    <AccordionPanel pb={4} rounded="xl">
                        {weeklySalesCategoryWiseIsLoading ? (
                            <Center
                                w={"full"}
                                boxShadow={"none"}
                                rounded="md"
                                p={5}
                                color={"gray.500"}
                                bg={"gray.100"}
                                border={"0px"}
                                borderColor={"gray.300"}

                            >
                                <PropagateLoader
                                    color={'purple'}
                                    loading={weeklySalesCategoryWiseIsLoading}
                                    aria-label="Loading enquiries"
                                    data-testid="loader"
                                    speedMultiplier={1}
                                />

                            </Center>
                        ) : (
                            <>
                                {weeklySalesCategoryWise != null && weeklySalesCategoryWise.length > 0 ?
                                    <Table border={"collapse"} variant='striped'>
                                        <Thead bgColor={"gray.600"} >
                                            <Th fontSize={"12"} fontWeight={900} textColor={'white'}>#</Th>
                                            <Th fontSize={"12"} fontWeight={900} textColor={'white'}>Name</Th>
                                            <Th fontSize={"12"} fontWeight={900} textColor={'white'}>Product Origin</Th>
                                            <Th fontSize={"12"} fontWeight={900} textColor={'white'}>Company</Th>
                                            <Th fontSize={"12"} fontWeight={900} textColor={'white'}>ManufacturedBy</Th>
                                            <Th fontSize={"12"} fontWeight={900} textColor={'white'}>Description</Th>
                                            <Th fontSize={"12"} fontWeight={900} textColor={'white'}>Category</Th>
                                            <Th fontSize={"12"} fontWeight={900} textColor={'white'}>Pack</Th>
                                            <Th fontSize={"12"} fontWeight={900} textColor={'white'}>Sold Quantity</Th>
                                            <Th fontSize={"12"} fontWeight={900} textColor={'white'}>Sales Amount</Th>
                                        </Thead>
                                        <Tbody fontSize={"12"}>
                                            {
                                                weeklySalesCategoryWise?.map((products, index) => (
                                                    <Tr >
                                                        <Td>{index + 1}.</Td>
                                                        <Td key={index}>
                                                            {products.productName}
                                                        </Td>
                                                        <Td>{products.productOrigin}</Td>
                                                        <Td>{products.companyName}</Td>
                                                        <Td>{products.manufacturedBy}</Td>
                                                        <Td>{products.description}</Td>
                                                        <Td>{products.category}</Td>
                                                        <Td>{products.pack}</Td>
                                                        <Td>{products.soldQuntity}</Td>
                                                        <Td>Rs. {products.salesAmount}</Td>
                                                    </Tr>
                                                )
                                                )
                                            }
                                        </Tbody>
                                    </Table>
                                    :
                                    <Center fontSize={"12"}>
                                        No products details
                                    </Center>
                                }
                            </>
                        )}
                    </AccordionPanel>
                </AccordionItem>


                {/* Top 10 retailers */}
                <AccordionItem boxShadow={"xl"} rounded="3xl" fontSize={18} marginBottom={"10"} >
                    <AccordionButton>
                        <Box as="span" flex='100%' textAlign='left' fontSize={"18"} fontWeight={750} >TOP 10 RETAILERS</Box>
                        <AccordionIcon boxSize={5} />
                    </AccordionButton>
                    <AccordionPanel pb={4} rounded="xl">
                        {topTenRetailersIsLoading ? (
                            <Center
                                w={"full"}
                                boxShadow={"none"}
                                rounded="md"
                                p={5}
                                color={"gray.500"}
                                bg={"gray.100"}
                                border={"0px"}
                                borderColor={"gray.300"}

                            >
                                <PropagateLoader
                                    color={'purple'}
                                    loading={topTenRetailersIsLoading}
                                    aria-label="Loading enquiries"
                                    data-testid="loader"
                                    speedMultiplier={1}
                                />

                            </Center>
                        ) : (
                            <>
                                {topTenRetailers != null && topTenRetailers.length > 0
                                    ?
                                    <Table border={"collapse"} variant='striped'>
                                        <Thead bgColor={"gray.600"} >
                                            <Th fontSize={"12"} fontWeight={900} textColor={'white'}>#</Th>
                                            <Th fontSize={"12"} fontWeight={900} textColor={'white'}>FirmName</Th>
                                            <Th fontSize={"12"} fontWeight={900} textColor={'white'}>MobileNo</Th>
                                            <Th fontSize={"12"} fontWeight={900} textColor={'white'}>Proprietor</Th>
                                            <Th fontSize={"12"} fontWeight={900} textColor={'white'}>Total Orders</Th>
                                            <Th fontSize={"12"} fontWeight={900} textColor={'white'}>Orders Amount</Th>
                                            <Th fontSize={"12"} fontWeight={900} textColor={'white'}>Address</Th>
                                        </Thead>
                                        <Tbody fontSize={"12"}>
                                            {
                                                topTenRetailers?.map((retailers, index) => (
                                                    <Tr >
                                                        <Td>{index + 1}.</Td>
                                                        <Td key={index}>
                                                            {retailers.firmName}
                                                        </Td>
                                                        <Td>{retailers.retailerId}</Td>
                                                        <Td>{retailers.proprietorName}</Td>
                                                        <Td>{retailers.totalOrders}</Td>
                                                        <Td>Rs. {retailers.totalOrderAmount}</Td>
                                                        <Td>{retailers.address}</Td>
                                                    </Tr>
                                                )
                                                )
                                            }
                                        </Tbody>
                                    </Table>
                                    :
                                    <Center fontSize={"12"}>
                                        No retailers details
                                    </Center>
                                }
                            </>
                        )}
                    </AccordionPanel>
                </AccordionItem>
                {/* Top 5 sales officers */}
                <AccordionItem boxShadow={"xl"} rounded="3xl" fontSize={18} marginBottom={"10"} >
                    <AccordionButton>
                        <Box as="span" flex='100%' textAlign='left' fontSize={"18"} fontWeight={750}  >
                            TOP 5 SALES OFFICERS
                        </Box>
                        <AccordionIcon boxSize={5} />
                    </AccordionButton>
                    <AccordionPanel pb={4} rounded="xl">
                        {topFiveSalesOfficersIsLoading ? (
                            <Center
                                w={"full"}
                                boxShadow={"none"}
                                rounded="md"
                                p={5}
                                color={"gray.500"}
                                bg={"gray.100"}
                                border={"0px"}
                                borderColor={"gray.300"}

                            >
                                <PropagateLoader
                                    color={'purple'}
                                    loading={topFiveSalesOfficersIsLoading}
                                    aria-label="Loading enquiries"
                                    data-testid="loader"
                                    speedMultiplier={1}
                                />

                            </Center>
                        ) : (
                            <>
                                {topFiveSalesOfficers != null && topFiveSalesOfficers.length > 0
                                    ?
                                    <Table border={"collapse"} variant='striped'>
                                        <Thead bgColor={"gray.600"} >
                                            <Th fontSize={"12"} fontWeight={900} textColor={'white'}>#</Th>
                                            <Th fontSize={"12"} fontWeight={900} textColor={'white'}>Name</Th>
                                            <Th fontSize={"12"} fontWeight={900} textColor={'white'}>Address</Th>
                                            <Th fontSize={"12"} fontWeight={900} textColor={'white'}>total Orders</Th>
                                            <Th fontSize={"12"} fontWeight={900} textColor={'white'}>Total Order Amount</Th>
                                        </Thead>
                                        <Tbody fontSize={"12"}>
                                            {
                                                topFiveSalesOfficers?.map((products, index) => (
                                                    <Tr >
                                                        <Td>{index + 1}.</Td>
                                                        <Td key={index}>
                                                            {products.name}
                                                        </Td>
                                                        <Td>{products.address}</Td>
                                                        <Td>{products.totalOrders}</Td>
                                                        <Td>Rs. {products.totalOrderAmount}</Td>
                                                    </Tr>
                                                )
                                                )
                                            }
                                        </Tbody>
                                    </Table>
                                    :
                                    <Center fontSize={"12"}>
                                        No sales officers details
                                    </Center>
                                }
                            </>
                        )}
                    </AccordionPanel>
                </AccordionItem>
            </Accordion>
        </>
    );
}
