import { deleteRequest, getBaseUrl, postRequest, putRequest} from "./axios";
import { getHeaderWithAccessToken } from "./helper";

const ENDPOINT = 'payment';

export const getPaymentsEndPoint = () => `${getBaseUrl()}${ENDPOINT}`;

export const addPaymentDetails = async (paymentDetails) => {
    return await postRequest(`${ENDPOINT}`, paymentDetails, getHeaderWithAccessToken());
};

export const deletePaymentDetails = async (id) => {
    return await deleteRequest(`${ENDPOINT}/${id}`, getHeaderWithAccessToken());
};

export const updatePaymentDetails = async (paymentDetails) => {
    return await putRequest(`${ENDPOINT}/${paymentDetails.id}`, paymentDetails, getHeaderWithAccessToken());
}

export const uploadBarcodeImage = async (id, formData) => {
    return await putRequest(`${ENDPOINT}/barcode/${id}`, formData, getHeaderWithAccessToken());
}

export const getPaymentsByIdEndPoint = (id)  => `${getBaseUrl()}${ENDPOINT}/+${id}`

