import {
  Button,
  Flex,
  Stack,
  useDisclosure,
  Center,
  SimpleGrid,
} from "@chakra-ui/react";
import { useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { useShowErrorToast, useShowSuccessToast } from "hooks/ToastUtil";
import useGetSalesOfficers from 'hooks/useGetSalesOfficers';
import ViewSalesOfficer from './components/ViewSalesOfficers';
import AddSalesOfficers from './components/AddSalesOfficer';
import ViewDetailedSalesOfficers from "./components/ViewDetailedSalesOfficers";
import { deboardSalesOfficer, getUploadImageUrl } from "http/sales";
import EditSalesOfficer from "./components/EditSalesOfficer";
import PropagateLoader from "react-spinners/PropagateLoader";
import ChangeImageSales from "./components/ChangeImageSales";

export default function Sales() {

  const successToast = useShowSuccessToast();
  const errorToast = useShowErrorToast();

  const [SelectedOfficer, setSelectedOfficer] = useState(null);
  const {
    isOpen: isEditOpen,
    onOpen: onEditOpen,
    onClose: onEditClose,
  } = useDisclosure();

  const {
    isOpen: isAddOpen,
    onOpen: onAddOpen,
    onClose: onAddClose,
  } = useDisclosure();

  const {
    isOpen: isViewDetailedOpen,
    onOpen: onViewDetailedOpen,
    onClose: onViewDetailedClose,
  } = useDisclosure();

  const { isLoading, data: sales, error, forceRefetch } = useGetSalesOfficers();

  const [isDeleting, setIsDeleting] = useState(false);

  const onViewDetails = (mobileNo) => {
    setSelectedOfficer(sales.find((sale) => sale.mobileNo === mobileNo));
    onViewDetailedOpen();
  };

  const onDeboardSalesOfficer = async (id, mobileNo) => {
    const temp = sales.find((sale) => sale.mobileNo === mobileNo);
    if (temp) {
      setIsDeleting(true);
      const response = await deboardSalesOfficer(id, mobileNo);
      if (response.error) errorToast("Deboard Sales Officer", response.error);
      else {
        successToast("Deboard Sales Officer", response.result);
        forceRefetch({});
      }
      setIsDeleting(false);
    }
  };

  const onEditDetails = (mobileNo) => {
    const temp = sales.find((sale) => sale.mobileNo === mobileNo);
    if (temp) {
      setSelectedOfficer({ ...SelectedOfficer, ...temp });
      onEditOpen();
    }
  };

  const {
    isOpen: isSalesImageChangeOpen,
    onOpen: onSalesImageChangeOpen,
    onClose: onSalesImageChangeClose,
  } = useDisclosure();

  const onChangeImage = async (mobileNo) => {
    alert(mobileNo)
    setSelectedOfficer(sales.find((z) => z.id === mobileNo));
    onSalesImageChangeOpen();
  };

  return (
    <>
      <Flex direction={"row-reverse"}>
        <Button
          colorScheme={"brand"}
          leftIcon={<AiOutlinePlus />}
          onClick={() => {
            setSelectedOfficer(null);
            onAddOpen();
          }}
        >
          Board Sales Officer
        </Button>
      </Flex>

      <Stack spacing={2} mt={6}>
        {isLoading ? (
          <Center
            w={"full"}
            boxShadow={"none"}
            rounded="md"
            p={5}
            border={"0px"}
          >
            <PropagateLoader
              color={'purple'}
              loading={isLoading}
              data-testid="loader"
              speedMultiplier={1} />
          </Center>
        ) : (
          <></>
        )}
        {!isLoading &&
          !error &&
          (sales === null || sales.length === 0 || Object.keys(sales).length === 0) ? (
          <Center
            w={"full"}
          >
            No Active Sales Officers
          </Center>
        ) : (

          <SimpleGrid
            minChildWidth="300px"
            spacing="40px"
            pl={[0, 0, "80px", "18px", "20px", "40px"]}
          >

            {sales?.map((sale) => (
              <ViewSalesOfficer
                isDeleting={isDeleting}
                data={sale}
                onEdit={(id, mobileNo) => onEditDetails(mobileNo)}
                key={sale.mobileNo}
                rest={{ my: 5 }}
                onDetailedView={(mobileNo) => onViewDetails(mobileNo)}
                onDelete={onDeboardSalesOfficer}
                onImageChange={onChangeImage}
              />
            ))}
          </SimpleGrid>
        )}

      </Stack>
      <ViewDetailedSalesOfficers
        data={SelectedOfficer}
        onClose={onViewDetailedClose}
        isOpen={isViewDetailedOpen}
        forceRefetch={forceRefetch}
      />

      <AddSalesOfficers
        isOpen={isAddOpen}
        onClose={onAddClose}
        onSuccess={() => forceRefetch({})}
      ></AddSalesOfficers>

      <EditSalesOfficer
        isOpen={isEditOpen}
        onClose={onEditClose}
        sales={SelectedOfficer}
        onSuccess={() => forceRefetch({})}
      ></EditSalesOfficer>

      <ChangeImageSales
        title={"Change Profile"}
        url={getUploadImageUrl(
          SelectedOfficer ? SelectedOfficer?.mobileNo : 0
        )}
        isOpen={isSalesImageChangeOpen}
        onClose={() => {
          onSalesImageChangeClose();
          forceRefetch({});
        }}
        onSuccess={() => {  }}
      ></ChangeImageSales>

    </>
  );
}
