import React, { useState } from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Flex,
  Heading,
  Spinner,
  useDisclosure,
} from "@chakra-ui/react";
import { SignOut } from "http/auth";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function Logout() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef();
  const navigate = useNavigate();
  const [isLoggingOut, setIsLoggingOut] = useState(false);

  const logout = () => {
    setIsLoggingOut(true);
    SignOut().then(() => {
      setIsLoggingOut(false);
      navigate("/login");
    });
  };

  const close = () => {
    onClose();
    navigate(-1);
  };

  useEffect(() => {
    onOpen();
  }, [onOpen]);

  return (
    <>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>Confirm Logout?</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>Are you sure you want to log out?</AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={close}>
              No
            </Button>
            <Button colorScheme="red" ml={3} onClick={logout}>
              Yes
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
      {isLoggingOut ? (
        <Flex
          height={"96vh"}
          justifyContent="center"
          alignItems={"center"}
          direction="column"
        >
          <Heading>Logging you out, please wait...</Heading>
          <Spinner />
        </Flex>
      ) : (
        <> </>
      )}
    </>
  );
}
