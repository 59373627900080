import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import { useShowSuccessToast } from "hooks/ToastUtil";
import { createCategory } from "http/category";
import { useState } from "react";
import CategoryForm from "./CategoryForm";

export default function AddCategory({ isOpen, onClose, onSuccess }) {
    const [ErrorMessage, setErrorMessage] = useState('');
    const toast = useShowSuccessToast()
    const submitHandler = async(data) => {
        const response = await createCategory(data);
        if(response.error) {
            setErrorMessage(response.error);
            return;
        }
        onClose();
        onSuccess();
        toast('Creating Category', response.result);
    };
  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        closeOnOverlayClick={false}
        closeOnEsc={false}
        isCentered
        blockScrollOnMount={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add New Category</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <CategoryForm submitHandler={submitHandler} alertMessage={ErrorMessage}/>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
