import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import { useShowSuccessToast } from "hooks/ToastUtil";
import { updateCategory } from "http/category";
import { useState } from "react";
import CategoryForm from "./CategoryForm";

export default function EditCategory({ isOpen, onClose, category, onSuccess }) {
  const [ErrorMessage, setErrorMessage] = useState("");
  const toast = useShowSuccessToast()
  const submitHandler = async (data) => {
    const temp = {...data};
    temp.id = category.id;
    const response = await updateCategory(temp);
    if (response.error) {
      setErrorMessage(response.error);
      return;
    }
    onClose();
    onSuccess();
    toast('Editing Category', response.result);
  };
  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        closeOnEsc={true}
        closeOnOverlayClick={true}
        isCentered
        blockScrollOnMount={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Category</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <CategoryForm submitHandler={submitHandler} zone={category} alertMessage={ErrorMessage}/>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
