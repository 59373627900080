import { Box, Center, Flex, HStack, IconButton, Text } from "@chakra-ui/react";
import DeleteItemComponent from "components/deleteItemComponent";
import ImageComponent from "components/imageComponent";
import { BiPencil } from "react-icons/bi";

export default function OfferCard({ offer, onEdit, onDelete, isDeleting, zones, onImageChange }) {
  return (
    <>
      <Box
        w={"full"}
        p={4}
        borderWidth={1}
        borderRadius={"lg"}
        boxShadow={"lg"}
        textAlign={"center"}
      >
        <Flex justifyContent={"space-between"}>
          <Text fontSize={"xl"} fontWeight={"bold"}>
            {offer.name} - (Zones:{" "}
            {offer?.zones
              .map((z) => {
                const zz = zones?.find((x) => {
                  // eslint-disable-next-line eqeqeq
                  return z == x.zoneCode;
                });
                if (zz) {
                  return zz.zoneName;
                } else {
                  return "";
                }
              })
              .join(", ")}
            )
          </Text>
          <HStack>
            <IconButton
              icon={<BiPencil />}
              aria-label={"edit"}
              variant={"outline"}
              colorScheme={"brand"}
              onClick={() => onEdit(offer.id)}
            ></IconButton>
            <DeleteItemComponent
              isDeleting={isDeleting}
              onDelete={() => onDelete(offer.id)}
              title={"Delete Offer"}
            ></DeleteItemComponent>
          </HStack>
        </Flex>
        <Center>
          <ImageComponent
            src={offer.bannerUrl}
            my={3}
            onClick={() => onImageChange(offer.id)}
            w={"full"}
            objectFit="contain"
          ></ImageComponent>
        </Center>
        <Text fontSize={"sm"} fontWeight={"normal"}>
          {offer.description}
        </Text>
      </Box>
    </>
  );
}
