import { getRequest, putRequest } from "./axios";
import { getHeaderWithAccessToken } from "./helper";

const ENDPOINT = 'order';

export const getOrdersEndPoint = () => ENDPOINT;
export const getOrdersByStatusEndPoint = (status) => !!status ? `${ENDPOINT}/status/${status}` : null;
export const getAOrderEndPoint = (id) => `${ENDPOINT}/${id}`;

export const getOrders = async () => {
    return await getRequest(ENDPOINT, getHeaderWithAccessToken());
};

export const getOrder = async (id) => {
    return await getRequest(`${ENDPOINT}/${id}`, getHeaderWithAccessToken());
};

export const updateOrder = async (id, data) => {
    return await putRequest(`${ENDPOINT}/${id}`, data, getHeaderWithAccessToken());
};

export const getOrdersSummaryEndPoint = () => ENDPOINT + "/summary";

export const getOrdersBySalesOfficerEndPoint = (mobileNo) => ENDPOINT + '/salesman/overview/' + mobileNo;

