import {
  Box,
  Heading,
  IconButton,
  Stack,
  Text,
  Tooltip,
  useColorModeValue,
} from "@chakra-ui/react";
import { BiCategory, BiEdit } from "react-icons/bi";
import { RiPlantFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import ImageComponent from "components/imageComponent";
import DeleteItemComponent from "components/deleteItemComponent";

export default function ViewZone({
  data,
  onEdit,
  onViewSubCategories,
  onDelete,
  isDeleting,
  onImageChange,
  rest,
}) {
  const navigate = useNavigate();

  const getImage = () =>
    data?.iconUrl?.endsWith("null") ? "/import_placeholder.png" : data.iconUrl;

  return (
    <>
      <Box
        role={"group"}
        p={6}
        maxW={"330px"}
        w={"full"}
        bg={useColorModeValue("white", "gray.800")}
        boxShadow={"2xl"}
        rounded={"lg"}
        pos={"relative"}
        zIndex={1}
        {...rest}
      >
        <Box
          pos={"relative"}
          height={"180px"}
          justifyContent={"center"}
          display={"flex"}
        >
          <ImageComponent src={getImage()} onClick={() => onImageChange(data.id)} 
            rounded={"lg"}
            height={180}
            width={180}
            objectFit={"cover"}></ImageComponent>

        </Box>
        <Stack pt={10} align={"center"}>
          <Heading fontSize={"2xl"} fontFamily={"body"} fontWeight={500}>
            {data.name}
          </Heading>
          <Text color={"gray.500"} fontSize={"sm"} textTransform={"uppercase"}>
            {data.description}
          </Text>
          <Stack mt={8} direction={"row"} spacing={4} flexWrap={"wrap"}>
            <Tooltip label="Edit Category" bg={"brand.600"}>
              <IconButton
                aria-label="Edit"
                icon={<BiEdit />}
                colorScheme={"brand"}
                variant="outline"
                onClick={() => onEdit(data.id)}
              />
            </Tooltip>
            <Tooltip label="View Sub Categories">
              <IconButton
                aria-label="View Sub Categories"
                icon={<BiCategory />}
                colorScheme={"brand"}
                variant="outline"
                onClick={() => onViewSubCategories(data.id)}
              />
            </Tooltip>
            <Tooltip label="View Products">
              <IconButton
                aria-label="View Products"
                icon={<RiPlantFill />}
                colorScheme={"brand"}
                variant="outline"
                onClick={() => navigate(`/products?category=${data.id}`)}
              />
            </Tooltip>
              <DeleteItemComponent onDelete={() => onDelete(data.id)} isDeleting={isDeleting} title="Delete Category"/>
          </Stack>
        </Stack>
      </Box>
    </>
  );
}
