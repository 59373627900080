import axios from "axios";
import { SignOut } from "./auth";
import { SendError, SendResult } from "./helper";

const Axios = axios.create({
	// Production URL
	baseURL: "https://rest.mirseed.com/mirseed/api/v1/",

	// Development URL
	// baseURL:"http://103.191.208.206:8089/mirseed/api/v1/",

	//Local URL
	// baseURL: "http://localhost:8089/mirseed/api/v1/",
});

Axios.interceptors.response.use(
	null,
	(error) => {
		if (error.response) {
			if (error.response.status === 401) {
				SignOut();
				window.location.href = "/login";
			}
		} else {
			SendError("Something went wrong");
		}
		return Promise.reject(error);
	}
);

export const getBaseUrl = () => Axios.getUri();

export const postRequest = async (url, data, headers={}, isData=false) => {
	try {
		const response = await Axios.post(url, data, headers);
		if(response.data.code !== 200 && response.data.code !== 201) {
			return SendError(response.data.message);
		}
		console.log(response.data, isData);
		if(isData) {
			return SendResult(response.data.data);
		}
		return SendResult(response.data.message);
	} catch (err) {
		console.log(err);
		return SendError(err.message);
	}
};

export const deleteRequest = async (url, headers={}) => {
	try {
		const response = await Axios.delete(url, headers);
		if(response.data.code !== 200) {
			return SendError(response.data.message);
		}
		return SendResult(response.data.message);
	} catch (err) {
		console.log(err);
		return SendError(err.message);
	}
};

export const putRequest = async (url, data, headers={}) => {
	try {
		const response = await Axios.put(url, data, headers);
		if(response.data.code !== 200) {
			return SendError(response.data.message);
		}
		return SendResult(response.data.message);
	} catch (err) {
		console.log(err);
		return SendError(err.message);
	}
};

export const getRequest = async (url, headers={}) => {
	try {
		const response = await Axios.get(url, headers);
		if(response.data.code !== 200) {
			return SendError(response.data.message);
		}
		return SendResult(response.data.data);
	} catch (err) {
		console.log(err);
		return SendError(err.message);
	}
};
