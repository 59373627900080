import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Text,
  SimpleGrid,
  Button,
  Center,
  Image,
  VStack,
  useDisclosure,
  Td,
  Table,
  Thead,
  Th,
  Tbody,
  Tr,
  Flex,
  Spacer,
  HStack,
  Select,
  Input
  // @ts-ignore
} from "@chakra-ui/react";
// @ts-ignore
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import { useShowErrorToast, useShowSuccessToast } from "hooks/ToastUtil";
// @ts-ignore
import { Icon } from '@chakra-ui/react'
import { MdDownload, MdFullscreen } from 'react-icons/md'
import {
  getRewardsStatementByMonths,
  getUploadFertilizersLicenceUrl,
  getUploadPesticidesLicenceUrl,
  getUploadSeedsLicenceUrl,
  redemRewardPoints,
  updateRetailer,
} from "http/retailer";
import { useEffect, useState } from "react";
import { TiTick } from "react-icons/ti";
import useGetRetailerDocuments from "hooks/useGetRetailerDocuments";
import ChangeImageRetailer from "./ChangeImageRetailer";
import useGetZones from "hooks/useGetZones";
import useGetAddressByCustomer from "hooks/getRetailerAddress";
import { Link } from "react-router-dom";
import { deleteAddressByUser } from "http/address";
import { Document, Page, pdfjs } from 'react-pdf'
import useFetch from "hooks/useFetch";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`


export default function ViewDetailedRetailer({
  data: RetailerInfo,
  isOpen,
  onClose,
  forceRefetch
}) {

  const { data: zones } = useGetZones();
  const successToast = useShowSuccessToast();
  const errorToast = useShowErrorToast();
  const [
    FertilizersLicenseVerifyInProgress,
    setFertilizersLicenseVerifyInProgress,
  ] = useState(false);
  const [
    PesticideLicenseVerifyInProgress,
    setPesticideLicenseVerifyInProgress,
  ] = useState(false);
  const [SeedsLicenseVerifyInProgress, setSeedsLicenseVerifyInProgress] =
    useState(false);

  const [ActivateInProgress, setActivateInProgress] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [pdfUrl, setPdfUrl] = useState(null);

  const { data: documents, forceRefetch: forceRefetchDocuments } = useGetRetailerDocuments(
    RetailerInfo ? RetailerInfo.mobileNo : 0
  );

  useEffect(() => {
    setPdfUrl(null);
    if (documents) {
      const doc = documents.documentUrls.find(
        (doc) => doc.documentType === "TransactionStatment"
      );
      if (doc) {
        if (!!doc.url) {
          setPdfUrl(doc.url);
        }
      }
    }
  }, [documents]);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const [ImageViewerData, setImageViewerData] = useState({
    url: "",
    type: 0,
  });

  const {
    isOpen: isRetailerImageChangeOpen,
    onOpen: onRetailerImageChangeOpen,
    onClose: onRetailerImageChangeClose,
  } = useDisclosure();

  const update = async (newData) => {
    const res = await updateRetailer(newData);
    if (res.error) {
      errorToast("Update Retailer", res.error);
    } else {
      successToast("Update Retailer", res.result);
      RetailerInfo = { ...newData };
      // forceRefetchDocuments({});
      onClose();
      // window.location.reload();
    }
  };

  const FertilizersLicenseVerify = async () => {
    setFertilizersLicenseVerifyInProgress(true);
    const temp = { ...RetailerInfo };
    temp.fertilizersLicenseVerified = true;
    await update(temp);
    setFertilizersLicenseVerifyInProgress(false);
  };

  const PesticideLicenseVerify = async () => {
    setPesticideLicenseVerifyInProgress(true);
    const temp = { ...RetailerInfo };
    temp.pesticideLicenseVerified = true;
    await update(temp);
    setPesticideLicenseVerifyInProgress(false);
  };

  const SeedsLicenseVerify = async () => {
    setSeedsLicenseVerifyInProgress(true);
    const temp = { ...RetailerInfo };
    temp.seedsLicenseVerified = true;
    await update(temp);
    setSeedsLicenseVerifyInProgress(false);
  };

  const Activate = async () => {
    setActivateInProgress(true);
    const temp = { ...RetailerInfo };
    temp.active = true;
    await update(temp);
    setActivateInProgress(false);
  };

  const hasSeedsLisenceImage = () => {
    if (documents) {
      const doc = documents.documentUrls.find(
        (doc) => doc.documentType === "SeedsLicense"
      );
      if (doc) {
        if (!!doc.url) {
          return doc.url;
        }
      }
    }
    return false;
  };

  const getSeedsLisenceImage = () => {
    const img = hasSeedsLisenceImage();
    return img ? img : "https://via.placeholder.com/32";
  };

  const hasFertilizersLicenseImage = () => {
    if (documents) {
      const doc = documents.documentUrls.find(
        (doc) => doc.documentType === "FertilizersLicense"
      );
      if (doc) {
        if (!!doc.url) {
          return doc.url;
        }
      }
    }
    return false;
  };
  const getFertilizersLicenseImage = () => {
    const img = hasFertilizersLicenseImage();
    return img ? img : "https://via.placeholder.com/32";
  };
  const hasPesticidesLicenceImage = () => {
    if (documents) {
      const doc = documents.documentUrls.find(
        (doc) => doc.documentType === "PesticidesLicence"
      );
      if (doc) {
        if (!!doc.url) {
          return doc.url;
        }
      }
    }
    return false;
  };
  const getPesticidesLicenceImage = () => {
    const img = hasPesticidesLicenceImage();
    return img ? img : "https://via.placeholder.com/32";
  };

  const onLisenceImageClick = (src, type) => {

    setImageViewerData({ url: src, type });
    onRetailerImageChangeOpen();
  };

  const onCloseImageViewer = () => {
    setImageViewerData({ isView: false, url: "", type: 0 });
  };

  const getZoneName = () => {
    if (RetailerInfo && zones) {
      const zone = zones.find((zone) => zone.zoneCode === RetailerInfo.zone);
      if (zone) {
        return zone.zoneName;
      }
    }
    return "";
  };

  const getMobileNumber = () => {
    if (RetailerInfo) {
      return RetailerInfo.mobileNo;
    } else {
      return 0
    }
  }

  const [url, setUrl] = useState(getRewardsStatementByMonths(getMobileNumber(), 'ONE_MONTH'));
  const { data: address } = useGetAddressByCustomer(getMobileNumber());
  const { data: rewards } = useFetch(url);
  const [show, setShow] = useState(false)
  const [redemption, setRedemption] = useState(0)

  const redemRewards = async () => {
    if (redemption > rewards?.totalRewards) {
      alert("Redemption point should not be more than total available rewards")
    } else {
      const res = await redemRewardPoints(getMobileNumber(), redemption);
      if (res.error) {
        errorToast("Rewards Redemption", res.error);
      } else {
        onClose();
        successToast("Rewards Redemption", res.result);
        forceRefetch({});
      }
    }
  }

  const onRedemptionChage = (event) => {
    setRedemption(event.target.value)
  }

  const onRangeChange = (e) => {
    const status = e.target.value;
    setUrl(getRewardsStatementByMonths(getMobileNumber(), status));
  }


  const deleteAddress = async (id) => {
    const response = await deleteAddressByUser(id)
    if (response.error) {
      errorToast('Delete Address', response.error);
    } else {
      successToast('Delete Address', response.result);
      onClose();
      window.location.reload();
    }
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        closeOnOverlayClick={true}
        closeOnEsc={true}
        isCentered
        blockScrollOnMount={true}
        size="5xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {RetailerInfo?.firmName}, Pro:  {RetailerInfo?.proprietorName} ({getZoneName()} ZONE)
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Tabs
              isFitted
              variant="enclosed"
              colorScheme={"purple"}
              minH={"500px"}
            >
              <TabList mb="1em">
                <Tab>Basic Details</Tab>
                <Tab>License Details</Tab>
                <Tab>Address</Tab>
                <Tab>Rewards Statement</Tab>
                <Tab>Transaction Details</Tab>
              </TabList>
              <TabPanels>
                <TabPanel overflowY="auto" height="650px">
                  <SimpleGrid columns={2} spacing={"25px"}>
                    <Text fontWeight={700}>Proprietor Name</Text>
                    <Text>{RetailerInfo?.proprietorName}</Text>
                    <Text fontWeight={700}>Firm Name</Text>
                    <Text>{RetailerInfo?.firmName}</Text>
                    <Text fontWeight={700}>Firm Type</Text>
                    <Text>{RetailerInfo?.userType}</Text>
                    <Text fontWeight={700}>Mobile No</Text>
                    <Text>{RetailerInfo?.mobileNo}</Text>
                    <Text fontWeight={700}>Email Id</Text>
                    <Text>{RetailerInfo?.email}</Text>
                    <Text fontWeight={700}>Address</Text>
                    <Text>{RetailerInfo?.address}</Text>
                    <Text fontWeight={700}>Pin Code</Text>
                    <Text>{RetailerInfo?.pinCode}</Text>
                    <Text fontWeight={700}>Activated</Text>
                    {RetailerInfo?.active === false ? (
                      <Button
                        colorScheme={"brand"}
                        onClick={Activate}
                        isLoading={ActivateInProgress}
                      >
                        Activate
                      </Button>
                    ) : (
                      <TiTick fontSize={24} color={"green"} />
                    )}
                    <Text fontWeight={700}>Wallet Balance</Text>
                    <Text>{RetailerInfo?.walletBalance}</Text>
                  </SimpleGrid>
                </TabPanel>
                <TabPanel overflowY="auto" height="650px">
                  <SimpleGrid columns={4} spacing={"30px"}>
                    <Text fontWeight={700}>PAN Number</Text>
                    <Text>{RetailerInfo?.pan}</Text>
                    <Text></Text>
                    <Text></Text>
                    <Text fontWeight={700}>GST Number</Text>
                    <Text>{RetailerInfo?.gstNo}</Text>
                    <Text></Text>
                    <Text></Text>
                    <Text fontWeight={700}>Seeds License Number</Text>
                    <Text>{RetailerInfo?.seedsLicenseNo}</Text>
                    {!RetailerInfo?.seedsLicenseVerified ? (
                      <Button
                        colorScheme={"brand"}
                        onClick={SeedsLicenseVerify}
                        isLoading={SeedsLicenseVerifyInProgress}
                      >
                        Verify
                      </Button>
                    ) : (
                      <Center>
                        <TiTick fontSize={24} color={"green"} />
                      </Center>
                    )}
                    <HStack>
                      <Image
                        height={'50%'}
                        width={'50%'}
                        src={getSeedsLisenceImage()}
                        alt="Seeds License"
                        _hover={{ cursor: "pointer" }}
                        onClick={() =>
                          onLisenceImageClick(getSeedsLisenceImage(), 1)
                        }
                      />
                      <a href={getSeedsLisenceImage()} rel="noreferrer" target="_blank">
                        <Icon as={MdFullscreen} boxSize={10} color={'purple'} /> &nbsp;&nbsp;&nbsp;&nbsp;
                      </a>
                    </HStack>
                    <Text fontWeight={700}>Pesticide License Number</Text>
                    <Text>{RetailerInfo?.pesticideLicenseNo}</Text>
                    {!RetailerInfo?.pesticideLicenseVerified ? (
                      <Button
                        colorScheme={"brand"}
                        onClick={PesticideLicenseVerify}
                        isLoading={PesticideLicenseVerifyInProgress}
                      >
                        Verify
                      </Button>
                    ) : (
                      <Center>
                        <TiTick fontSize={24} color={"green"} />
                      </Center>
                    )}
                    <HStack>
                      <Image
                        height={'50%'}
                        width={'50%'}
                        src={getPesticidesLicenceImage()}
                        alt="Pesticide License"
                        _hover={{ cursor: "pointer" }}
                        onClick={() =>
                          onLisenceImageClick(getPesticidesLicenceImage(), 2)
                        }
                      />
                      <a href={getPesticidesLicenceImage()} rel="noreferrer" target="_blank">
                        <Icon as={MdFullscreen} boxSize={10} color={'purple'} /> &nbsp;&nbsp;&nbsp;&nbsp;
                      </a>
                    </HStack>
                    <Text fontWeight={700}>Fertilizers License Number</Text>
                    <Text>{RetailerInfo?.fertilizersLicenseNo}</Text>
                    {!RetailerInfo?.fertilizersLicenseVerified ? (
                      <Button
                        colorScheme={"brand"}
                        onClick={FertilizersLicenseVerify}
                        isLoading={FertilizersLicenseVerifyInProgress}
                      >
                        Verify
                      </Button>
                    ) : (
                      <Center>
                        <TiTick fontSize={24} color={"green"} />
                      </Center>
                    )}
                    <HStack>
                      <Image
                        height={'50%'}
                        width={'50%'}
                        src={getFertilizersLicenseImage()}
                        alt="Fertilizers License"
                        _hover={{ cursor: "pointer" }}
                        onClick={() =>
                          onLisenceImageClick(getFertilizersLicenseImage(), 3)
                        }
                      />
                      <a href={getFertilizersLicenseImage()} rel="noreferrer" target="_blank">
                        <Icon as={MdFullscreen} boxSize={10} color={'purple'} /> &nbsp;&nbsp;&nbsp;&nbsp;
                      </a>
                    </HStack>
                  </SimpleGrid>
                </TabPanel>
                {/* Address Tab */}
                <TabPanel overflowY="auto" height="650px">
                  {address === null
                    ?
                    (
                      <center>No Address</center>
                    )
                    :
                    (
                      <Table border={"collapse"} variant='striped'>
                        <Thead >
                          <Th fontSize={"13"} fontWeight={"extrabold"}>Name</Th>
                          <Th fontSize={"13"} fontWeight={"extrabold"}>Address</Th>
                          <Th fontSize={"13"} fontWeight={"extrabold"}>Mobile #</Th>
                          <Th fontSize={"13"} fontWeight={"extrabold"}>Action</Th>
                        </Thead>
                        <Tbody>
                          {
                            address.map((add) => (
                              <Tr>
                                <Td >{add.name}</Td>
                                <Td>{add.address}, {add.landmark}, {add.addressType} -{add.pin}</Td>
                                <Td>{add.primaryMobile}, {add.secondaryMobile}</Td>
                                <Td>
                                  <Link to={"#"}>
                                    <Button className="delete-address" variant="solid" colorScheme="blue" mt={4} onClick={() => deleteAddress(add.id)}>Delete</Button>
                                  </Link>
                                </Td>
                              </Tr>
                            )
                            )
                          }
                        </Tbody>
                      </Table>
                    )
                  }
                </TabPanel>
                {/* Rewards statement tab */}
                <TabPanel overflowY="auto" height="650px">
                  <HStack p={0}>
                    <Text marginLeft={560} w={350}><b>SELECT STATEMENT RANGE</b></Text>
                    <Select w={280} bg={'brand'} placeholder="Select range" onChange={onRangeChange}>
                      <option value="ONE_MONTH">One Month</option>
                      <option value="THREE_MONTHS">Three Months</option>
                      <option value="SIX_MONTHS">Six Months</option>
                      <option value="TWELVE_MONTHS">One Year</option>
                    </Select>
                  </HStack>
                  {rewards == null
                    ?
                    (<center>No rewards statment, Please select range</center>)
                    :
                    (
                      <Table border={"collapse"} variant='striped'>
                        <Thead >
                          <Th fontSize={"13"} fontWeight={"extrabold"}>Serial#</Th>
                          <Th fontSize={"13"} fontWeight={"extrabold"}>Credit</Th>
                          <Th fontSize={"13"} fontWeight={"extrabold"}>Redemption</Th>
                          <Th fontSize={"13"} fontWeight={"extrabold"}>Reference</Th>
                          <Th fontSize={"13"} fontWeight={"extrabold"}>Date</Th>
                        </Thead>
                        <Tbody>
                          {
                            rewards?.rewardsStatement.map((reward, index) => (
                              <Tr>
                                <Td>{index + 1}.</Td>
                                <Td >{reward.credit > 0 ? reward.credit : <>-</>}</Td>
                                <Td>{reward.redemption > 0 ? reward.redemption : <>-</>}</Td>
                                <Td>{reward.notes}</Td>
                                <Td>{reward.createdAt}</Td>
                              </Tr>
                            )
                            )
                          }
                        </Tbody>
                      </Table>
                    )
                  }
                  <br />
                  <HStack p={0} marginLeft={560}>
                    <b>Total rewards: {rewards?.totalRewards}</b>
                    <Button colorScheme={"brand"} width={100} height={8} onClick={() => setShow(!show)}>Redem</Button>
                  </HStack>
                  <br />
                  <div p={100}>
                    {
                      show ? (
                        <>
                          <HStack p={0}>
                            <Text marginLeft={560}><b>Enter rewards</b></Text>
                            <Input type="number" w={150} id="redem_rewards" onChange={onRedemptionChage} value={redemption}></Input>
                            <Button colorScheme={"brand"} width={100} height={8} onClick={redemRewards}>Process</Button>
                          </HStack>
                        </>
                      ) : null
                    }
                  </div>
                </TabPanel>
                {/* Transaction Tab */}
                <TabPanel overflowY="auto" height="650px">
                  {pdfUrl === null ? (
                    <Center
                      w={"full"}
                      boxShadow={"xl"}
                      rounded="md"
                      p={5}
                      color={"white.500"}
                      bg={"white.100"}
                      border={"0px"}
                      borderColor={"white.300"}
                    >
                      No Transactions
                    </Center>
                  ) : (
                    <div>
                      <Flex minWidth='max-content' alignItems='center' gap='2'>
                        <Spacer />
                        <a href={pdfUrl} rel="noreferrer" target="_blank">
                          <Icon as={MdFullscreen} boxSize={10} color={'purple'} /> &nbsp;&nbsp;&nbsp;&nbsp;
                          <Icon as={MdDownload} boxSize={10} color={'purple'} />
                        </a>
                      </Flex>
                      <Document onLoadSuccess={onDocumentLoadSuccess} file={pdfUrl}>
                        <Page pageNumber={numPages} />
                      </Document>
                    </div>
                  )}
                </TabPanel>
              </TabPanels>
            </Tabs>
          </ModalBody>
        </ModalContent>
      </Modal >
      <ChangeImageRetailer
        title={"Change Image"}
        url={

          ImageViewerData.type === 1
            ? getUploadSeedsLicenceUrl(
              RetailerInfo ? RetailerInfo.mobileNo : ""
            )
            : ImageViewerData.type === 2
              ? getUploadPesticidesLicenceUrl(
                RetailerInfo ? RetailerInfo.mobileNo : ""
              )
              : getUploadFertilizersLicenceUrl(
                RetailerInfo ? RetailerInfo.mobileNo : ""
              )
        }
        isOpen={isRetailerImageChangeOpen}
        onClose={() => {
          onRetailerImageChangeClose();
          forceRefetchDocuments({});
        }}
        onSuccess={() => { }}
      ></ChangeImageRetailer>
      {
        ImageViewerData.isView && (
          <Center
            pos={"absolute"}
            w={"100vw"}
            h={"100vh"}
            top={0}
            left={0}
            bg={"gray"}
            opacity={0.9}
            zIndex={10000}
            onClick={onCloseImageViewer}
          >
            <VStack>
              <Image
                src={ImageViewerData.url}
                alt="Fertilizers License"
                onClick={(e) => e.stopPropagation()}
              />
            </VStack>
          </Center>
        )
      }
    </>
  );
}
