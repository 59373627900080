import { Link, useParams } from "react-router-dom";
import useProductRequestDetailsById from "hooks/useProductRequestDetailsById";
import { Box, Button, Center, Heading, Image, Spinner, Table, Tbody, Td, Th, Thead, Tr, Flex, Spacer } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom/dist";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useShowErrorToast, useShowSuccessToast } from "hooks/ToastUtil";
import { notifyAllCustomer } from "http/product";

  export default function ViewProductRequest() {
    const { variantId, totalRequest } = useParams();
    const {
      isLoading,
      data: productRequest,
      forceRefetch,
    } = useProductRequestDetailsById(variantId);
    const navigate = useNavigate();
  
    const successToast = useShowSuccessToast();
    const errorToast = useShowErrorToast();
  
    const notifyAll = async (variantId, name, pack) => {
      const response = await notifyAllCustomer(variantId, name, pack);
      if(response.error) {
        errorToast('Notify All customer', response.error);
      } else {
        successToast('Notify All customer', response.result);
        forceRefetch({});
      }
    };

    return (
      <>
      <Flex direction={"row"}>
        <Button
          colorScheme={"brand"}
          leftIcon={<AiOutlineArrowLeft />}
          onClick={() => navigate(-1)}
        >
          Go Back
        </Button>
      </Flex>
      <Spacer h={30} />

       {isLoading ? (
        <Center
          w={"full"}
          boxShadow={"xl"}
          rounded="md"
          p={5}
          color={"gray.500"}
          bg={"gray.100"}
          border={"1px"}
          borderColor={"gray.300"}
        >
        <Spinner />
        </Center>
      ) : (
          <div>
            <Heading size={"md"}>Requested products details</Heading> <br/>
          </div>
      )}

      {(productRequest===null || productRequest.length<1) ?(
        <></>
      ) : (
          <Box p={8} borderWidth={3} borderRadius={10} boxShadow="lg">
                <Table>
                  <Thead >
                        <Th fontSize={"13"} fontWeight={"extrabold"}>Product Name</Th>
                        <Th fontSize={"13"} fontWeight={"extrabold"}>Description</Th>
                        <Th fontSize={"13"} fontWeight={"extrabold"}>Pack</Th>
                        <Th fontSize={"13"} fontWeight={"extrabold"}>Company</Th>
                        <Th fontSize={"13"} fontWeight={"extrabold"}>Image</Th>
                        <Th fontSize={"13"} fontWeight={"extrabold"}>Requested By({totalRequest})</Th>
                        <Th fontSize={"13"} fontWeight={"extrabold"}>Notifications</Th>
                        <Th fontSize={"13"} fontWeight={"extrabold"}>Actions</Th>
                  </Thead>
                  <Tbody>
                  <Tr>
                      <Td>{productRequest.productName}</Td>
                      <Td>{productRequest.productDescription}</Td>
                      <Td>{productRequest.pack}</Td>
                      <Td>{productRequest.companyName}</Td>
                      <Td>
                        <Image boxSize='100px' src={productRequest.imagesUrl}/>
                      </Td>
                      <Td>
                      <div>
                        {
                          productRequest.customerDetails.map(details => {
                            return(<div>{details.firmName}[{details.proprieterName}-{details.customerId}]<br/><br/></div>)
                          })
                        }
                      </div>
                      </Td>
                      <Td>{productRequest.notifyCount}</Td>
                      <Td>
                          <Link to={'#'}>
                            <Button className="notify-all" variant="solid" colorScheme="blue" mt={4} onClick={()=> notifyAll(productRequest.variantId, productRequest.productName, productRequest.pack)}>Notify All</Button>
                        </Link>
                      </Td>
                </Tr>
                </Tbody>
                </Table>
          </Box>
      )}
      </>
    );
  }
  