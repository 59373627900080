import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  FormControl,
  InputGroup,
  InputLeftAddon,
  FormErrorMessage,
  Center,
  ModalFooter,
  Button,
  Input,
  HStack,
  SimpleGrid,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Heading,
  Text,
  Tooltip,
  useDisclosure,
  VStack,
  Select,
  Icon,
  Switch,
} from "@chakra-ui/react";
import { useShowSuccessToast, useShowErrorToast } from "hooks/ToastUtil";
import useGetCategories from "hooks/useGetCategories";
import useGetSubCategories from "hooks/useGetSubCategories";
import { createAProduct, updateAProduct } from "http/product";
import { useEffect, useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import AddVarientModal from "./AddVarientModal";

export default function AddEditProduct({
  isOpen,
  onClose,
  product = null,
  onSuccess,
  isEdit = false,
}) {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    benefits: product ? product.benefits : "",
    category: product ? product.category : 0,
    cod: product ? product.cod : false,
    recommended: product ? product.recommended : false,
    companyName: product ? product.companyName : "",
    description: product ? product.description : "",
    validity: product ? product.validity : "",
    manufacturedBy: product ? product.manufacturedBy : "",
    productName: product ? product.productName : "",
    productOrigin: product ? product.productOrigin : "",
    productType: product ? product.productType : "",
    returnPercentage: product ? product.returnPercentage : 0,
    returnTime: product ? product.returnTime : 0,
    searchKeyword: product ? product.searchKeyword : "",
    shippingCharges: product ? product.shippingCharges : 0,
    subCategory: product ? product.subCategory : 0,
    videoUrl: product ? product.videoUrl : "",
    createdAt: product ? product.createdAt : "",

  });

  const [Varients, setVarients] = useState(product ? product.varients : []);

  useEffect(() => {
    setFormData({
      benefits: product ? product.benefits : "",
      category: product ? product.category : 0,
      cod: product ? product.cod : false,
      recommended: product ? product.recommended : false,
      companyName: product ? product.companyName : "",
      description: product ? product.description : "",
      validity: product ? product.validity : "",
      manufacturedBy: product ? product.manufacturedBy : "",
      productName: product ? product.productName : "",
      productOrigin: product ? product.productOrigin : "",
      productType: product ? product.productType : "",
      returnPercentage: product ? product.returnPercentage : 0,
      returnTime: product ? product.returnTime : 0,
      searchKeyword: product ? product.searchKeyword : "",
      shippingCharges: product ? product.shippingCharges : 0,
      subCategory: product ? product.subCategory : 0,
      videoUrl: product ? product.videoUrl : "",
    });
    setVarients(product ? product.varients : []);
  }, [product]);

  const [errorMessage, setErrorMessage] = useState({
    benefits: null,
    category: null,
    cod: null,
    recommended:null,
    companyName: null,
    description: null,
    manufacturedBy: null,
    validity: null,
    productName: null,
    productOrigin: null,
    productType: null,
    returnPercentage: null,
    returnTime: null,
    searchKeyword: null,
    shippingCharges: null,
    subCategory: null,
    videoUrl: null,
    varients: null,
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const {
    isOpen: isProductAddVarientOpen,
    onOpen: onProductAddVarientOpen,
    onClose: onProductAddVarientClose,
  } = useDisclosure();
  const successToast = useShowSuccessToast();
  const errorToast = useShowErrorToast();
  const { data: categories } = useGetCategories();

  const { data: subcategories } = useGetSubCategories(formData.category);

  const onSubmit = async () => {
    let isError = false;
    const error = {
      benefits: null,
      category: null,
      cod: null,
      recommended: null,
      companyName: null,
      description: null,
      manufacturedBy: null,
      validity: null,
      productName: null,
      productOrigin: null,
      productType: null,
      returnPercentage: null,
      returnTime: null,
      searchKeyword: null,
      shippingCharges: null,
      subCategory: null,
      videoUrl: null,
      varients: null,
    };
    if (formData.productName === "") {
      error.productName = "Product name is required";
      isError = true;
    }
    if (formData.description === "") {
      error.description = "Description is required";
      isError = true;
    }
    if (formData.category === 0) {
      error.category = "Select category";
      isError = true;
    }
    if (formData.subCategory === 0) {
      error.subCategory = "Select sub category";
      isError = true;
    }
    if (formData.productType === "") {
      error.productType = "Product type is required";
      isError = true;
    }
    if (formData.productOrigin === "") {
      error.productOrigin = "Product origin is required";
      isError = true;
    }
    if (formData.companyName === "") {
      error.companyName = "Company name is required";
      isError = true;
    }
    if (formData.manufacturedBy === "") {
      error.manufacturedBy = "Manufactured by is required";
      isError = true;
    }
    if (formData.validity === "") {
      error.validity = "Validity is required";
      isError = true;
    }
    if (formData.returnTime < 0) {
      error.returnTime = "Return time is required";
      isError = true;
    }
    if (formData.returnPercentage < 0) {
      error.returnPercentage = "Return percentage is required";
      isError = true;
    }
    if (formData.shippingCharges < 0) {
      error.shippingCharges = "Shipping charges is required";
      isError = true;
    }
    if (formData.searchKeyword === "") {
      error.searchKeyword = "Search keyword is required";
      isError = true;
    }
    if (formData.benefits === "") {
      error.benefits = "Benefits is required";
      isError = true;
    }
    if (Varients.length === 0) {
      error.varients = "Atleast one varient is required";
      isError = true;
    }
    if (isError) {
      setErrorMessage(error);
      setTabIndex(0);
      return;
    }

    setErrorMessage(error);
    setIsSubmitting(true);
    let response = null;
    if (isEdit) {
      response = await updateAProduct(product.productCode, {
        ...formData,
        varients: Varients,
      });
    } else {
      response = await createAProduct({ ...formData, varients: Varients });
    }
    if (response.error) {
      errorToast(response.error);
    } else {
      successToast(response.result);
      if (!isEdit) {
        navigate(
          `/products?category=${formData.category}&subCategory=${formData.subCategory}`
        );
      }
      onClose();
      onSuccess();
    }
    setIsSubmitting(false);
  };

  const [tabIndex, setTabIndex] = useState(0);

  const handleTabsChange = (index) => {
    setTabIndex(index);
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} isCentered={true} size={"5xl"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{isEdit ? "Update Product" : "Add Product"}</ModalHeader>
          <ModalCloseButton />
          <ModalBody minH={600}>
            <Tabs index={tabIndex} onChange={handleTabsChange}>
              <TabList>
                <Tab>Basic Details</Tab>
                {Varients.map((item, index) => {
                  return <Tab key={index}>{item.pack}</Tab>;
                })}
                {!isEdit && (
                  <Tooltip label="Add A New Varient">
                    <Tab onClick={onProductAddVarientOpen}>
                      <Icon as={AiOutlinePlus}></Icon>
                    </Tab>
                  </Tooltip>
                )}
              </TabList>

              <TabPanels>
                <TabPanel>
                  <SimpleGrid columns={2} spacing={4}>
                    <FormControl
                      id="category"
                      isInvalid={errorMessage.category}
                    >
                      <InputGroup>
                        <InputLeftAddon minW={168}>Category</InputLeftAddon>
                        <Select
                          placeholder="Select category"
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              category: parseInt(e.target.value),
                            });
                          }}
                          value={formData.category}
                        >
                          {categories?.result?.map((item) => {
                            return (
                              <option key={item.id} value={item.id}>
                                {item.name}
                              </option>
                            );
                          })}
                        </Select>
                      </InputGroup>
                      <FormErrorMessage>
                        {errorMessage.category}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl
                      id="subcategories"
                      isInvalid={errorMessage.subCategory}
                    >
                      <InputGroup>
                        <InputLeftAddon minW={168}>Sub Category</InputLeftAddon>
                        <Select
                          placeholder="Select sub-category"
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              subCategory: parseInt(e.target.value),
                            });
                          }}
                          value={formData.subCategory}
                        >
                          {subcategories?.result?.subCategories?.map((item) => {
                            return (
                              <option value={item.id} key={item.id}>
                                {item.name}
                              </option>
                            );
                          })}
                        </Select>
                      </InputGroup>
                      <FormErrorMessage>
                        {errorMessage.subCategory}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl
                      id="productName"
                      isInvalid={errorMessage.productName}
                    >
                      <InputGroup>
                        <InputLeftAddon minW={168}>Product Name</InputLeftAddon>
                        <Input
                          type="text"
                          value={formData.productName}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              productName: e.target.value,
                            })
                          }
                        />
                      </InputGroup>
                      <FormErrorMessage>
                        {errorMessage.productName}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl
                      id="description"
                      isInvalid={errorMessage.description}
                    >
                      <InputGroup>
                        <InputLeftAddon minW={168}>Description</InputLeftAddon>
                        <Input
                          type="text"
                          value={formData.description}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              description: e.target.value,
                            })
                          }
                        />
                      </InputGroup>
                      <FormErrorMessage>
                        {errorMessage.description}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl
                      id="benefits"
                      isInvalid={errorMessage.benefits}
                    >
                      <InputGroup>
                        <InputLeftAddon minW={168}>Benefits</InputLeftAddon>
                        <Input
                          type="text"
                          value={formData.benefits}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              benefits: e.target.value,
                            })
                          }
                        />
                      </InputGroup>
                      <FormErrorMessage>
                        {errorMessage.benefits}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl
                      id="productOrigin"
                      isInvalid={errorMessage.productOrigin}
                    >
                      <InputGroup>
                        <InputLeftAddon minW={168}>
                          Product Origin
                        </InputLeftAddon>
                        <Input
                          type="text"
                          value={formData.productOrigin}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              productOrigin: e.target.value,
                            })
                          }
                        />
                      </InputGroup>
                      <FormErrorMessage>
                        {errorMessage.productOrigin}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl
                      id="productType"
                      isInvalid={errorMessage.productType}
                    >
                      <InputGroup>
                        <InputLeftAddon minW={168}>Product Type</InputLeftAddon>
                        <Input
                          type="text"
                          value={formData.productType}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              productType: e.target.value,
                            })
                          }
                        />
                      </InputGroup>
                      <FormErrorMessage>
                        {errorMessage.productType}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl
                      id="companyName"
                      isInvalid={errorMessage.companyName}
                    >
                      <InputGroup>
                        <InputLeftAddon minW={168}>Company Name</InputLeftAddon>
                        <Input
                          type="text"
                          value={formData.companyName}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              companyName: e.target.value,
                            })
                          }
                        />
                      </InputGroup>
                      <FormErrorMessage>
                        {errorMessage.companyName}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl
                      id="manufacturedBy"
                      isInvalid={errorMessage.manufacturedBy}
                    >
                      <InputGroup>
                        <InputLeftAddon minW={168}>
                          Manufactured By
                        </InputLeftAddon>
                        <Input
                          type="text"
                          value={formData.manufacturedBy}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              manufacturedBy: e.target.value,
                            })
                          }
                        />
                      </InputGroup>
                      <FormErrorMessage>
                        {errorMessage.manufacturedBy}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl
                      id="shippingCharges"
                      isInvalid={errorMessage.shippingCharges}
                    >
                      <InputGroup>
                        <InputLeftAddon minW={168}>
                          Shipping Charges
                        </InputLeftAddon>
                        <Input
                          type="number"
                          value={formData.shippingCharges}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              shippingCharges: parseInt(e.target.value),
                            })
                          }
                        />
                      </InputGroup>
                      <FormErrorMessage>
                        {errorMessage.shippingCharges}
                      </FormErrorMessage>
                    </FormControl>
                    <InputGroup>
                      <InputLeftAddon minW={168}>
                        Validity
                      </InputLeftAddon>
                      <Input
                        type="text"
                        value={formData.validity}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            validity: e.target.value,
                          })
                        }
                      />
                    </InputGroup>
                    <FormErrorMessage>
                      {errorMessage.validity}
                    </FormErrorMessage>
                    <FormControl
                      id="returnPercentage"
                      isInvalid={errorMessage.returnPercentage}
                    >
                      <InputGroup>
                        <InputLeftAddon minW={168}>
                          Return Percentage
                        </InputLeftAddon>
                        <Input
                          type="number"
                          value={formData.returnPercentage}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              returnPercentage: parseInt(e.target.value),
                            })
                          }
                        />
                      </InputGroup>
                      <FormErrorMessage>
                        {errorMessage.returnPercentage}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl
                      id="returnTime"
                      isInvalid={errorMessage.returnTime}
                    >
                      <InputGroup>
                        <InputLeftAddon minW={168}>Return Time</InputLeftAddon>
                        <Input
                          type="number"
                          value={formData.returnTime}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              returnTime: parseInt(e.target.value),
                            })
                          }
                        />
                      </InputGroup>
                      <FormErrorMessage>
                        {errorMessage.returnTime}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl
                      id="videoUrl"
                      isInvalid={errorMessage.videoUrl}
                    >
                      <InputGroup>
                        <InputLeftAddon minW={168}>Video Url</InputLeftAddon>
                        <Input
                          type="text"
                          value={formData.videoUrl}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              videoUrl: e.target.value,
                            })
                          }
                        />
                      </InputGroup>
                      <FormErrorMessage>
                        {errorMessage.videoUrl}
                      </FormErrorMessage>
                    </FormControl>

                    <FormControl id="createdAt" isInvalid={errorMessage.createdAt}>
                      <InputGroup>
                        <InputLeftAddon minW={168}>Available since</InputLeftAddon>
                        <Input
                          disabled
                          type="text"
                          value={formData.createdAt}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              createdAt: e.target.value,
                            })
                          }
                        />
                      </InputGroup>
                    </FormControl>

                    <FormControl id="cod" isInvalid={errorMessage.cod}>
                      <InputGroup>
                        <InputLeftAddon minW={168}>
                          Cash On Delivery?
                        </InputLeftAddon>
                        <Center w={"100%"}>
                          <Switch id='product-cod' size='md' direction='row' defaultChecked={formData.cod} value={!formData.cod}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                cod: e.target.checked,
                              })
                            }
                          />
                        </Center>
                      </InputGroup>
                    </FormControl>

                    <FormControl
                      id="searchKeyword"
                      isInvalid={errorMessage.searchKeyword}
                    >
                      <InputGroup>
                        <InputLeftAddon minW={168}>
                          Search Keyword
                        </InputLeftAddon>
                        <Input
                          type="text"
                          value={formData.searchKeyword}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              searchKeyword: e.target.value,
                            })
                          }
                        />
                      </InputGroup>
                      <FormErrorMessage>
                        {errorMessage.searchKeyword}
                      </FormErrorMessage>
                    </FormControl>

                    <FormControl id="recommendation" isInvalid={errorMessage.recommendation}>
                      <InputGroup>
                        <InputLeftAddon minW={168}>
                          Recommended?
                        </InputLeftAddon>
                        <Center w={"100%"}>
                          <Switch id='product-recommendation' size='md' direction='row' defaultChecked={formData.recommended} value={!formData.recommended}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                recommended: e.target.checked,
                              })
                            }
                          />
                        </Center>
                      </InputGroup>
                    </FormControl>

                  </SimpleGrid>
                </TabPanel>
                {Varients.map((item, index) => {
                  return (
                    <TabPanel key={index}>
                      <SimpleGrid columns={2} spacing={5}>
                        <Heading size={"xs"}>Pack</Heading>
                        <Input
                          type="text"
                          value={item.pack}
                          onChange={(e) => {
                            let temp = [...Varients];
                            temp[index].pack = e.target.value;
                            setVarients(temp);
                          }}
                        />
                        <Heading size={"xs"}>Description</Heading>
                        <Input
                          type="text"
                          value={item.description}
                          onChange={(e) => {
                            let temp = [...Varients];
                            temp[index].description = e.target.value;
                            setVarients(temp);
                          }}
                        />
                        <Heading size={"xs"}>Price</Heading>
                        <Input
                          type="number"
                          value={item.price}
                          onChange={(e) => {
                            let temp = [...Varients];
                            temp[index].price = e.target.value;
                            setVarients(temp);
                          }}
                        />
                        <Heading size={"xs"}>Min Quantity/Order</Heading>
                        <Input
                          type="number"
                          value={item.minimumQuantity}
                          onChange={(e) => {
                            let temp = [...Varients];
                            temp[index].minimumQuantity = e.target.value;
                            setVarients(temp);
                          }}
                        />
                        <Heading size={"xs"}>Max Quantity/Order</Heading>
                        <Input
                          type="number"
                          value={item.maxQty}
                          onChange={(e) => {
                            let temp = [...Varients];
                            temp[index].maxQty = e.target.value;
                            setVarients(temp);
                          }}
                        />
                        <Heading size={"xs"}>Stock</Heading>
                        <Input
                          type="number"
                          value={item.stock}
                          onChange={(e) => {
                            let temp = [...Varients];
                            temp[index].stock = e.target.value;
                            setVarients(temp);
                          }}
                        />

                        <Heading size={"xs"}>Rewards per quantity:</Heading>
                        <Input
                          type="number"
                          value={item.rewards}
                          onChange={(e) => {
                            let temp = [...Varients];
                            temp[index].rewards = e.target.value;
                            setVarients(temp);
                          }}
                        />

                        <Heading size={"xs"}>Default listing</Heading>
                        <Switch id='pack-default-listing' size='md' direction='row' defaultChecked={item.default} value={!item.default}
                          onChange={(e) => {
                            let temp = [...Varients];
                            temp[index].default = e.target.value;
                            setVarients(temp);
                          }} />
                      </SimpleGrid>
                    </TabPanel>
                  );
                })}
              </TabPanels>
            </Tabs>
          </ModalBody>
          <ModalFooter>
            <VStack w={"100%"}>
              <Text color={"red"} w={"100%"} textAlign="center">
                {errorMessage.varients}
              </Text>
              <HStack w={"100%"} justifyContent={"flex-end"}>
                <Button
                  colorScheme="brand"
                  mr={3}
                  onClick={onSubmit}
                  isLoading={isSubmitting}
                >
                  {isEdit ? "Update Product" : "Add Product"}
                </Button>
                <Button onClick={onClose}>Cancel</Button>
              </HStack>
            </VStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <AddVarientModal
        isOpen={isProductAddVarientOpen}
        onClose={() => {
          onProductAddVarientClose();
        }}
        product={product?.productCode}
        onSuccess={(data) => {
          data.productCode = product?.productCode;
          setVarients([...Varients, data]);
          onProductAddVarientClose();
        }}
        sendToServer={false}
      ></AddVarientModal>
    </>
  );
}
