import React from "react";
import { Flex, Heading } from "@chakra-ui/react";
import useGetDashboardData from "hooks/useFetDashboardData";
import DataCard from "./Components/DataCard";
import DataBarGraph from "./Components/DataBarGraph";
import PropagateLoader from "react-spinners/PropagateLoader";

export default function Dashboard() {
  const { isLoading, error, data } = useGetDashboardData();

  const getTotalOrders = () => {
    if (data) {
      return (
        data.totalOrders.created +
        data.totalOrders.outForDelivery +
        data.totalOrders.failed +
        data.totalOrders.approved +
        data.totalOrders.cancelled+
        data.totalOrders.delivered+
        data.totalOrders.packing+
        data.totalOrders.rejected
      );
    }
    return 0;
  };

  const getTodaysOrders = () => {
    if (data) {
      return (
        data.todaysOrders.created +
        data.todaysOrders.outForDelivery +
        data.todaysOrders.failed +
        data.todaysOrders.approved +
        data.todaysOrders.cancelled+
        data.todaysOrders.delivered+
        data.todaysOrders.packing+
        data.todaysOrders.rejected
      );
    }
    return 0;
  };

  const getTotalEnquiries = () => {
    if (data) {
      return data.enquries.newEnq + data.enquries.existing;
    }
    return 0;
  };

  const getTotalRetailers = () => {
    if (data) {
      return data.retailers.total;
    }
    return 0;
  };

  const getTotalOrdersGraphData = () => {
    if (data) {
      return [
        {
          name: "Created",
          value: data.totalOrders.created,
        },
        {
          name: "Packing",
          value: data.totalOrders.packing,
        },
        {
          name: "On Way",
          value: data.totalOrders.outForDelivery,
        },
        {
          name: "Failed",
          value: data.totalOrders.failed,
        },
        {
          name: "Approved",
          value: data.totalOrders.approved,
        },
        { 
          name: "Cancelled",
          value: data.totalOrders.cancelled,
        },
        {
          name: "Rejected",
          value: data.totalOrders.rejected,
        },
        {
          name: "Delivered",
          value: data.totalOrders.delivered,
        },
      ];
    }
    return [];
  };

  const getTodaysOrdersGraphData = () => {
    if (data) {
      return [
        {
          name: "Created",
          value: data.todaysOrders.created,
        },
        {
          name: "Packing",
          value: data.todaysOrders.packing,
        },
        {
          name: "On Way",
          value: data.todaysOrders.outForDelivery,
        },
        {
          name: "Failed",
          value: data.todaysOrders.failed,
        },
        {
          name: "Approved",
          value: data.todaysOrders.approved,
        },
        { 
          name: "Cancelled",
          value: data.todaysOrders.cancelled,
        },
        {
          name: "Rejected",
          value: data.todaysOrders.rejected,
        },
        {
          name: "Delivered",
          value: data.todaysOrders.delivered,
        },
      ];
    }
    return [];
  };

  const getEnquiriesGraphData = () => {
    if (data) {
      return [
        {
          name: "New",
          value: data.enquries.newEnq,
        },
        {
          name: "Existing",
          value: data.enquries.existing,
        },
      ];
    }
    return [];
  };
  const getRetailersGraphData = () => {
    if (data) {
      return [
        {
          name: "Active",
          value: data.retailers.active,
        },
        {
          name: "Inactive",
          value: data.retailers.inactive,
        },
      ];
    }
    return [];
  };

  return (
    <>
      {isLoading && (
        <Flex
          width={"100%"}
          height="96.5vh"
          justifyContent={"center"}
          alignItems={"center"}
          direction={"column"}
        >
          <Heading>Welcome to Mirseed Wholesale Admin...</Heading>
          <PropagateLoader
            color={'purple'}
            loading={isLoading}
            data-testid="loader"
            speedMultiplier={1}
          />
        </Flex>
      )}
      {error && <div>Something went wrong...</div>}
      {data && (
        <>
        <Flex
            width={"100%"}
            justifyContent={"center"}
            alignItems={"center"}
            direction={"row"}
          >
            <DataCard title={"Today's Orders"} value={getTodaysOrders()}></DataCard>
            {/* <DataCard title={"Coming soon"} value={0}></DataCard>
            <DataCard title={"Coming soon"} value={0}></DataCard> */}
          </Flex>
          <Flex
            width={"100%"}
            justifyContent={"center"}
            alignItems={"center"}
            direction={"row"}
          >
            <DataBarGraph
              title={"Today's order details"}
              data={getTodaysOrdersGraphData()}
            ></DataBarGraph>
            {/* <DataBarGraph
              title={"Coming soon.."}
              data={0}
            ></DataBarGraph>
            <DataBarGraph
              title={"Coming soon.."}
              data={0}
            ></DataBarGraph> */}
          </Flex>
          
          <Flex
            width={"100%"}
            justifyContent={"center"}
            alignItems={"center"}
            direction={"row"}
          >
            <DataCard title={"Total Orders"} value={getTotalOrders()}></DataCard>
            <DataCard
              title={"Total Enquiries"}
              value={getTotalEnquiries()}
            ></DataCard>
            <DataCard
              title={"Total Retailers"}
              value={getTotalRetailers()}
            ></DataCard>
          </Flex>
          <Flex
            width={"100%"}
            justifyContent={"center"}
            alignItems={"center"}
            direction={"row"}
          >
            <DataBarGraph
              title={"Order details"}
              data={getTotalOrdersGraphData()}
            ></DataBarGraph>
            <DataBarGraph
              title={"Enquirie details"}
              data={getEnquiriesGraphData()}
            ></DataBarGraph>
            <DataBarGraph
              title={"Retailer details"}
              data={getRetailersGraphData()}
            ></DataBarGraph>
          </Flex>

          
        </>
      )}
    </>
  );
}
