import { useToast } from "@chakra-ui/react";

export function useShowSuccessToast() {
    const toast = useToast();
    const show = (title, message) => toast({title, description: message, status: 'success', position: 'top-right'});

    return show;
}

export function useShowErrorToast() {
    const toast = useToast();
    const show = (title, message) => toast({title, description: message, status: 'error', position: 'top-right'});
    return show;
}