import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import { useShowErrorToast, useShowSuccessToast } from "hooks/ToastUtil";
import { useState } from "react";
import SalesOfficerForm from './SalesOfficerForm';
import { updateSalesOfficer } from 'http/sales';

export default function EditSalesOfficer({ isOpen, onClose, sales, onSuccess }) {

  const [ErrorMessage, setErrorMessage] = useState("");
  const errorToast = useShowErrorToast()
  const successToast = useShowSuccessToast()

  const submitHandler = async (data) => {
    const response = await updateSalesOfficer(sales.id, data);
    if (response.error) {
      setErrorMessage(response.error);
      errorToast("Update sales officer", response.error)
    }
    onClose();
    onSuccess();
    successToast('Update Sales Officer', response.result);
  };
  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        closeOnOverlayClick={false}
        closeOnEsc={false}
        isCentered
        blockScrollOnMount={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Sales Officer</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <SalesOfficerForm submitHandler={submitHandler} sales={sales} alertMessage={ErrorMessage} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
