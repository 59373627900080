import { Center, Heading, IconButton, Table, Tbody, Td, Textarea, Th, Thead, Tr } from "@chakra-ui/react";
import { useShowErrorToast, useShowSuccessToast } from "hooks/ToastUtil";
import useGetEnquiries from "hooks/useGetEnquiries";
import { deleteEnquiryById } from "http/enquiry";
import { useState } from "react";
import { RiDeleteBin3Fill } from "react-icons/ri";
import PropagateLoader from "react-spinners/PropagateLoader";



export default function Enquiry() {
  const { isLoading, data: enquiries, forceRefetch } = useGetEnquiries();
  const [isDeleting, setIsDeleting] = useState(false);

  const successToast = useShowSuccessToast();
  const errorToast = useShowErrorToast();
  console.log(isDeleting)

  const deleteEnquiry = async (id) => {
    setIsDeleting(true);
    const response = await deleteEnquiryById(id);
    if (response.error) {
      errorToast("Delete Enquiry", response.error);
    } else {
      successToast("Delete Enquiry", response.result);
      forceRefetch({});
    }
    setIsDeleting(false);
  }
  return (
    <>

      {isLoading ? (
        <Center
          w={"full"}
          boxShadow={"none"}
          rounded="md"
          p={5}
        >
          <PropagateLoader
            color={'purple'}
            loading={isLoading}
            aria-label="Loading enquiries"
            data-testid="loader"
            speedMultiplier={1}
          />

        </Center>
      ) : (
        <>
          <Heading size={"md"}>All Enquiries</Heading> <br />
          <Table border={"collapse"} variant='striped'>
            <Thead bgColor={"gray.600"} >
              <Th fontSize={"14"} fontWeight={900} textColor={'white'}>#</Th>
              <Th fontSize={"14"} fontWeight={900} textColor={'white'}>Name</Th>
              <Th fontSize={"14"} fontWeight={900} textColor={'white'}>MobileNo</Th>
              <Th fontSize={"14"} fontWeight={900} textColor={'white'}>Email</Th>
              <Th fontSize={"14"} fontWeight={900} textColor={'white'}>Message</Th>
              <Th fontSize={"14"} fontWeight={900} textColor={'white'}>Actions</Th>
            </Thead>
            <Tbody>
              {
                enquiries?.map((enquiry, index) => (
                  <Tr >
                    <Td>{index + 1}.</Td>
                    <Td key={index}>
                      {enquiry.customerName}
                    </Td>
                    <Td>{enquiry.mobileNo}</Td>
                    <Td>{enquiry.email}</Td>
                    <Td>
                      <Textarea value={enquiry.message}  />
                    </Td>
                    <Td>
                      <IconButton
                        aria-label="Delete"
                        title='Delete enquiry'
                        icon={<RiDeleteBin3Fill />}
                        colorScheme={"red"}
                        variant="outline"
                        onClick={() => deleteEnquiry(enquiry.id)}
                      /> &nbsp;&nbsp;&nbsp;
                    </Td>
                  </Tr>
                )
                )
              }
            </Tbody>
          </Table>
        </>
      )}
    </>
  )
}
