import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Stack,
  Center,
  Spinner,
  Button,
  Flex,
  useDisclosure,
} from "@chakra-ui/react";
import useGetSubCategories from "hooks/useGetSubCategories";
import { deleteSubCategory } from "http/subcategory";
import { useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import AddSubCategory from "./AddSubCategory";
import EditSubCategory from "./EditSubCategory";
import ViewSubCategory from "./ViewSubCategory";
import {useShowErrorToast, useShowSuccessToast} from "hooks/ToastUtil";

export default function ViewSubCategoriesList({
  isOpen,
  onClose,
  categoryId,
  categoryName,
}) {
  const [SelectedSubCategory, setSelectedSubCategory] = useState(null);
  const {
    isLoading,
    data: subcategories,
    error,
    refetch: forceRefetch,
  } = useGetSubCategories(categoryId);
  const {
    isOpen: isAddOpen,
    onOpen: onAddOpen,
    onClose: onAddClose,
  } = useDisclosure();
  const {
    isOpen: isEditOpen,
    onOpen: onEditOpen,
    onClose: onEditClose,
  } = useDisclosure();
  const [isDeleting, setIsDeleting] = useState(false);
  const successToast = useShowSuccessToast();
  const errorToast = useShowErrorToast();

  const onEdit = (id) => {
    setSelectedSubCategory(subcategories?.result?.subCategories.find((z) => z.id === id));
    onEditOpen();
  };

  const onDelete = async id => {
    setIsDeleting(true);
    const response = await deleteSubCategory(id);
    if(response.error) {
      errorToast('Delete Sub Category', response.error);
    } else {
      forceRefetch({});
      successToast('Delete Sub Category', response.result);
    }
    setIsDeleting(false);
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        closeOnOverlayClick={true}
        closeOnEsc={true}
        isCentered
        size={"4xl"}
        scrollBehavior={"inside"}
        blockScrollOnMount={false}
      >
        <ModalOverlay />
        <ModalContent h={"600px"}>
          <ModalHeader>
            <Flex justifyContent={"space-between"}>
              <div>Sub Categories Of {categoryName}</div>
              <Button
                colorScheme={"brand"}
                leftIcon={<AiOutlinePlus />}
                onClick={() => onAddOpen()}
                mr={6}
              >
                Add New Sub Category
              </Button>
            </Flex>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing={3}>
              {isLoading ? (
                <Center
                  w={"full"}
                  boxShadow={"xl"}
                  rounded="md"
                  p={5}
                  color={"gray.500"}
                  bg={"gray.100"}
                  border={"1px"}
                  borderColor={"gray.300"}
                >
                  <Spinner />
                </Center>
              ) : (
                <></>
              )}
              {!isLoading &&
              !error &&
              (subcategories === null ||
                subcategories?.result?.subCategories.length === 0) ? (
                <Center
                  w={"full"}
                  boxShadow={"xl"}
                  rounded="md"
                  p={5}
                  color={"gray.500"}
                  bg={"gray.100"}
                  border={"1px"}
                  borderColor={"gray.300"}
                >
                  No Sub Categories Created
                </Center>
              ) : (
                <></>
              )}
              {!isLoading &&
                !error &&
                subcategories?.result?.subCategories?.map((scat) => (
                  <ViewSubCategory
                    isDeleting={isDeleting}
                    data={scat}
                    key={scat.id}
                    onEdit={onEdit}
                    onDelete={onDelete}
                  />
                ))}
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>
      <AddSubCategory
        isOpen={isAddOpen}
        onClose={onAddClose}
        onSuccess={() => forceRefetch({})}
        categoryId={categoryId}
      />
      <EditSubCategory
        isOpen={isEditOpen}
        onClose={onEditClose}
        onSuccess={() => forceRefetch({})}
        subcategory={SelectedSubCategory}
        categoryId={categoryId}
      />
    </>
  );
}