import {
  Heading,
  Avatar,
  Box,
  Text,
  Stack,
  Badge,
  useColorModeValue,
  HStack,
  IconButton,
  Tooltip,
} from "@chakra-ui/react";
import { AiFillMail, AiFillPhone } from "react-icons/ai";
import {BiEdit} from "react-icons/bi";
import { FiEye } from "react-icons/fi";
import DeleteItemComponent from "components/deleteItemComponent";

export default function ViewSalesOfficer({
  isDeleting, 
  data,
  onEdit,
  key,
  rest,
  onDetailedView,
  onDelete,
  onImageChange,
}) {

  return (
    <>
      <Box
        flex={1}
        marginTop={5}
        p={4}
        maxW={"320px"}
        spacing={0}
        align="flex-start"
        h="full"
        bg={useColorModeValue("white", "gray.900")}
        boxShadow={"2xl"}
        rounded={"lg"}
        textAlign={"center"}
      >
        <Tooltip label="Click to change profile picture">
          {data.active===true ? <Avatar
            size={"xl"}
            src={'#'}
            mb={4}
            pos={"relative"}
            _after={{
              content: '""',
              w: 4,
              h: 4,
              bg: "green.300",
              border: "2px solid white",
              rounded: "full",
              pos: "absolute",
              bottom: 0,
              right: 3,
            }}
            _hover={{
              transform: "scale(1.02)",
              cursor: "pointer",
            }}
            onClick={() => onImageChange(data.id)}
          /> : 
          <Avatar
            size={"xl"}
            src={'#'}
            mb={4}
            pos={"relative"}
            _after={{
              content: '""',
              w: 4,
              h: 4,
              bg: "red.600",
              border: "2px solid white",
              rounded: "full",
              pos: "absolute",
              bottom: 0,
              right: 3,
            }}
            _hover={{
              transform: "scale(1.02)",
              cursor: "pointer",
            }}
          // onClick={() => onImageChange(data.id)}
          />
          }
        </Tooltip>
        <Heading fontSize={"3xl"} fontFamily={"body"}>
          {data.name}
        </Heading>
        <Text fontWeight={600} color={"gray.500"} mb={4} fontSize={"14"}>
          {data.address}
        </Text>
        <Heading fontSize={"xl"} fontFamily={"body"}>
          {data.zones?.map((zone) => (
            zone.zoneName + "  "
          ))}
        </Heading>
        <Stack align={"center"} justify={"center"} direction={"row"} mt={6}>
          <Badge
            px={2}
            py={1}
            bg={useColorModeValue("gray.50", "gray.800")}
            fontWeight={"400"}
            fontSize={"10"}
          >
            <HStack>
              <AiFillMail />
              <p>{data.email}</p>
            </HStack>
          </Badge>
          <Badge
            px={2}
            py={1}
            bg={useColorModeValue("gray.50", "gray.800")}
            fontWeight={"400"}
            fontSize={"10"}
          >
            <HStack>
              <AiFillPhone />
              <p>{data.mobileNo}</p>
            </HStack>
          </Badge>
        </Stack>

        <Stack
          mt={8}
          direction={"row"}
          spacing={4}
          flexWrap={"wrap"}
          justifyContent={"space-evenly"}
        >
          <Tooltip label="View Details">
            <IconButton
              aria-label="View Details"
              icon={<FiEye />}
              colorScheme={"brand"}
              variant="outline"
              onClick={() => onDetailedView(data.mobileNo)}
            />
          </Tooltip>
          <Tooltip label="Edit Sales Officer" bg={"brand.600"}>
            <IconButton
              aria-label="Edit"
              icon={<BiEdit />}
              colorScheme={"brand"}
              variant="outline"
              onClick={() => onEdit(data.id, data.mobileNo)}
            />
          </Tooltip>
          <DeleteItemComponent
            isDeleting={isDeleting}
            title="Deboard sales officer"
            onDelete={() => onDelete(data.id, data.mobileNo)}
          />
        </Stack>
      </Box>
    </>
  );
}
