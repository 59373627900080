import {
  Box,
  Container,
  Stack,
  Text,
  Flex,
  VStack,
  Button,
  Heading,
  SimpleGrid,
  StackDivider,
  List,
  ListItem,
  Center,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  IconButton,
  useBreakpointValue,
  useDisclosure,
  HStack,
  Tooltip,
} from "@chakra-ui/react";
import { MdModeEditOutline } from "react-icons/md";
import useProductByCode from "hooks/useProductByCode";
import {
  AiOutlineArrowLeft,
} from "react-icons/ai";
import { useNavigate, useParams } from "react-router-dom";
import useGetProductImages from "hooks/useGetProductImages";
import { BiLeftArrowAlt, BiPlus, BiRightArrowAlt } from "react-icons/bi";
import Slider from "react-slick";
import { useState } from "react";
import {
  deleteAProduct,
  deleteProductImage,
  makeAnImageAsDefault,
} from "http/product";
import { useShowSuccessToast, useShowErrorToast } from "hooks/ToastUtil";
import UploadImagesProduct from "./Components/UploadImagesProduct";
import AddVarientModal from "./Components/AddVarientModal";
import AddEditProduct from "./Components/AddEditProduct";
import DeleteItemComponent from "components/deleteItemComponent";

export default function ViewProduct() {
  const { productCode } = useParams();
  const navigate = useNavigate();
  const {
    isLoading,
    data: product,
    error,
    forceRefetch,
  } = useProductByCode(productCode);

  console.log(product)
  const {
    data: images,
    forceRefetch: forceRefetchImages,
  } = useGetProductImages(productCode);
  const [currentImage, setCurrentImage] = useState(0);
  const successToast = useShowSuccessToast();
  const errorToast = useShowErrorToast();

  const {
    isOpen: isProductImageChangeOpen,
    onOpen: onProductImageChangeOpen,
    onClose: onProductImageChangeClose,
  } = useDisclosure();
  const {
    isOpen: isProductAddVarientOpen,
    onOpen: onProductAddVarientOpen,
    onClose: onProductAddVarientClose,
  } = useDisclosure();
  const {
    isOpen: isAddEditProductOpen,
    onClose: onAddEditProductClose,
    onOpen: onAddEditProductOpen,
  } = useDisclosure();

  const [isDeletingImage, setIsDeletingImage] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  // As we have used custom buttons, we need a reference variable to
  // change the state
  const [slider, setSlider] = useState(null);

  // These are the breakpoints which changes the position of the
  // buttons as the screen size changes
  const top = useBreakpointValue({ base: "90%", md: "50%" });
  const side = useBreakpointValue({ base: "30%", md: "10px" });

  const handleTabsChange = (index) => { };

  const makeImageDefault = async () => {
    const res = await makeAnImageAsDefault(
      product.productCode,
      images[currentImage].id
    );
    if (res.error) {
      errorToast(res.error);
    } else {
      successToast("Update Product", res.result);
    }
  };

  const deleteImage = async () => {
    setIsDeletingImage(true);
    const res = await deleteProductImage(
      product.productCode,
      images[currentImage].id
    );
    if (res.error) {
      errorToast(res.error);
    } else {
      successToast(res.result);
      forceRefetchImages({});
    }
    setIsDeletingImage(false);
  };

  const onDeleteProduct = async () => {
    setIsDeleting(true);
    const response = await deleteAProduct(product.productCode);
    if (response.error) {
      errorToast(response.error);
    } else {
      successToast(response.result);
      navigate(-1);
    }
    setIsDeleting(false);
  };

  // Settings for the slider
  const settings = {
    dots: true,
    arrows: false,
    fade: true,
    infinite: true,
    autoplay: false,
    speed: 500,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnHover: true,
    beforeChange: (current, next) => {
      setCurrentImage(next);
    },
  };

  // These are the images used in the slide
  const cards = !images ? [] : images;

  return (
    <>
      <Flex direction={"row"}>
        <Button
          colorScheme={"brand"}
          leftIcon={<AiOutlineArrowLeft />}
          onClick={() => navigate(-1)}
        >
          Go Back
        </Button>
      </Flex>

      <Stack spacing={2} mt={6}>
        {isLoading ? (
          <Center
            w={"full"}
            boxShadow={"xl"}
            rounded="md"
            p={5}
            color={"gray.500"}
            bg={"gray.100"}
            border={"1px"}
            borderColor={"gray.300"}
          >
            <Spinner />
          </Center>
        ) : (
          <></>
        )}
        {!isLoading && !error && product === null ? (
          <Center
            w={"full"}
            boxShadow={"xl"}
            rounded="md"
            p={5}
            color={"gray.500"}
            bg={"gray.100"}
            border={"1px"}
            borderColor={"gray.300"}
          >
            Error Loading The Product
          </Center>
        ) : (
          <></>
        )}
        {!isLoading && !error && product ? (
          <Container maxW={"9xl"}>
            <SimpleGrid
              columns={{ base: 1, lg: 2 }}
              spacing={{ base: 8, md: 10 }}
              py={{ base: 5, md: 5 }}
            >
              <Flex direction={"column"}>
                <Box
                  position={"relative"}
                  height={"600px"}
                  width={"full"}
                  overflow={"hidden"}
                >
                  <HStack position={"absolute"} right={0} top={0}>
                    <Button
                      colorScheme={"brand"}
                      variant={"solid"}
                      size={"sm"}
                      onClick={makeImageDefault}
                      zIndex={1055}> Make Default Image
                    </Button>
                    <DeleteItemComponent
                      colorScheme={"brand"}
                      variant={"solid"}
                      isDeleting={isDeletingImage}
                      onDelete={deleteImage}
                      title={"Delete Image"}
                      zIndex={1055}
                    />
                  </HStack>
                  {/* CSS files for react-slick */}
                  <link
                    rel="stylesheet"
                    type="text/css"
                    charSet="UTF-8"
                    href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
                  />
                  <link
                    rel="stylesheet"
                    type="text/css"
                    charSet="UTF-8"
                    href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
                  />
                  <link
                    rel="stylesheet"
                    type="text/css"
                    href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
                  />
                  {/* Left Icon */}
                  <IconButton
                    aria-label="left-arrow"
                    colorScheme="brand"
                    borderRadius="full"
                    position="absolute"
                    left={side}
                    top={top}
                    transform={"translate(0%, -50%)"}
                    zIndex={2}
                    onClick={() => slider?.slickPrev()}
                  >
                    <BiLeftArrowAlt />
                  </IconButton>
                  {/* Right Icon */}
                  <IconButton
                    aria-label="right-arrow"
                    colorScheme="brand"
                    borderRadius="full"
                    position="absolute"
                    right={side}
                    top={top}
                    transform={"translate(0%, -50%)"}
                    zIndex={2}
                    onClick={() => slider?.slickNext()}
                  >
                    <BiRightArrowAlt />
                  </IconButton>
                  {/* Slider */}
                  <Slider {...settings} ref={(slider) => setSlider(slider)}>
                    {cards.map((url, index) => (
                      <Box
                        key={index}
                        height={"600px"}
                        position="relative"
                        backgroundPosition="center"
                        backgroundRepeat="no-repeat"
                        backgroundSize="cover"
                        backgroundImage={`url(${url.url})`}
                      />
                    ))}
                  </Slider>
                </Box>
                <Button
                  colorScheme={"brand"}
                  onClick={onProductImageChangeOpen}
                >
                  Upload Product Images
                </Button>
              </Flex>
              <Stack spacing={{ base: 6, md: 10 }}>
                <HStack
                  as={"header"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Heading
                    lineHeight={1.1}
                    fontWeight={600}
                    fontSize={{ base: "2xl", sm: "4xl", lg: "4xl" }}
                  >
                    {product.productName} &nbsp;({product.recommended === true ?
                      ("Recommended")
                      :
                      ("No Recommendation")
                    })


                  </Heading>
                  <HStack>
                    <Tooltip label={"Edit Product"}>
                      <IconButton
                        colorScheme={"brand"}
                        aria-label="edit product"
                        icon={<MdModeEditOutline />}
                        onClick={onAddEditProductOpen}
                      />
                    </Tooltip>
                    <DeleteItemComponent
                      isDeleting={isDeleting}
                      onDelete={onDeleteProduct}
                      title="Delete Product"
                    />
                  </HStack>
                </HStack>

                <Stack
                  spacing={{ base: 4, sm: 6 }}
                  direction={"column"}
                  divider={<StackDivider borderColor={"gray.200"} />}
                >
                  <VStack spacing={{ base: 4, sm: 6 }}>
                    <Text
                      color={"gray.500"}
                      fontSize={"2xl"}
                      fontWeight={"300"}
                    >
                      {product.description}
                    </Text>
                  </VStack>
                  <Box>
                    <Text
                      fontSize={{ base: "16px", lg: "18px" }}
                      color={"yellow.500"}
                      fontWeight={"500"}
                      textTransform={"uppercase"}
                      mb={"4"}
                    >
                      Benefits
                    </Text>

                    <Text>{product.benefits}</Text>
                  </Box>

                  <Tabs
                    variant="soft-rounded"
                    colorScheme="green"
                    defaultIndex={0}
                    onChange={handleTabsChange}
                  >
                    <HStack mb={5}>
                      <Text
                        fontSize={{ base: "16px", lg: "18px" }}
                        color={"yellow.500"}
                        fontWeight={"500"}
                        textTransform={"uppercase"}
                      >
                        Packs:
                      </Text>
                      <Tooltip label="Add New Pack">
                        <IconButton
                          icon={<BiPlus />}
                          aria-label={""}
                          colorScheme={"brand"}
                          onClick={onProductAddVarientOpen}
                        ></IconButton>
                      </Tooltip>
                    </HStack>
                    <TabList>
                      {product.varients.map((v) => (
                        <Tab key={v.id}>{v.pack}</Tab>
                      ))}
                    </TabList>
                    <TabPanels>
                      {product.varients.map((v) => {
                        return (
                          <TabPanel key={v.id}>
                            <List spacing={3}>
                              <ListItem>
                                <Text as={"span"} fontWeight={"bold"}>
                                  Description:
                                </Text>{" "}
                                {v.description}
                              </ListItem>
                              <ListItem>
                                <Text as={"span"} fontWeight={"bold"}>
                                  Price:
                                </Text>{" "}
                                INR {v.price}
                              </ListItem>
                              <ListItem>
                                {
                                  v.stock > 0 ?
                                    (
                                      <Text as={"span"} fontWeight={"bold"}>
                                        Stock: {" "}{v.stock}
                                      </Text>
                                    )
                                    : <ListItem color={"red"} fontWeight="bold">Out of stock</ListItem>
                                }
                              </ListItem>
                              <ListItem>
                                <Text as={"span"} fontWeight={"bold"}>
                                  Min Quntity/Order:
                                </Text>{" "}
                                {v.minimumQuantity}
                              </ListItem>
                              <ListItem>
                                <Text as={"span"} fontWeight={"bold"}>
                                  Max Quntity/Order:
                                </Text>{" "}
                                {v.maxQty}
                              </ListItem>
                              <ListItem>
                                <Text as={"span"} fontWeight={"bold"}>
                                  Rewards per quantity:
                                </Text>{" "}
                                {v.rewards}
                              </ListItem>

                              <ListItem>
                                {
                                  v.default === true ?
                                    (<Text as={"span"} fontWeight={"bold"}>
                                      Default pack: Yes
                                    </Text>)
                                    :
                                    (
                                      <Text as={"span"} fontWeight={"bold"}>
                                        Default pack: No
                                      </Text>
                                    )
                                }
                              </ListItem>
                            </List>
                          </TabPanel>
                        );
                      })}
                    </TabPanels>
                  </Tabs>

                  <Box>
                    <Text
                      fontSize={{ base: "16px", lg: "18px" }}
                      color={"yellow.500"}
                      fontWeight={"500"}
                      textTransform={"uppercase"}
                      mb={"4"}
                    >
                      Product Details
                    </Text>

                    <List spacing={2}>
                      <ListItem>
                        <Text as={"span"} fontWeight={"bold"}>
                          Validity:
                        </Text>{" "}
                        {product.validity}
                      </ListItem>
                      <ListItem>
                        <Text as={"span"} fontWeight={"bold"}>
                          Manufactured By:
                        </Text>{" "}
                        {product.manufacturedBy}
                      </ListItem>
                      <ListItem>
                        <Text as={"span"} fontWeight={"bold"}>
                          Company Name:
                        </Text>{" "}
                        {product.companyName}
                      </ListItem>
                      <ListItem>
                        <Text as={"span"} fontWeight={"bold"}>
                          Product Origin:
                        </Text>{" "}
                        {product.productOrigin}
                      </ListItem>
                      <ListItem>
                        <Text as={"span"} fontWeight={"bold"}>
                          Product Type:
                        </Text>{" "}
                        {product.productType}
                      </ListItem>
                      <ListItem>
                        <Text as={"span"} fontWeight={"bold"}>
                          Shipping Charges:
                        </Text>{" "}
                        INR {product.shippingCharges}
                      </ListItem>
                      <ListItem>
                        <Text as={"span"} fontWeight={"bold"}>
                          Return Time:
                        </Text>{" "}
                        {product.returnTime}
                      </ListItem>
                      <ListItem>
                        <Text as={"span"} fontWeight={"bold"}>
                          Return Percentage:
                        </Text>{" "}
                        {product.returnPercentage}%
                      </ListItem>
                      <ListItem>
                        <Text as={"span"} fontWeight={"bold"}>
                          Cash On Delivery:
                        </Text>{" "}
                        {product.cod ? "Available" : "Not Available"}
                      </ListItem>
                    </List>
                  </Box>
                </Stack>
              </Stack>
            </SimpleGrid>
          </Container>
        ) : (
          <></>
        )}
      </Stack>
      <UploadImagesProduct
        isOpen={isProductImageChangeOpen}
        onClose={() => {
          onProductImageChangeClose();
          forceRefetchImages({});
        }}
        product={product?.productCode}
      ></UploadImagesProduct>
      <AddVarientModal
        isOpen={isProductAddVarientOpen}
        onClose={() => {
          onProductAddVarientClose();
        }}
        product={product?.productCode}
        onSuccess={(data) => {
          onProductAddVarientClose();
          forceRefetch({});
        }}
      ></AddVarientModal>

      <AddEditProduct
        isOpen={isAddEditProductOpen}
        onClose={onAddEditProductClose}
        product={product}
        onSuccess={() => {
          forceRefetch({});
          onAddEditProductClose();
        }}
        isEdit={true}
      ></AddEditProduct>
    </>
  );
}
