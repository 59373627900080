import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import { useShowSuccessToast } from "hooks/ToastUtil";
import { updateSubCategory } from "http/subcategory";
import { useState } from "react";
import SubCategoryForm from "./SubCategoryForm";

export default function EditSubCategory({ isOpen, onClose, subcategory, onSuccess, categoryId }) {
  const [ErrorMessage, setErrorMessage] = useState("");
  const toast = useShowSuccessToast()
  const submitHandler = async (data) => {
    const temp = {...data[0]};
    temp.id = subcategory.id;
    temp.categoryId = categoryId;
    const response = await updateSubCategory(temp.id, temp);
    if (response.error) {
      setErrorMessage(response.error);
      return;
    }
    onClose();
    onSuccess();
    toast('Editing Sub Category', response.result);
  };
  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        closeOnOverlayClick={false}
        closeOnEsc={false}
        isCentered
        blockScrollOnMount={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Sub Category</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <SubCategoryForm submitHandler={submitHandler} subcategory={subcategory} alertMessage={ErrorMessage} categoryId={categoryId}/>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
