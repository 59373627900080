import { deleteRequest, getBaseUrl, postRequest, putRequest } from "./axios";
import { getHeaderWithAccessToken } from "./helper";

const ENDPOINT = 'banner';

export const getOffersEndPoint = () => `${getBaseUrl()}${ENDPOINT}`;

export const getUploadImageUrl = (id) => `${getBaseUrl()}${ENDPOINT}/upload/${id}`;

export const saveOffer = async (id, data) => {
    return await putRequest(`${ENDPOINT}/${id}`, data, getHeaderWithAccessToken());
}

export const createOffer = async (data) => {
    return await postRequest(`${ENDPOINT}`, data, getHeaderWithAccessToken());
}

export const deleteOffer = async (id) => {
    return await deleteRequest(`${ENDPOINT}/${id}`, getHeaderWithAccessToken());
}

