import { Tooltip, Image } from "@chakra-ui/react";

export default function ImageComponent({src, onClick, ...rest}) {
    return (
        <Tooltip label="Click To Change Image">
        <Image
          rounded={"lg"}
          height={180}
          width={180}
          objectFit={"cover"}
          src={!!src ? src : "https://via.placeholder.com/240x180"}
          _hover={{
            transform: "scale(1.02)",
            cursor: "pointer",
          }}
          onClick={onClick}
          {...rest}
        />
        </Tooltip>
    );
}