import { getBaseUrl, putRequest } from "./axios";
import { getHeaderWithAccessToken } from "./helper";

const ENDPOINT = 'settings';

export const getSettingsEndPoint = () => `${getBaseUrl()}${ENDPOINT}`;

export const saveSettings = async (data) => {
    return await putRequest(`${ENDPOINT}`, data, getHeaderWithAccessToken());
}

