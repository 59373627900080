import {
  Heading,
  Tbody,
  Table,
  Td,
  Tr,
  Button,
  Thead,
  Th,
  Image
} from "@chakra-ui/react";
import { useShowErrorToast, useShowSuccessToast } from "hooks/ToastUtil";
import useGetPayments from "hooks/useGetPayments";
import { deletePaymentDetails } from "http/payments";
import AddPayment from "./AddPayment";
import { AiOutlinePlus } from "react-icons/ai";
import { Box, Center, Flex } from '@chakra-ui/layout'
import { Link } from "react-router-dom";
import PropagateLoader from "react-spinners/PropagateLoader";


export default function Payments() {

  const {
    isLoading,
    data: payment,
    forceRefetch,
  } = useGetPayments();

  const successToast = useShowSuccessToast();
  const errorToast = useShowErrorToast();

  const deletePayment = async (id) => {
    const response = await deletePaymentDetails(id);
    if (response.error) {
      errorToast('Delete Payment', response.error);
    } else {
      successToast('Delete Payment', response.result);
      forceRefetch({});
    }
  };

  return (
    <>
      <Flex direction={"row-reverse"}>
        <Link to={"/addPayment"}>
          <Button
            colorScheme={"brand"}
            leftIcon={<AiOutlinePlus />}
          >
            Add Payment
          </Button>
        </Link>
      </Flex><br />
      {isLoading ? (
        <Center
          w={"full"}
          boxShadow={"None"}
          rounded="md"
          p={5}
          border={"0px"}
        >
          <PropagateLoader
            color={'purple'}
            loading={isLoading}
            aria-label="Loading enquiries"
            data-testid="loader"
            speedMultiplier={1}
          />
        </Center>
      ) : (
        <div>
          <Heading size={"md"}>Payment Details</Heading> <br />
        </div>
      )}
      {(payment === null || payment.length < 1) ? (
        <AddPayment></AddPayment>
      ) : (
        <Box p={8} borderWidth={3} borderRadius={10} boxShadow="lg">
          <Heading size={"md"}>Payment Details</Heading> <br />
          <Table>
            <Thead >
              <Th fontSize={"13"} fontWeight={"extrabold"}>Account#</Th>
              <Th fontSize={"13"} fontWeight={"extrabold"}>Name</Th>
              <Th fontSize={"13"} fontWeight={"extrabold"}>Bank Name</Th>
              <Th fontSize={"13"} fontWeight={"extrabold"}>IFSC Code</Th>
              <Th fontSize={"13"} fontWeight={"extrabold"}>UPI Id</Th>
              <Th fontSize={"13"} fontWeight={"extrabold"}>WhatsApp#1</Th>
              <Th fontSize={"13"} fontWeight={"extrabold"}>WhatsApp#2</Th>
              <Th fontSize={"13"} fontWeight={"extrabold"}>Email</Th>
              <Th fontSize={"13"} fontWeight={"extrabold"}>Barcode</Th>
              <Th fontSize={"13"} fontWeight={"extrabold"}>Actions</Th>
            </Thead>
            <Tbody>
              {
                payment.map((pay) => {
                  return (
                    <Tr>
                      <Td >{pay.accountNumber}</Td>
                      <Td>{pay.name}</Td>
                      <Td>{pay.bankName}</Td>
                      <Td>{pay.ifscCode}</Td>
                      <Td>{pay.upiId}</Td>
                      <Td>{pay.primaryWhatsApp}</Td>
                      <Td>{pay.secondaryWhatsApp}</Td>
                      <Td>{pay.email}</Td>
                      <Td>
                        <a href={pay.barCodeUrl} target="_blank" rel="noreferrer" title="Click to view" >
                          <Image boxSize='100px' src={pay.barCodeUrl} />
                        </a>
                      </Td>
                      <Td>
                        <Link to={"/update/" + pay.id}>
                          <Button className="updat-payment" variant="solid" colorScheme="blue" mt={4}>Update</Button>
                        </Link>
                        &nbsp; 
                        <Link to={"#"}>
                          <Button className="delete-payment" variant="solid" colorScheme="blue" mt={4} onClick={() => deletePayment(pay.id)}>Delete</Button>
                        </Link>
                      </Td>
                    </Tr>
                  )
                })
              }
            </Tbody>
          </Table>
        </Box>
      )}
    </>
  )
}