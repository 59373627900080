import { useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Input,
  FormControl,
  FormErrorMessage,
  InputGroup,
  InputLeftAddon,
  VStack,
  Button,
  Flex,
  Box,
  Checkbox,
} from "@chakra-ui/react";
import { useShowErrorToast, useShowSuccessToast } from "hooks/ToastUtil";
import { useState } from "react";
import { createOffer, saveOffer } from "http/offers";

export default function EditOffer({
  offer = null,
  zones,
  isOpen,
  onClose,
  onSuccess,
  isAdd = false,
}) {
  const [IsSubmitting, setIsSubmitting] = useState(false);
  const [Data, setData] = useState({
    id: 0,
    name: "",
    description: "",
    bannerUrl: "",
    zones: [],
  });
  const [ErrorMessage, setErrorMessage] = useState({
    description: null,
    name: null,
    zones: null,
  });
  const toast = useShowSuccessToast();
  const errorToast = useShowErrorToast();

  useEffect(() => {
    if (offer) {
      setData(offer);
    } else {
      setData({
        id: 0,
        name: "",
        description: "",
        bannerUrl: "",
        zones: [],
      });
    }
    setErrorMessage({
      description: null,
      name: null,
      zones: null,
    });
  }, [offer]);

  const submitHandler = async () => {
    const errors = {
      description: null,
      name: null,
      zones: null,
    };
    if (!Data.name || Data.name.length < 3) {
      errors.name = "Name should be at least 3 characters";
    }
    if (!Data.description || Data.description.length < 5) {
      errors.description = "Description should be at least 5 characters";
    }
    if (!Data.zones || Data.zones.length === 0) {
      errors.zones = "Please select at least one zone";
    }

    if (errors.name || errors.description || errors.zones) {
      setErrorMessage(errors);
      return;
    }

    setIsSubmitting(true);
    let response = null;
    if (isAdd) {
      response = await createOffer(Data);
    } else {
      response = await saveOffer(Data.id, Data);
    }
    if (response.error) {
      errorToast(response.error);
      setIsSubmitting(false);
      return;
    }
    setIsSubmitting(false);
    onClose();
    onSuccess();
    if (isAdd) {
      toast("Creating Offer", response.result);
    } else {
      toast("Updating Offer", response.result);
    }
  };

  const checkZoneIsSelected = (z) => {
    const zz = Data?.zones.find((fz) => {
      // eslint-disable-next-line eqeqeq
      return parseInt(fz) == parseInt(z.zoneCode);
    });
    return !!zz;
  };

  const onSelectingZone = (z, isChecked) => {
    if (isChecked) {
      setData({
        ...Data,
        zones: [...Data.zones, z.zoneCode.toString()],
      });
    } else {
      setData({
        ...Data,
        zones: Data.zones.filter((fz) => {
          // eslint-disable-next-line eqeqeq
          return parseInt(fz) != parseInt(z.zoneCode);
        }),
      });
    }
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        closeOnOverlayClick={true}
        closeOnEsc={true}
        isCentered
        blockScrollOnMount={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Offer</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={5}>
              <FormControl isInvalid={ErrorMessage.name}>
                <InputGroup>
                  <InputLeftAddon children="Name" w={120}></InputLeftAddon>
                  <Input
                    type="text"
                    value={Data?.name}
                    onChange={(e) => setData({ ...Data, name: e.target.value })}
                  />
                </InputGroup>
                <FormErrorMessage>{ErrorMessage.name}</FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={ErrorMessage.description}>
                <InputGroup>
                  <InputLeftAddon
                    children="Description"
                    w={120}
                  ></InputLeftAddon>
                  <Input
                    type="text"
                    value={Data?.description}
                    onChange={(e) =>
                      setData({ ...Data, description: e.target.value })
                    }
                  />
                </InputGroup>
                <FormErrorMessage>{ErrorMessage.description}</FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={ErrorMessage.zones}>
                <InputGroup>
                  <InputLeftAddon
                    children="Zones"
                    w={120}
                    h={200}
                  ></InputLeftAddon>
                  <Box h={200} w={"full"} overflowY="auto">
                    <VStack alignItems={"flex-start"} pl={5}>
                      {zones?.map((z) => (
                        <Checkbox
                          key={z.zoneCode}
                          isChecked={checkZoneIsSelected(z)}
                          onChange={(e) => onSelectingZone(z, e.target.checked)}
                        >
                          {z.zoneName}
                        </Checkbox>
                      ))}
                    </VStack>
                  </Box>
                </InputGroup>
                <FormErrorMessage>{ErrorMessage.zones}</FormErrorMessage>
              </FormControl>
              <Flex direction={"row-reverse"}>
                <Button
                  colorScheme={"brand"}
                  onClick={submitHandler}
                  isLoading={IsSubmitting}
                >
                  Submit
                </Button>
              </Flex>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
