import { deleteRequest, getBaseUrl, postRequest, putRequest } from "./axios";
import { getHeaderWithAccessToken } from "./helper";

const ENDPOINT = '/salesman';

export const getSalesOfficersEndPoint = () => ENDPOINT;

export const boardSalesOfficer = async (data) => {
    return await postRequest(`${ENDPOINT}`, data, getHeaderWithAccessToken());
};

export const deboardSalesOfficer = async (id, mobileNo) => {
    return await deleteRequest(`${ENDPOINT}/${id}/${mobileNo}`, getHeaderWithAccessToken());
};

export const updateSalesOfficer = async (id, data) => {
    return await putRequest(`${ENDPOINT}/${id}`, data, getHeaderWithAccessToken());
};

export const dismissSalesOfficerFromZone = async (id) => {
    return await deleteRequest(`${ENDPOINT}/${id}`, getHeaderWithAccessToken());
};

export const appointSalesOfficerForZone = async (mobileNo, id) => {
    return await postRequest(`${ENDPOINT}/${mobileNo}`, [id], getHeaderWithAccessToken());
};

export const getUploadImageUrl = (mobile) => `${getBaseUrl()}document/ProfilePic/${mobile}`;

