import { Button } from "@chakra-ui/button";
import { Box, Heading, VStack } from "@chakra-ui/layout";
import { FormControl, FormLabel, Input, Table, Td, Tr } from "@chakra-ui/react";
import { Formik } from "formik";
import { addPaymentDetails } from "http/payments";
import { useShowErrorToast, useShowSuccessToast } from "hooks/ToastUtil";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useNavigate } from "react-router-dom";


export default function AddPayment(){

const successToast = useShowSuccessToast();
const errorToast = useShowErrorToast();
const navigate = useNavigate();
let response = null;
const addPayment = async (paymentDetails) =>{
  response = await addPaymentDetails(paymentDetails);
  if (response.error) {
    errorToast("Add payment", response.error);
  } else {
    successToast("Add payment", response.result);
  }
}

    return (
      <>
      <Button
        colorScheme={"brand"}
        leftIcon={<AiOutlineArrowLeft />}
        onClick={() => navigate("/payments")}
      >
      Payment details
    </Button>
 
        <Formik
          initialValues={{ accountNumber: "", name: "", bankName:"", email:"", ifscCode:"", primaryWhatsApp:"", secondaryWhatsApp:"", upiId:"" }}
          onSubmit={async (values, actions) => {
            if (values!==null){
              addPayment(values);
            }
          }}
        >
          {formik => (
            <VStack
              as="form"
              mx="auto"
              w={{ base: "90%", md: 500 }}
              h="100vh"
              justifyContent="flex-start"
              onSubmit={values=>formik.handleSubmit()}
            >
           <Box p={8} borderWidth={3} borderRadius={10} boxShadow="lg" width={800}>
           <Heading  size={"md"}>Add Payment</Heading>
              <Table>
                  <Tr>
                    <Td>
                    <FormControl isRequired>
                      <FormLabel>Account number</FormLabel>
                      <Input 
                          type="number"
                          name="accountNumber" 
                          onChange={formik.handleChange}
                          value={formik.values.accountNumber} 
                          placeholder="Enter 16 digit of account number"/>
                    </FormControl>
                    </Td>
                    <Td>
                    <FormControl isRequired>
                    <FormLabel>Account Holder Name</FormLabel>
                    <Input 
                        type="text"
                        name="name" 
                        onChange={formik.handleChange}
                        value={formik.values.name} 
                        placeholder="Enter account holder name"/>
                  </FormControl>
                    </Td>
                  </Tr>

                  <Tr>
                    <Td>
                    <FormControl isRequired>
                    <FormLabel>Account BankName</FormLabel>
                    <Input 
                        type="text"
                        name="bankName" 
                        onChange={formik.handleChange}
                        value={formik.values.bankName} 
                        placeholder="Enter bank name"/>
                  </FormControl>
                    </Td>
                    <Td>
                    <FormControl isRequired>
                    <FormLabel>Account IFSC Code</FormLabel>
                    <Input 
                        type="text"
                        name="ifscCode" 
                        onChange={formik.handleChange}
                        value={formik.values.ifscCode} 
                        placeholder="Enter bank IFSC code"/>
                  </FormControl>
                    </Td>
                  </Tr>

                  <Tr>
                    <Td>
                    <FormControl isRequired>
                      <FormLabel>Account UPI ID</FormLabel>
                      <Input 
                          type="text"
                          name="upiId" 
                          onChange={formik.handleChange}
                          value={formik.values.upiId} 
                          placeholder="Enter bank UPI ID"/>
                    </FormControl>
                    </Td>
                    <Td>
                    <FormControl isRequired>
                    <FormLabel>Primary WhatsApp Number</FormLabel>
                    <Input 
                        type="number"
                        name="primaryWhatsApp" 
                        onChange={formik.handleChange}
                        value={formik.values.primaryWhatsApp} 
                        placeholder="Enter primary whatsapp number"/>
                  </FormControl>
                    </Td>
                  </Tr>

                  <Tr>
                    <Td>
                    <FormControl isRequired>
                <FormLabel>Secondary WhatsApp Number</FormLabel>
                <Input 
                    type="number"
                    name="secondaryWhatsApp" 
                    onChange={formik.handleChange}
                    value={formik.values.secondaryWhatsApp} 
                    placeholder="Enter secondary whatsapp number"/>
              </FormControl>
                    </Td>
                    <Td>

                    <FormControl isRequired>
                <FormLabel>Customer Support Email</FormLabel>
                <Input 
                    type="text"
                    name="email" 
                    onChange={formik.handleChange}
                    value={formik.values.email} 
                    placeholder="Enter customer support email"/>
              </FormControl>
                    </Td>
                  </Tr>
              </Table>
              <br/>
              &nbsp;  &nbsp; &nbsp; &nbsp;  &nbsp; &nbsp;&nbsp;  &nbsp; &nbsp;&nbsp;  &nbsp; &nbsp;&nbsp;  &nbsp; &nbsp;&nbsp;  &nbsp; &nbsp;
              &nbsp;  &nbsp; &nbsp; &nbsp;  &nbsp; &nbsp;&nbsp;  &nbsp; &nbsp;&nbsp;  &nbsp; &nbsp;&nbsp;  &nbsp; &nbsp;&nbsp;  &nbsp; &nbsp;
              &nbsp;&nbsp;
              <Button type="submit" variant="solid" colorScheme="blue" mt={4}> Add Payment</Button>
             </Box>
             
            </VStack>
          )}
        </Formik>
    </>
      );
}
