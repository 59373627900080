import { Box, Center, Flex, Heading } from "@chakra-ui/react";
import {
  Bar,
  BarChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

export default function DataBarGraph({ title, data }) {
  return (
    <>
      <Box
        w={["100%", "100%", "100%", "100%"]}
        h={"290px"}
        boxShadow="md"
        borderRadius="md"
        p={3}
        m={2}
      >
        <Flex direction={"column"} h={"100%"}>
          <Heading size={"sm"}  mb={5}>
            {title}
          </Heading>
          <Center flexGrow={2} textAlign={"center"}>
            <ResponsiveContainer width={"100%"} height={"100%"}>
              <BarChart width={150} height={40} data={data}>
                <XAxis dataKey="name" fontSize={12} minTickGap={0} />
                <YAxis  fontSize={12} minTickGap={0} />
                <Tooltip />
                <Bar dataKey="value" fill="pink" />
              </BarChart>
            </ResponsiveContainer>
          </Center>
        </Flex>
      </Box>
    </>
  );
}
