import React from "react";
import {
  Box,
  Flex,
  Heading,
  IconButton,
  Stack,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { AiFillEye } from "react-icons/ai";
import { BiEdit } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import DeleteItemComponent from "components/deleteItemComponent";

export default function ViewSubCategory({
  data,
  onEdit,
  onDelete,
  isDeleting,
}) {
  const navigate = useNavigate();

  return (
    <>
      <Box
        w={"full"}
        boxShadow={"xl"}
        rounded="md"
        p={2}
        bg={"gray.100"}
        border={"1px"}
        borderColor={"gray.300"}
      >
        <Flex justifyContent={"space-between"}>
          <Stack direction={"column"} spacing={0} ml={5}>
            <Heading size={"md"}>{data.name}</Heading>
            <Text fontSize={12} color="gray">
              {data.description}
            </Text>
          </Stack>
          <Stack direction={"row"} mr={5}>
            <Tooltip label="Edit Sub Category" bg={"brand.600"}>
              <IconButton
                aria-label="Edit"
                icon={<BiEdit />}
                colorScheme={"brand"}
                variant="outline"
                onClick={() => onEdit(data.id)}
              />
            </Tooltip>
            <Tooltip label="View Products">
              <IconButton
                aria-label="View Products"
                icon={<AiFillEye />}
                colorScheme={"brand"}
                variant="outline"
                onClick={() =>
                  navigate(
                    `/products?category=${data.categoryId}&subcategory=${data.id}`
                  )
                }
              />
            </Tooltip>
            <DeleteItemComponent
              isDeleting={isDeleting}
              title="Delete Sub-Category"
              onDelete={() => onDelete(data.id)}
            />
          </Stack>
        </Flex>
      </Box>
    </>
  );
}
