import {
  Stack,
  Heading,
  Tbody,
  Table,
  Td,
  Tr,
  Button,
  Input,
  Box,
  Flex,
  Center,
} from "@chakra-ui/react";
import { useShowErrorToast, useShowSuccessToast } from "hooks/ToastUtil";
import useGetSettings from "hooks/useGetSettings";
import { saveSettings } from "http/settings";
import { useEffect, useState } from "react";
import PropagateLoader from "react-spinners/PropagateLoader";


export default function Settings() {
  const [IsEditing, setIsEditing] = useState(false);
  const [Data, setData] = useState({
    bannerInterval: 0,
    freeDeliveryAmount: 0,
    loginRetry: 0,
    perUserLoginSession: 0,
    sessionExpiry: 0,
    distributorId: 0,
    id: 0,
    productNotifyCount: 0,
    notificationCleanUp: 0,
    retailerAppVersion: '',
    salesAppVersion: "",
    salesApkUrl: "",
    retailerApkUrl: ""
  });
  const { isLoading, data: settings, forceRefetch } = useGetSettings();
  const [IsSaving, setIsSaving] = useState(false);
  const errorToast = useShowErrorToast();
  const successToast = useShowSuccessToast();

  useEffect(() => {
    if (settings) {
      setData({ ...settings });
    }
  }, [settings]);

  const onEditing = () => {
    setIsEditing(true);
  };
  const onCancelEditing = () => {
    setIsEditing(false);
  };
  const onSaveSettings = async () => {
    if (Data.sessionExpiry < 1 || Data.sessionExpiry > 4320) {
      errorToast("Invalid session expiry", "Please enter session exiry 1-4320 hours");
      return
    }
    setIsSaving(true);
    const response = await saveSettings(Data);
    if (response.error) {
      errorToast(response.error);
      setData({ ...settings });
    } else {
      successToast(response.result);
      forceRefetch({});
      setIsEditing(false);
    }
    setIsSaving(false);
  };

  return (
    <>
      {isLoading ? (
        <Center
          w={"full"}
          boxShadow={"None"}
          rounded="md"
          p={5}
        >
          <PropagateLoader
            color={'purple'}
            loading={isLoading}
            data-testid="loader"
            speedMultiplier={1} />
        </Center>
      ) : (
        <Stack spacing={2} mt={6} align={'center'}>
          <Heading size={"md"}>Settings</Heading>
          <Table maxW={600}>
            <Tbody>
              <Tr>
                <Td fontWeight={"bold"}> Offers Slider Interval</Td>
                <Td>
                  <Input
                    type="number"
                    borderColor={"brand"}
                    disabled={!IsEditing}
                    value={Data.bannerInterval}
                    onChange={(e) =>
                      setData({
                        ...Data,
                        bannerInterval: parseInt(e.target.value),
                      })
                    }
                  />
                </Td>
              </Tr>
              <Tr>
                <Td fontWeight={"bold"}>Minimum Order Amount</Td>
                <Td>
                  <Input
                    type="number"
                    borderColor={"brand"}
                    disabled={!IsEditing}
                    value={Data.freeDeliveryAmount}
                    onChange={(e) =>
                      setData({
                        ...Data,
                        freeDeliveryAmount: parseInt(e.target.value),
                      })
                    }
                  />
                </Td>
              </Tr>
              <Tr>
                <Td fontWeight={"bold"}>Login Retry</Td>
                <Td>
                  <Input
                    type="number"
                    borderColor={"brand"}
                    disabled={!IsEditing}
                    value={Data.loginRetry}
                    onChange={(e) =>
                      setData({ ...Data, loginRetry: parseInt(e.target.value) })
                    }
                  />
                </Td>
              </Tr>
              <Tr>
                <Td fontWeight={"bold"}>Per User Login Session</Td>
                <Td>
                  <Input
                    type="number"
                    borderColor={"brand"}
                    disabled={!IsEditing}
                    value={Data.perUserLoginSession}
                    onChange={(e) =>
                      setData({
                        ...Data,
                        perUserLoginSession: parseInt(e.target.value),
                      })
                    }
                  />
                </Td>
              </Tr>
              <Tr>
                <Td fontWeight={"bold"}>Session Expiry Time</Td>
                <Td>
                  <Input
                    placeholder='Enter 1-4320 in hours'
                    type="number"
                    borderColor={"brand"}
                    disabled={!IsEditing}
                    value={Data.sessionExpiry}
                    onChange={(e) =>
                      setData({
                        ...Data,
                        sessionExpiry: parseInt(e.target.value),
                      })
                    }
                  />
                </Td>
              </Tr>

              <Tr>
                <Td fontWeight={"bold"}>Product Notify Followup</Td>
                <Td>
                  <Input
                    type="number"
                    borderColor={"brand"}
                    disabled={!IsEditing}
                    value={Data.productNotifyCount}
                    onChange={(e) =>
                      setData({
                        ...Data,
                        productNotifyCount: parseInt(e.target.value),
                      })
                    }
                  />
                </Td>
              </Tr>

              <Tr>
                <Td fontWeight={"bold"}>Notification Cleanup In Days</Td>
                <Td>
                  <Input
                    type="number"
                    borderColor={"brand"}
                    disabled={!IsEditing}
                    value={Data.notificationCleanUp}
                    onChange={(e) =>
                      setData({
                        ...Data,
                        notificationCleanUp: parseInt(e.target.value),
                      })
                    }
                  />
                </Td>
              </Tr>
              <Tr>
                <Td fontWeight={"bold"}>Sales App Version</Td>
                <Td>
                  <Input
                    type="text"
                    borderColor={"brand"}
                    disabled={!IsEditing}
                    value={Data.salesAppVersion}
                    onChange={(e) =>
                      setData({
                        ...Data,
                        salesAppVersion: e.target.value,
                      })
                    }
                  />
                </Td>
              </Tr>

              <Tr>
                <Td fontWeight={"bold"}>Retailer App Version</Td>
                <Td>
                  <Input
                    type="text"
                    borderColor={"brand"}
                    disabled={!IsEditing}
                    value={Data.retailerAppVersion}
                    onChange={(e) =>
                      setData({
                        ...Data,
                        retailerAppVersion: e.target.value,
                      })
                    }
                  />
                </Td>
              </Tr>

              <Tr>
                <Td fontWeight={"bold"}>Retailer APK URL</Td>
                <Td>
                  <Input
                    type="text"
                    borderColor={"brand"}
                    disabled={!IsEditing}
                    value={Data.retailerApkUrl}
                    onChange={(e) =>
                      setData({
                        ...Data,
                        retailerApkUrl: e.target.value,
                      })
                    }
                  />
                </Td>
              </Tr>

              <Tr>
                <Td fontWeight={"bold"}>Sales APK URL</Td>
                <Td>
                  <Input
                    type="text"
                    borderColor={"brand"}
                    disabled={!IsEditing}
                    value={Data.salesApkUrl}
                    onChange={(e) =>
                      setData({
                        ...Data,
                        salesApkUrl: e.target.value,
                      })
                    }
                  />
                </Td>
              </Tr>

            </Tbody>
          </Table>
          {!IsEditing ? (
            <Box w={600} textAlign={"right"} >
              <Button colorScheme={"brand"} onClick={onEditing} >
                Edit Settings
              </Button>
            </Box>
          ) : (
            <Flex
              direction={"row-reverse"}
              w={600}
              alignItems={"center"}
              gap={3}
            >
              <Button
                colorScheme={"brand"}
                onClick={onSaveSettings}
                isLoading={IsSaving}
              >
                Save Settings
              </Button>
              <Button colorScheme={"brand"} onClick={onCancelEditing}>
                Cancel Editing
              </Button>
            </Flex>
          )}
        </Stack>
      )}
    </>
  );
}
