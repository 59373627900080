import SimpleSidebar from "components/sidebar";
import { Navigate, Outlet } from "react-router-dom";
import { getAccessToken } from "storage/session";

function RootLayout() {
    const accessToken = getAccessToken();

    if (!accessToken) {
        return <Navigate to='/login' />;
    }

    return (<>
        <SimpleSidebar>
            <Outlet />
        </SimpleSidebar>
    </>);
}

export default RootLayout;
