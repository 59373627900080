import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import { getUploadImageUrl } from "http/category";
import ImageUploader from 'components/ImageUploader';

export default function ChangeImageCategory({ isOpen, onClose, category, onSuccess }) {
  
  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        closeOnOverlayClick={false}
        closeOnEsc={false}
        isCentered
        blockScrollOnMount={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Category: Change Image</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <ImageUploader multiple={false} maxFiles={1} uploadHandlerObj={{
              url: getUploadImageUrl(category ? category.id : ''),
              method: "put",
              name: 'categoryIcon '
            }}></ImageUploader>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
