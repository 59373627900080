import {
  Flex,
  Button,
  Box,
  Heading,
  Text,
  Spacer,
  Container,
  Divider,
  VStack,
  Table,
  Tbody,
  Tr,
  Td,
  Thead,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Center,
  Input,
  Tooltip,
  InputGroup,
  InputLeftAddon,
  Select,
} from "@chakra-ui/react";
import { useShowSuccessToast, useShowErrorToast } from "hooks/ToastUtil";
import useGetAOrder from "hooks/useGetAOrder";
import useGetARetailer from "hooks/useGetARetailer";
import { updateOrder } from "http/order";
import { useEffect, useState } from "react";
import { AiFillDownSquare, AiOutlineArrowLeft } from "react-icons/ai";
import { useNavigate, useParams } from "react-router-dom";
import PropagateLoader from "react-spinners/PropagateLoader";

export default function ViewOrder() {
  const statuses = ["CREATED", "APPROVED", "OUT_FOR_DELIVERY", "DELIVERED", "PACKING", "REJECTED", 'FAILED'];
  const paymentStatuses = [
    "INITIATED",
    "WAITING",
    "HOLD",
    "CONFIM",
    "FAILED",
    "UNKNOWN",
  ];
  const paymentMethods = ["COD", "ONLINE", 'UPI'];

  const params = useParams();
  const navigate = useNavigate();
  const { isLoading, data: order, forceRefetch } = useGetAOrder(params.orderId);
  const { data: retailer, forceRefetch: fetchReatiler } = useGetARetailer(
    order?.retailerId
  );
  const [IsUpdatingOrder, setIsUpdatingOrder] = useState(false);
  const successToast = useShowSuccessToast();
  const errorToast = useShowErrorToast();
  const [NewOrder, setNewOrder] = useState(null);
  const [IsDirty, setIsDirty] = useState(false);

  useEffect(() => {
    if (order) {
      fetchReatiler({});
      setNewOrder(order);
      setIsDirty(false);
    }
  }, [order, fetchReatiler]);

  const onUpdate = async () => {
    setIsUpdatingOrder(true);
    // Update only these attributes
    var update = {}
    update.retailerId = NewOrder.retailerId
    update.coupanCode = NewOrder.coupanCode
    update.discount = NewOrder.discount
    update.paymentStatus = NewOrder.paymentStatus
    update.transactionId = NewOrder.transactionId
    update.paymentMethod = NewOrder.paymentMethod
    update.shippingCharges = NewOrder.shippingCharges
    update.receivedPayment = parseInt(NewOrder.receivedPayment, 10)
    update.rewards = NewOrder.rewards
    update.status = NewOrder.status
    const response = await updateOrder(NewOrder.orderId, update);
    if (response.error) {
      errorToast(response.error);
      setIsUpdatingOrder(false);
      return;
    }
    setIsUpdatingOrder(false);
    successToast("Update Order", response.result);
    forceRefetch({});
  };

  const handleStatusChange = async (status) => {
    const newOrder = { ...NewOrder, status: status };
    setNewOrder(newOrder);
    setIsDirty(true);
  };

  const handlePaymentStatusChange = async (status) => {
    const newOrder = { ...NewOrder, paymentStatus: status };
    setNewOrder(newOrder);
    setIsDirty(true);
  };

  const onTranscationIdChange = (e) => {
    const newOrder = { ...NewOrder, transactionId: e.target.value };
    setNewOrder(newOrder);
    setIsDirty(true);
  };

  const onPaymentMethodChange = (e) => {
    const newOrder = { ...NewOrder, paymentMethod: e.target.value };
    setNewOrder(newOrder);
    setIsDirty(true);
  };

  const onDiscountChange = (e) => {
    const newOrder = { ...NewOrder, discount: e.target.value };
    setNewOrder(newOrder);
    setIsDirty(true);
  };

  const onReceivedPaymentChange = (e) => {
    const newOrder = { ...NewOrder, receivedPayment: e.target.value };
    setNewOrder(newOrder);
    setIsDirty(true);
  };

  return (
    <>
      {IsUpdatingOrder ? (
        <Center
          pos={"fixed"}
          w={"100%"}
          h={"100%"}
          top={0}
          left={0}
          right={0}
          bottom={0}
          bg="rgba(0,0,0,0.5)"
          zIndex={10000}
        >
          <VStack>
            <Text color={"white"}>Updating Order, please wait...</Text>


          </VStack>
        </Center>
      ) : null}
      <Flex direction={"row"}>
        <Button
          colorScheme={"brand"}
          leftIcon={<AiOutlineArrowLeft />}
          onClick={() => navigate(-1)}
        >
          Go Back
        </Button>
      </Flex>
      <Spacer h={30} />
      {isLoading ? (
        <Center
          w={"full"}
          boxShadow={"None"}
          rounded="md"
          p={5}
        >
          <PropagateLoader
            color={'purple'}
            loading={isLoading}
            data-testid="loader"
            speedMultiplier={1} />
        </Center>
      ) : (
        <Container maxW={"container.xl"}>
          <Box
            w={"full"}
            boxShadow={"xl"}
            rounded="md"
            p={5}
          >
            <Flex direction={"column"} gap={5}>
              <Flex direction={"row"} justify={"space-between"}>
                <Heading size={"lg"}>Order Id: {order.orderId}</Heading>
                <Flex alignItems={"center"}>
                  <Text mr={2}>Status:</Text>
                  <Box bg={"teal"} p={1} color={"whiteAlpha.900"}>
                    <Text>{NewOrder?.status}</Text>
                  </Box>
                  <Menu>
                    <Tooltip label={"Change Order Status"}>
                      <MenuButton
                        as={IconButton}
                        aria-label="Options"
                        icon={<AiFillDownSquare />}
                        variant="ghost"
                        fontSize={"2.6rem"}
                        color={"teal"}
                      />
                    </Tooltip>
                    <MenuList>
                      {statuses.map((status, index) => {
                        if (status !== NewOrder?.status) {
                          return (
                            <MenuItem
                              key={status}
                              onClick={(e) => handleStatusChange(status)}
                            >
                              {status}
                            </MenuItem>
                          );
                        } else {
                          return null;
                        }
                      })}
                    </MenuList>
                  </Menu>
                </Flex>
              </Flex>
              <Flex direction={"row"} justify={"space-between"}>
                <Text fontWeight={'bold'}>
                  Retailer : {retailer ? retailer.firmName : "Loading..."}(Proprietor : {retailer ? retailer.proprietorName : "Loading..."})
                </Text>
                <Text fontWeight={'bold'}>Order Date : {order.createdAt}</Text>
                <Text fontWeight={'bold'}>CreatedBy : {order.createdBy}</Text>
                <Text fontWeight={'bold'}>Delivery Date : {order.deliveredAt}</Text>
              </Flex>

              {/* Payment details */}
              <Divider />
              <Flex direction={"column"} gap={5}>
                <Heading size={"sm"}>Payment Details</Heading>
                <Flex direction={"row"} gap={8}>
                  <InputGroup>
                    <InputLeftAddon children="Transaction Id" />
                    <Input
                      type="text"
                      value={NewOrder?.transactionId}
                      onChange={onTranscationIdChange}
                    />
                  </InputGroup>
                  <InputGroup>
                    <InputLeftAddon children="Payment Method" />
                    <Select value={NewOrder?.paymentMethod} onChange={onPaymentMethodChange}>
                      {
                        paymentMethods.map((method, index) => {
                          return <option key={index} value={method}>{method}</option>
                        })
                      }
                    </Select>
                  </InputGroup>
                  <Flex alignItems={"center"}>
                    <Text w={"150px"} mr={2}>
                      Payment Status:
                    </Text>
                    <Box bg={"teal"} p={1} color={"whiteAlpha.900"}>
                      <Text>{NewOrder?.paymentStatus}</Text>
                    </Box>
                    <Menu>
                      <Tooltip label={"Change Payment Status"}>
                        <MenuButton
                          as={IconButton}
                          aria-label="Options"
                          icon={<AiFillDownSquare />}
                          variant="ghost"
                          fontSize={"2.6rem"}
                          color={"teal"}
                        />
                      </Tooltip>
                      <MenuList>
                        {paymentStatuses.map((status, index) => {
                          if (status !== NewOrder?.paymentStatus) {
                            return (
                              <MenuItem
                                key={status}
                                onClick={(e) =>
                                  handlePaymentStatusChange(status)
                                }
                              >
                                {status}
                              </MenuItem>
                            );
                          } else {
                            return null;
                          }
                        })}
                      </MenuList>
                    </Menu>
                  </Flex>
                </Flex>
              </Flex>

              {/* Order details */}
              <Flex direction={"column"} gap={5}>
                <Flex direction={"row"} justify={"space-between"}>
                  <Flex direction={"column"} gap={5} grow={2}>
                    <Heading size={"xs"}>Delivery Address:</Heading>
                    <tr>
                      <td > {order.address.name} ({order.address.addressType}) </td>
                    </tr>
                    <tr>
                      <td> {order.address.address} - {order.address.pin}</td>
                    </tr>
                    <tr>
                      <td> {order.address.landmark}</td>
                    </tr>
                    <tr>
                      <td> <text>{order.address.primaryMobile}, {order.address.secondaryMobile}</text></td>
                    </tr>
                  </Flex>
                  <Flex direction={"column"} gap={5} grow={1}>
                    <Table variant={"unstyled"}>
                      <Tbody>
                        <Tr>
                          <Td>Coupan Code</Td>
                          <Td>{order.coupanCode}</Td>
                        </Tr>
                        <Tr>
                          <Td>Earning Rewards</Td>
                          <Td>{order.rewards}</Td>
                        </Tr>
                        <Tr>
                          <Td>Total Amount</Td>
                          <Td>Rs. {order.totalAmount}</Td>
                        </Tr>
                        <Tr>
                          <Td>Discount</Td>
                          <Td >RS. {order.discount}</Td>
                          <Td>
                            <Input
                              w="100px"
                              type={"number"}
                              value={NewOrder?.discount}
                              onChange={onDiscountChange}
                            />
                          </Td>
                        </Tr>
                        <Tr>
                          <Td>Shipping Charges</Td>
                          <Td >Rs. {order.shippingCharges}</Td>
                        </Tr>
                        <Tr>
                          <Td>Payable amount</Td>
                          <Td>Rs. {order.totalAmount - NewOrder?.discount}</Td>
                        </Tr>
                        <Tr>
                          <Td>Received Payment</Td>
                          <Td>Rs. {order.receivedPayment}</Td>
                          <Td>
                            <Input
                              w="100px"
                              type={"number"}
                              value={NewOrder?.receivedPayment}
                              onChange={onReceivedPaymentChange}
                            />
                          </Td>
                        </Tr>
                      </Tbody>
                    </Table>
                  </Flex>
                </Flex>
              </Flex>
              <Divider />
              <Divider />
              <Flex direction={"column"} gap={5}>
                <Heading size={"sm"}>Order Items</Heading>
                <Table variant="striped">
                  <Thead>
                    <Tr bg={"gray"} color={"white"} fontWeight={"bold"}>
                      <Td>Product Description</Td>
                      <Td>Pack</Td>
                      <Td>Price</Td>
                      <Td>Quantity</Td>
                      <Td>Item Total</Td>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {order.items.map((item) => (
                      <Tr key={item.productCode}>
                        <Td>{item.description}</Td>
                        <Td>{item.pack}</Td>
                        <Td>{item.price}</Td>
                        <Td>{item.quantity}</Td>
                        <Td>{item.totalAmount}</Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </Flex>
              <Divider />
              <Flex direction={"row-reverse"} gap={5}>
                <Button colorScheme="teal" variant="outline" onClick={onUpdate} isDisabled={!IsDirty}>
                  Update Order
                </Button>
                <Button colorScheme="blackAlpha" variant="outline" onClick={() => { setNewOrder(order); setIsDirty(false); }} isDisabled={!IsDirty}>
                  Reset
                </Button>
              </Flex>
            </Flex>
          </Box>
        </Container>
      )}
    </>
  );
}
