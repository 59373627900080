export const getMobile = () => {
	let data = sessionStorage.getItem("mobile");
	return data;
};

export const setMobile = (param) => {
	sessionStorage.setItem("mobile", param);
};

export const clearMobile = (param) => {
	sessionStorage.removeItem("mobile");
};

export const setFirmName = (param) => {
	sessionStorage.setItem("FirmName", param);
};

export const getFirmName = () => {
	let data = sessionStorage.getItem("FirmName");
	return data;
};

export const clearFirmName = (param) => {
	sessionStorage.removeItem("FirmName");
};

export const getAccessToken = () => {
	let data = sessionStorage.getItem("accessToken");
	return data;
};

export const setAccessToken = (param) => {
	sessionStorage.setItem("accessToken", param);
};

export const clearAccessToken = (param) => {
	sessionStorage.removeItem("accessToken");
};