import { deleteRequest, getBaseUrl, postRequest, putRequest } from "./axios";
import { getHeaderWithAccessToken } from "./helper";

const ENDPOINT = 'retailer';

export const getRetailersEndPoint = () => ENDPOINT;
export const getARetailerEndPoint = (id) => id ? `${ENDPOINT}/${id}` : null;
export const getRetailerDocumentsEndPoint = (id) => id ? `document/${id}` : null;
export const getRewardsStatementByRetailer = (id) => id ? `${ENDPOINT}/rewards/${id}` : null;
export const getRewardsStatementByMonths = (id, range) => !!range ? `${ENDPOINT}/rewards/${id}/${range}` : null;

export const getUploadImageUrl = (mobile) => `${getBaseUrl()}document/ProfilePic/${mobile}`;
export const getUploadPesticidesLicenceUrl = (mobile) => `${getBaseUrl()}document/PesticidesLicence/${mobile}`;
export const getUploadFertilizersLicenceUrl = (mobile) => `${getBaseUrl()}document/FertilizersLicense/${mobile}`;
export const getUploadSeedsLicenceUrl = (mobile) => `${getBaseUrl()}document/SeedsLicense/${mobile}`; 

export const createRetailer = async (data) => {
    return await postRequest(`${ENDPOINT}`, data, getHeaderWithAccessToken());
};

export const updateRetailer = async (data) => {
    return await putRequest(`${ENDPOINT}/${data.mobileNo}`, data, getHeaderWithAccessToken());
};

export const deleteRetailer = async (id) => {
    return await deleteRequest(`${ENDPOINT}/${id}`, getHeaderWithAccessToken());
};
export const uploadPdfStatement = async (customerId, documentType, formData) => {
    return await putRequest(`/document/${documentType}/${customerId}`, formData, getHeaderWithAccessToken());
}

export const redemRewardPoints = async (mobileNo, rewards) => {
    return await putRequest(`${ENDPOINT}/rewards/${mobileNo}/${rewards}`,null, getHeaderWithAccessToken());
};